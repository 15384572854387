import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import VerticallyCenteredModal from "../../commonModules/Popups/VerticallyCenteredModal";
import InputFieldText from "../../commonModules/InputFieldText";
import InputFieldDropdown from "../../commonModules/InputFieldDropdown";
import InputFieldCheckBox from "../../commonModules/InputFieldCheckBox";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import SVG from "react-inlinesvg";
import { Button } from "react-bootstrap";
import deleteIcon from "../../../../src/images/delete.svg";
import location from "../../../../src/images/location.svg";
import ErrorMessage from "../../commonModules/ErrorMessage";
import "../../style/CSS/HealthFormPage1.css"

export default function HealthFormPage1(props) {

  const [modalShow, setModalShow] = React.useState(false);
  const [InsurancemodalShow, SetInsurancemodalShow] = React.useState(false);

  const [memberIndex, setMemberIndex] = useState(1);
  const [formError, setFormError] = useState("");

  const [familyMembers, setFamilyMembers] = useState([
    {
      id: 0,
      dateOfBirth: null,
      age: null,
      relation: 'Self',
    },
  ]);
  const hospitalLimitValues = [
    { label: "Select", value: "" },
    { label: "5 lakhs", value: "5" },
    { label: "7 lakhs", value: "7" },
    { label: "7.5 lakhs", value: "7.5" },
    { label: "10 lakhs", value: "10" },
    { label: "15 lakhs", value: "15" },
    { label: "20 lakhs", value: "20" },
    { label: "25 lakhs", value: "25" },
    { label: "30 lakhs", value: "30" },
    { label: "35 lakhs", value: "35" },
    { label: "40 lakhs", value: "40" },
    { label: "45 lakhs", value: "45" },
    { label: "50 lakhs", value: "50" },
    { label: "75 lakhs", value: "75" },
    { label: "1 crore", value: "100" },
    { label: "1.5 crores", value: "150" },
    { label: "2 crores", value: "200" }
  ];
  const addFamilyMember = () => {
    if (familyMembers.length < 6) {
      setMemberIndex((prevIndex) => prevIndex + 1);
      setFamilyMembers((family) => [...family, {
        id: memberIndex,
        age: null,
        relation: '',
        dateOfBirth: null,
      }]);
    }
  };

  // Define policy term options
  const policyTermOptions = [
    { value: "1", label: "1 Year" },
    { value: "2", label: "2 Years" },
    { value: "3", label: "3 Years" }
  ];

  const handleRelationChange = (event, id) => {
    console.log(event)
    let childCount = 0;
    let adultCount = 0;
    familyMembers.forEach((member) => {
      if (member.relation === 'Son' || member.relation === 'Daughter') childCount++;
      else if (member.relation === 'Self' || member.relation === 'Spouse' || member.relation === 'Mother' || member.relation === 'Father') adultCount++;
    });
    console.log(childCount);
    if (adultCount <= 4 && childCount <= 4) {
      setFamilyMembers((members) => members.map(member => {
        if (member.id === id) {
          member.relation = event.target.value;
        }
        return member;
      }))
    } else {
      console.log('Child count exceeds max limit of 4');
    }
  };

  const handleDateOfBirthChange = (event, id) => {
    setFamilyMembers((members) => members.map(member => {
      if (member.id === id) {
        member.dateOfBirth = event.target.value;
        member.age = calculateAge(event.target.value);
      }
      return member;
    }))
  };

  const calculateAge = (dateValue, id) => {
    const differenceDate = new Date(Date.now() - new Date(dateValue).getTime());
    return Math.abs(differenceDate.getUTCFullYear() - 1970);
  };

  const handleDeleteMember = (id) => {
    setFamilyMembers((members) => members.filter(member => member.id !== id));
  };

  const handleSubmitMembers = () => {
    let valid = true;
    let selfAge = null;
    let motherAge = null;
    let fatherAge = null;

    for (const member of familyMembers) {
      if (member.age !== null && member.age !== undefined && member.age !== NaN &&
        member.dateOfBirth !== null && member.dateOfBirth !== undefined && member.dateOfBirth !== '' &&
        member.relation !== '' && member.relation !== undefined && member.relation !== null) {

        if (member.relation === "Self") selfAge = member.age;
        if (member.relation === "Mother") motherAge = member.age;
        if (member.relation === "Father") fatherAge = member.age;

      } else {
        valid = false;
        break;
      }
    }

    if (valid && selfAge !== null && (motherAge !== null || fatherAge !== null)) {
      if ((motherAge && selfAge >= motherAge) || (fatherAge && selfAge >= fatherAge)) {
        valid = false;
        setFormError("The age of Self should be less than the age of Mother or Father.");
      }
    }

    if (valid) {
      props.formik.setFieldValue('healthInsurefor', JSON.stringify(familyMembers));
      SetInsurancemodalShow(false);
      setFormError(""); // Clear any previous errors
    } else {
      console.log('Please enter all the values or correct the age validation');
    }
  };

  const handlePincodeChange = (event) => {
    if (event.target.value.length === 6) {
      props.formik.setFieldValue('pincode', event.target.value);
    } else {
      props.formik.setFieldValue('pincode', '');
    }
  };

  useEffect(() => {
    if (props.formik.values.healthInsurefor !== '') {
      setFamilyMembers(JSON.parse(props.formik.values.healthInsurefor));
    }
  }, []);

  const popForInsurance = (
    <>
      <div className="">
        <div>
          {familyMembers.map((member, index) => {
            return <div className="member-row" key={`member-row-${index}`}>
              <div className="member" >
                <div className="form-floating mb-2">
                  <select id="idunique" className="form-select"
                    value={member.relation}
                    disabled={index === 0}
                    onChange={(event) => handleRelationChange(event, member.id)}
                  >
                    <option>Select</option>
                    <option value="Self" selected={index === 0}>Self</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Mother">Mother</option>
                    <option value="Father">Father</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                  </select>
                  <label htmlFor="idunique select-label">Relation</label>
                </div>
              </div>
              <div className="member">
                <div>
                  <FloatingLabel
                    controlId="age"
                    label="Age "
                    className="mb-3"
                    aria-autocomplete="off"
                  >
                    <Form.Control
                      name="age"
                      type="text"
                      placeholder=""
                      className="floating-input"
                      value={member.age}
                      disabled
                    />
                  </FloatingLabel>
                </div>
              </div>
              <div className="member">
                <div>
                  <FloatingLabel
                    controlId="dateOfBirth"
                    label="Date of birth "
                    className="mb-3"
                    aria-autocomplete="off"
                  >
                    <Form.Control
                      name="dateOfBirth"
                      type="date"
                      placeholder=""
                      className="floating-input"
                      value={member.dateOfBirth}
                      max={new Date()?.toISOString()?.slice(0, 10)}
                      onChange={(event) => handleDateOfBirthChange(event, member.id)}
                    />
                  </FloatingLabel>
                </div>
              </div>
              <div className="delete-icon-wrap">
                <SVG className="delete-icon" src={deleteIcon} onClick={() => { if (member.id !== 0) handleDeleteMember(member.id) }} />
              </div>
            </div>
          })}
          <div className="text-center mb-3">
            <Button className="add-member-btn" onClick={addFamilyMember}>+ OTHER FAMILY MEMBERS</Button>
          </div>
          <div className="text-center">
            <Button className="primary-btn" onClick={handleSubmitMembers}>Submit</Button>
          </div>
        </div>
      </div>
    </>
  );

  const popForPinCode = (
    <>
      <div className="">
        <div className="d-flex pincode-wrap">
          <SVG className="location-icon" src={location}></SVG>
          <div className="input-wrap">
            <FloatingLabel
              controlId="floatingPassword"
              label="Pincode"
              className="mb-1 "
            >
              <Form.Control
                type="text"
                placeholder="Pincode"
                className="floating-input"
                onClick={() => setModalShow(true)}
                minLength={6}
                maxLength={6}
                onChange={handlePincodeChange}
              />
            </FloatingLabel>
            <p className="help-text">No Results Found</p>
          </div>
        </div>
        <div className="text-center">
          <Button className="primary-btn" onClick={() => setModalShow(false)}>Submit</Button>
        </div>
      </div>
    </>
  );

  return (
    <div className="d-flex justify-content-center">
      <Form as="div" className="form-ui p-4" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', width: '100%', maxWidth: '600px' }}>
        <Row>
          {/* gender */}
          <Col md={12} sm={12} className='mb-3'>
            <div className="form-group gender-checkbox-group">
              <span className="d-flex justify-content-left" style={{fontSize:"13px", color:"grey"}}>Gender</span>
              <div className="checkbox-options">
              <div className="policy-type">
                <Form.Check
                  inline
                  label="Male"
                  name="gender"
                  type="radio"
                  id="gender-male"
                  value="Male"
                  checked={props.formik.values.gender === "Male"}
                  onChange={(event) => props.formik.setFieldValue("gender", event.target.value)}
                />
                <Form.Check
                  inline
                  label="Female"
                  name="gender"
                  type="radio"
                  id="gender-female"
                  value="Female"
                  checked={props.formik.values.gender === "Female"}
                  onChange={(event) => props.formik.setFieldValue("gender", event.target.value)}
                />
                </div>
              </div>
            </div>
            <ErrorMessage formik={props.formik} fieldValue="gender" />
          </Col>

     
          {/* Policy Term Dropdown */}
          <Col md={12} sm={12} className='mb-3'>
            <InputFieldDropdown
              formikFieldName="policyTerm"
              label="Policy Term"
              optionsArray={policyTermOptions}
              formik={props.formik}
            />
            <ErrorMessage formik={props.formik} fieldValue="policyTerm" />
          </Col>


          <Col md={12} sm={12} className='mb-3'>
            <InputFieldDropdown
              formikFieldName="hospitalLimit"
              label="Cover"
              optionsArray={hospitalLimitValues}
              formik={props.formik}
            />
            <ErrorMessage formik={props.formik} fieldValue="hospitalLimit" />
          </Col>

          <Col md={12} sm={12} className='mb-3'>
            <InputFieldText
              formikFieldName="pincode"
              placeholder="Pincode [Change]"
              formik={props.formik}
              showPopup={() => {
                setModalShow(true);
              }}
            />
            <VerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              heading="Enter Area or City or Pincode"
            >
              {popForPinCode}
            </VerticallyCenteredModal>
            <ErrorMessage formik={props.formik} fieldValue="pincode" />
          </Col>

          <Col md={12} sm={12} className='mb-3'>
            <div className="health-input">
              <p className="text-left-align">
              Insured Members{" "}
                <span className="change-member" onClick={() => SetInsurancemodalShow(true)}>
                [Update]
                </span>
              </p>
              <div className="insuranceFor">
                {familyMembers.map((member, index) => (
                  <p className="text-left-align font-weight-bold" key={index}>
                    {`${member.relation} - 
                    ${index === 0 ? props.formik.values.gender
                        : ['Daughter', 'Mother'].includes(member.relation)
                          ? 'Female'
                          : ['Son', 'Father'].includes(member.relation)
                            ? 'Male'
                            : ['Spouse'].includes(member.relation) && props.formik.values.gender === 'Male'
                              ? 'Female'
                              : 'Male'} - 
                    ${member.age ? member.age + " Years" : ''} `}
                  </p>

                ))}
              </div>
            </div>
            <VerticallyCenteredModal
              show={InsurancemodalShow}
              onHide={() => SetInsurancemodalShow(false)}
              heading="Add your family members"
            >

              {popForInsurance}
              {formError && <div className="error-message d-flex justify-content-center">{formError}</div>}
            </VerticallyCenteredModal>
            <ErrorMessage formik={props.formik} fieldValue="healthInsurefor" />

          </Col>
          <Col md={12} sm={12}>
            <InputFieldDropdown
              formikFieldName="existanceIllness"
              label="Existing Illness"
              optionsArray={[
                { value: "", label: "Select" },
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ]}
              formik={props.formik}
            />
            <ErrorMessage formik={props.formik} fieldValue="existanceIllness" />
          </Col>

          <Col md={12} sm={12}>
            {props.formik.values.existanceIllness === "Yes" && (
              <div className="health-input">
                <p className="text-left-align">Medical History</p>
                <p className="text-left-align">
                  Do any member(s) have any illness for which they take any medications.
                </p>
                <Row className="custom-checkbox">
                  <Col lg={4} md={6} sm={6}>
                    <InputFieldCheckBox
                      formik={props.formik}
                      formikFieldName="illnesses.diabetes"
                      label="Diabetes"
                      checkedValue={props.formik.values.illnesses.diabetes}
                    />
                  </Col>

                  <Col lg={4} md={6} sm={6}>
                    <InputFieldCheckBox
                      formik={props.formik}
                      formikFieldName="illnesses.bloodPressure"
                      label="Blood Pressure"
                      checkedValue={props.formik.values.illnesses.bloodPressure}
                    />
                  </Col>
                  <Col lg={4} md={6} sm={6}>
                    <InputFieldCheckBox
                      formik={props.formik}
                      formikFieldName="illnesses.anySurgery"
                      label="Any Surgery"
                      checkedValue={props.formik.values.illnesses.anySurgery}
                    />
                  </Col>
                  <Col lg={4} md={6} sm={6}>
                    <InputFieldCheckBox
                      formik={props.formik}
                      formikFieldName="illnesses.thyroid"
                      label="Thyroid"
                      checkedValue={props.formik.values.illnesses.thyroid}
                    />
                  </Col>
                  <Col lg={4} md={6} sm={6}>
                    <InputFieldCheckBox
                      formik={props.formik}
                      formikFieldName="illnesses.asthma"
                      label="Asthma"
                      checkedValue={props.formik.values.illnesses.asthma}
                    />
                  </Col>
                  <Col lg={4} md={6} sm={6}>
                    <InputFieldCheckBox
                      formik={props.formik}
                      formikFieldName="illnesses.otherDiseases"
                      label="Other disease"
                      checkedValue={props.formik.values.illnesses.otherDiseases}
                    />
                  </Col>
                </Row>
                <ErrorMessage formik={props.formik} fieldValue="illnesses" />
              </div>
            )}
          </Col>
        </Row>
      </Form>
    </div>

  )
}



