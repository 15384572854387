import React, { useEffect, useState } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import InputFieldRadio from "../../commonModules/InputFieldRadio";
import Arrow from "../../../images/down-arrow.svg";
import UseFormContext from "../../../context/UseFormContext";
import SVG from "react-inlinesvg";

const RiderSelection = (props) => {
  const { id } = useParams();
  const formContext = UseFormContext();
  const [riders, setRiders] = useState([]);
  let history = useHistory();

  useEffect(() => {
    if (formContext.selectedHealthQuoteData && formContext.selectedHealthQuoteData.riderList) {
      setRiders(formContext.selectedHealthQuoteData.riderList);
    }
  }, [formContext.selectedHealthQuoteData]);

  const hospitalLimitValues = [
    { label: "Select", value: "" },
    { label: "5 lakhs", value: "5" },
    { label: "7 lakhs", value: "7" },
    { label: "7.5 lakhs", value: "7.5" },
    { label: "10 lakhs", value: "10" },
    { label: "15 lakhs", value: "15" },
    { label: "20 lakhs", value: "20" },
    { label: "25 lakhs", value: "25" },
    { label: "30 lakhs", value: "30" },
    { label: "35 lakhs", value: "35" },
    { label: "40 lakhs", value: "40" },
    { label: "45 lakhs", value: "45" },
    { label: "50 lakhs", value: "50" },
    { label: "75 lakhs", value: "75" },
    { label: "1 crore", value: "100" },
    { label: "1.5 crores", value: "150" },
    { label: "2 crores", value: "200" },
  ];

  const filteredRiders = riders.filter(rider => rider.amount !== 0 && rider.amount !== "");
  const ridersList = filteredRiders.filter(rider => rider.type === "M");
  const addOnsList = filteredRiders.filter(rider => rider.type === "O");

  // Helper function to format amounts
  const formatAmount = (amount) => {
    if (amount) {
      return new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(amount);
    }
    return "0";
  };

  // Function to handle adding or removing riders and add-ons
  const handleAddOrRemove = (item, type) => {
    if (type === "rider") {
      if (formContext.setSelectedRiders) {
        formContext.setSelectedRiders((prevState) => {
          const updatedRiders = [...prevState];
          const existingRiderIndex = updatedRiders.findIndex((r) => r.riderId === item.riderId);

          if (existingRiderIndex === -1) {
            updatedRiders.push(item); // Add the rider
          } else {
            updatedRiders.splice(existingRiderIndex, 1); // Remove the rider
          }
          return updatedRiders;
        });
      } else {
        console.error('setSelectedRiders is not available');
      }
    } else if (type === "addon") {
      if (formContext.setSelectedAddOns) {
        formContext.setSelectedAddOns((prevState) => {
          const updatedAddOns = [...prevState];
          const existingAddOnIndex = updatedAddOns.findIndex((a) => a.riderId === item.riderId);

          if (existingAddOnIndex === -1) {
            updatedAddOns.push(item); // Add the add-on
          } else {
            updatedAddOns.splice(existingAddOnIndex, 1); // Remove the add-on
          }

          return updatedAddOns;
        });
      } else {
        console.error('setSelectedAddOns is not available');
      }
    }
  };

  const isRiderAdded = (riderId) => {
    return formContext.selectedRiders && formContext.selectedRiders.some((r) => r.riderId === riderId);
  };

  const isAddOnAdded = (riderId) => {
    return formContext.selectedAddOns && formContext.selectedAddOns.some((a) => a.riderId === riderId);
  };

  const backButtonPressed = () => {
    formContext.setgetquotesApiFlag(false);
    history.push("/healthQuotes/1?");
  };

  return (
    <div>
      {/* Cover Amount Section */}
      <div className="bg-white p-3 radius-12 mt-3 mb-3" style={{ textAlign: "left" }}>
        <label className="mb-1 fw-600">Cover Amount</label>
        <p className="text-grey mb-0">Is this cover amount sufficient?</p>
        <Row>
          <Col sm={12} md={6} lg={6} className="pt-0">
            <FloatingLabel controlId="floatingInput" label="" aria-autocomplete="off">
              <Form.Select value={props.formik.values.sumInsured} disabled>
                <option value="">Select...</option>
                {hospitalLimitValues.map((item) => (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Row>
      </div>

   {/* Policy Period Section */}
   <div className="bg-white p-3 radius-12 mt-3 mb-3" style={{ textAlign: "left" }}>
        <label className="mb-1 fw-600">Policy Period</label>
        <p className="text-grey mb-0">Choosing multiyear plan saves your money and the trouble of remembering yearly renewals.</p>
        <Row>
          <Col sm={12} md={12} lg={12} className="pt-0">
            <InputFieldRadio
              formikFieldName="policyTerm"
              optionsArray={[{ value: "1", label: <>1 year </> }, { value: "2", label: <>2 year</> }, { value: "3", label: <>3 year</> }]}
              formik={props.formik}
              checkedClassName="checkedLabel"
            />
          </Col>
        </Row>
      </div>
      
      {/* Riders Section */}
      <div className="bg-white p-3 radius-12 mt-3 mb-3" style={{ textAlign: "left" }}>
        <label className="mb-1 fw-600">Riders</label>
        <p className="text-grey mb-4">You should get these additional benefits to enhance your current plan.</p>
        <div className="scroll-box">
          <div className="border-wrap-box">
            <h6 className="bordered-title">Select any 1 of 2</h6>
            <div className="riders-list-container" style={{ overflowY: 'auto' }}>
              {ridersList.length > 0 ? (
                ridersList.map((rider) => (
                  <div className="single-box" key={rider.id}>
                    <Row className="align-items-center">
                      <Col lg={7}>
                        <div className="policy-details">
                          <h6 className="mb-1 fs-13 fw-600">{rider.name}</h6>
                          <p className="text-grey mb-0">{rider.description}</p>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="policy-cost">
                          <h6 className="mb-1 fs-13 text-grey">Premium</h6>
                          <p className="mb-0"><strong>₹ {formatAmount(rider.amount)}</strong></p>
                        </div>
                      </Col>
                      <Col lg={2}>
                        <Button
                          className="primary-btn float-end"
                          size="xs"
                          onClick={() => handleAddOrRemove(rider, "rider")}
                        >
                          {isRiderAdded(rider.riderId) ? "Added" : "+ Add"}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))
              ) : (
                <p>No riders available.</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Add-ons Section */}
      <div className="bg-white p-3 radius-12 mt-3 mb-3" style={{ textAlign: "left" }}>
        <label className="mb-1 fw-600">Add-ons</label>
        <p className="text-grey mb-4">Consider these optional add-ons to further enhance your coverage.</p>
        <div className="scroll-box">
          <div className="border-wrap-box">
            <h6 className="bordered-title">Select any 1 of 2</h6>
            <div className="addons-list-container" style={{ maxHeight: '400px', overflowY: 'auto' }}>
              {addOnsList.length > 0 ? (
                addOnsList.map((addOn) => (
                  <div className="single-box" key={addOn.id}>
                    <Row className="align-items-center">
                      <Col lg={7}>
                        <div className="policy-details">
                          <h6 className="mb-1 fs-13 fw-600">{addOn.name}</h6>
                          <p className="text-grey mb-0">{addOn.description}</p>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="policy-cost">
                          <h6 className="mb-1 fs-13 text-grey">Premium</h6>
                          <p className="mb-0"><strong>₹ {formatAmount(addOn.amount)}</strong></p>
                        </div>
                      </Col>
                      <Col lg={2}>
                        <Button
                          className="primary-btn float-end"
                          size="xs"
                          onClick={() => handleAddOrRemove(addOn, "addon")}
                        >
                          {isAddOnAdded(addOn.riderId) ? "Added" : "+ Add"}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))
              ) : (
                <p>No add-ons available.</p>
              )}

              {
                !id &&
                <button
                  title="Back"
                  onClick={() => backButtonPressed()}
                  className="back-button"
                >
                  {" "}
                  <SVG src={Arrow} alt="" className="back-arrow" />
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiderSelection;
