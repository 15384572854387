
import React, { useEffect, useState, useMemo } from 'react';
import { Button, Card, Col, Container, Form, Row, Collapse } from 'react-bootstrap';
import UseFormContext from '../../../context/UseFormContext';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import InputFieldText from '../../commonModules/InputFieldText';
import InputFieldDropdown from '../../commonModules/InputFieldDropdown';
import InputFieldCheckBox from "../../commonModules/InputFieldCheckBox";
import { useFormik } from 'formik';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import reliance from "../../../images/reliance.png";
import Star from "../../../images/star.svg";
import Best from "../../../images/best-value.svg";
import Lowest from "../../../images/lowest.svg";
import careLogo from "../../../images/careLogo.jpg"
import futureGenrali from "../../../images/futureGenrali.png";
import ICICI from "../../../images/ICICI-insurance.png"
import Godigit from "../../../images/Godigit.png";
import NewIndia from "../../../images/new-india.png"
import SVG from "react-inlinesvg";
import { FullStar } from "../../pages/Rating/FullStar";
import { HalfStar } from "../../pages/Rating/HalfStar";
import { ZeroStar } from "../../pages/Rating/ZeroStar";
import * as api from "../../../API/authCurd";
import Arrow from "../../../images/down-arrow.svg";
import { date } from 'yup';
import InputFieldRadio from '../../commonModules/InputFieldRadio';
import { Margin } from 'react-to-pdf';
import "../../style/CSS/HealthQuote.css"
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Font Awesome icons for arrows

const HealthQuote = (props) => {
  const formContext = UseFormContext();
  const location = useLocation();
  const { id } = useParams();
  const history = useHistory();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [quotesResultsArray, setquotesResultsArray] = useState([]);
  const [loaderForQuotes, setloaderForQuotes] = useState(false);
  const [count, setcount] = useState(0);
  const [shareQuotesData, setshareQuotesData] = useState();
  const [previousPolicyTerm, setPreviousPolicyTerm] = useState('');
  const [memberDetailsOpen, setMemberDetailsOpen] = useState(true); // Default open
  const [insuranceForOpen, setInsuranceForOpen] = useState(true); // Default open

  var loadercount = 0;
  const imgArrayForCard = {
    "Care Health Insurance": careLogo,
  }
  const sumInsuredValues = [
    { label: "Select", value: "" },
    { label: "3 lakhs", value: "3" },
    { label: "5 lakhs", value: "5" },
    { label: "7 lakhs", value: "7" },
    { label: "7.5 lakhs", value: "7.5" },
    { label: "10 lakhs", value: "10" },
    { label: "15 lakhs", value: "15" },
    { label: "20 lakhs", value: "20" },
    { label: "25 lakhs", value: "25" },
    { label: "30 lakhs", value: "30" },
    { label: "35 lakhs", value: "35" },
    { label: "40 lakhs", value: "40" },
    { label: "45 lakhs", value: "45" },
    { label: "50 lakhs", value: "50" },
    { label: "75 lakhs", value: "75" },
    { label: "1 crore", value: "100" },
    { label: "1.5 crores", value: "150" },
    { label: "2 crores", value: "200" },
    { label: "3 crores", value: "300" },
    { label: "6 crores", value: "600" },
  ];

  const policyTermValues = [
    { label: '1 Year', value: "1" },
    { label: '2 Year', value: "2" },
    { label: '3 Year', value: "3" },
  ];

  const genderValues = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" }
  ]

  const initialData = {
    gender: location.state?.values?.gender ?? '',
    existanceIllness: location.state?.values?.existanceIllness ?? '',
    hospitalLimit: location.state?.values?.hospitalLimit ?? '',
    pincode: location.state?.values?.pincode ?? '',
    firstName: location.state?.values?.firstName ?? '',
    lastName: location.state?.values?.lastName ?? '',
    email: location.state?.values?.email ?? '',
    phoneNumber: location.state?.values?.phoneNumber ?? '',
    illnesses: location.state?.values?.illnesses
      ? {
        diabetes: location.state?.values?.illnesses.diabetes,
        bloodPressure: location.state?.values?.illnesses.bloodPressure,
        thyroid: location.state?.values?.illnesses.thyroid,
        asthma: location.state?.values?.illnesses.asthma,
        anySurgery: location.state?.values?.illnesses.anySurgery,
        otherDiseases: location.state?.values?.illnesses.otherDiseases,
      } : {
        diabetes: '',
        bloodPressure: '',
        thyroid: '',
        asthma: '',
        anySurgery: '',
        otherDiseases: '',
      },
    sumInsured: location.state?.values?.hospitalLimit ?? '',
    healthInsurefor: location.state?.values?.healthInsurefor ? JSON.parse(location.state?.values?.healthInsurefor) : [],
    policyTerm: location.state?.values?.policyTerm || '1',
  };

  const formik = useFormik({
    initialValues: initialData,
    onSubmit: (values) => {
      setIsFormDirty(false);
      apiToCreateHealthQuotes(values);
    },
  });

  console.log("################formik.values############", formik.values)

  useEffect(() => {
    const isPageRefreshed = sessionStorage.getItem("isPageRefreshed");

    if (isPageRefreshed) {
      let params = new URLSearchParams(window.location.search);
      const quotesID = params.get("quoteID");
      const shareId = sessionStorage.getItem("quoteId") || quotesID;
      console.log("QUOteID------->", shareId)

      history.replace(`/HealthPaymentFailed/${shareId}`);
    }
    sessionStorage.setItem("isPageRefreshed", "true");
    return () => {
      sessionStorage.removeItem("isPageRefreshed");
    };
  }, [history, api]);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    const quotesID = params.get("quoteID");
    if (quotesID) {
      // sessionStorage.setItem("quoteId", quotesID)
      formContext.setloaderStatus(true);
      setloaderForQuotes(true);
      console.log("####In if quote ID####")
      api
        .shareQuotes(quotesID)
        .then((resdata) => {

          const jsonData = JSON.parse(resdata.data);
          const data = jsonData.data


          const objectForHealth = {
            gender: data.healthQuoteRequest?.healthInfo?.healthInsurefor[0]?.gender ?? '',
            existanceIllness: data.healthQuoteRequest?.healthInfo?.existingIllness ?? '',
            hospitalLimit: data.healthQuoteRequest?.healthInfo?.sumInsured ?? '',
            pincode: data.healthQuoteRequest?.healthInfo?.pinCode ?? '',
            firstName: data.healthQuoteRequest?.healthInfo?.firstName ?? '',
            lastName: data.healthQuoteRequest?.healthInfo?.lastName ?? '',
            email: data.healthQuoteRequest?.healthInfo?.emailId ?? '',
            phoneNumber: data.healthQuoteRequest?.healthInfo?.mobileNumber ?? '',
            illnesses: {
              diabetes: data.healthQuoteRequest?.healthInfo?.existingIllnessDetails?.diabetes ? true : false,
              bloodPressure: data.healthQuoteRequest?.healthInfo?.existingIllnessDetails?.bloodPressure ? true : false,
              thyroid: data.healthQuoteRequest?.healthInfo?.existingIllnessDetails?.thyroid ? true : false,
              asthma: data.healthQuoteRequest?.healthInfo?.existingIllnessDetails?.asthma ? true : false,
              anySurgery: data.healthQuoteRequest?.healthInfo?.existingIllnessDetails?.anySurgery ? true : false,
              otherDiseases: data.healthQuoteRequest?.healthInfo?.existingIllnessDetails?.otherDiseases ? true : false,
            },
            sumInsured: data.healthQuoteRequest?.healthInfo?.sumInsured ?? '',
            healthInsurefor: data.healthQuoteRequest?.healthInfo?.healthInsurefor ?? [],
            policyTerm: data.healthQuoteRequest?.healthInfo?.policyTerm ?? '1',
          };

          console.log("sharedQuote objectForHealth---->", objectForHealth)
          formik.setValues(objectForHealth)

          console.log("objectForHealthQuote", data)
          //formik.setIsFormDirty(false);
          setIsFormDirty(false)

          formContext.setSelectedHealthQuoteData(data?.healthQuoteResponse);
          formContext.setHealthQuoteFormikData(objectForHealth);
          setquotesResultsArray(data?.healthQuoteResponse)

          const businessLineId = jsonData?.data.businessLineId;
          var nestedDataVeh;
          var nestedData;
          var nestedDate;
          setTimeoutMethod()//previously commented 
        })

        .catch((err) => {
          console.log("share quotes err", err);
        })
        .finally((fin) => {
          formContext.setloaderStatus(false);
          setloaderForQuotes(false);
        });
    }
  }, []);


  useEffect(() => {
    console.log("quotesResultsArray", quotesResultsArray);
    if (quotesResultsArray?.length > 0) {
      formContext.setquotesList(quotesResultsArray);
    }
  }, [quotesResultsArray]);

  //back button 
  useEffect(() => {
    if (id) {
      formContext.setgetquotesApiFlag(false);
    }
    if (id && formContext.healthQuoteFormikData) {
      formik.setValues({
        ...formik.initialValues,
        ...formContext.healthQuoteFormikData,
      });
      console.log("formContext.quotesList", formContext.quotesList)
      setquotesResultsArray(formContext.quotesList)
    }
  }, []);


  useEffect(() => {
    if (formik.dirty) {
      setIsFormDirty(true);
    }
  }, [formik?.values, formik?.dirty]);


  const handleCancel = () => {
    formik.resetForm();
    setTimeoutMethod();
  };
  const setTimeoutMethod = () => {
    setTimeout(() => {
      setIsFormDirty(false);
    }, 500);
  };

  const handleSave = () => {
    setshareQuotesData(formik?.values);
    formContext.setquotesPageFormikData(formik?.values);
    formik.handleSubmit();
  };

  const changeDOB = (event, memberId) => {
    formik.setFieldValue('healthInsurefor', formik.values.healthInsurefor.map((member) => {
      if (member.id === memberId) {
        member.dateOfBirth = event.target.value;
      }
      return member;
    }))
    formik.setIsFormDirty(true);
  };

  const apiResopnse = (data) => {
    const jsonData = JSON.parse(data);
    if (jsonData?.data?.quotes && jsonData?.data?.quotes.length) {
      setquotesResultsArray((prevdata) => { return [...prevdata, ...jsonData?.data?.quotes] })
    }
  }

  const apiToCreateHealthQuotes = (values) => {
    formContext.setloaderStatus(true);
    setloaderForQuotes(true);

    api
      .createHealthQuoteApi(values)  // Ensure the API call is using the current form values
      .then((data) => {
        console.log("valuessss->>>>>", data)
        redirectMethod(values, data); // Handle redirect or further actions
      })
      .catch((err) => {
        console.log("Error while fetching quotes", err);
        formContext.notifyError("An error occurred while fetching data.");
        formContext.setquotesPageFormikData();
      })
      .finally(() => {
        setIsFormDirty(false);
        setloaderForQuotes(false);
      });
  };

  const backButtonPressed = () => {
    history.push(formContext.routingPath);
  };
  //Nested -Call API - Health Quote 
  const getHealthQuoteResult = (singlerecord, currentIndex, actuallength) => {
    formContext.setloaderStatus(true);

    api
      .getHealthQuoteApi(
        singlerecord?.qname,
        singlerecord?.messageId,
        singlerecord?.QUOTE_ID
      )
      .then((data) => {

        apiResopnse(data, actuallength, currentIndex);
        console.log("data----->", data)
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setcount((prev) => prev + 1);
        loadercount = loadercount + 1;
        setTimeout(() => {
          if (actuallength === loadercount) {
            formContext.setloaderStatus(false);
            setloaderForQuotes(false);
          }
        }, 1000);
      });
  };

  //added
  useEffect(() => {
    setcount(0);
    loadercount = 0;
    const singlerecord = formContext.createquotesresult;
    if (singlerecord?.length > 0 && formContext.getquotesApiFlag) {
      setloaderForQuotes(true);
      for (let i = 0; i < singlerecord?.length; i++) {
        getHealthQuoteResult(singlerecord[i], i, singlerecord?.length);
      }
    }
  }, [formContext.createquotesresult]);

  const redirectMethod = (values, data) => {
    console.log("redirecttttttttttttttttttttttttttt from health quote --->>")
    formContext.setgetquotesApiFlag(true);
    setquotesResultsArray([]);
    try {
      const JsonDataForCreateQuotes = JSON.parse(data);

      console.log("Parsed Quote Data:", JsonDataForCreateQuotes);
      const quoteId = Array.isArray(JsonDataForCreateQuotes)
        ? JsonDataForCreateQuotes[0]?.QUOTE_ID
        : JsonDataForCreateQuotes?.QUOTE_ID;

      console.log("Quote ID:", quoteId);

      if (quoteId) {
        sessionStorage.setItem("quoteId", quoteId);
      } else {
        console.error("Quote ID is undefined");
      }

      formContext.setPolicyDates(JsonDataForCreateQuotes);
      console.log("Quotes create:", JsonDataForCreateQuotes.data);
      formContext.setcreatequotesresult(JsonDataForCreateQuotes.data);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  const giveRating = (rating) => {
    return (<div className="">
      {(rating >= 1)
        ? <FullStar />
        : (rating >= 0.5)
          ? <HalfStar />
          : <ZeroStar />
      }
      {(rating >= 2)
        ? <FullStar />
        : (rating >= 1.5)
          ? <HalfStar />
          : <ZeroStar />
      }
      {(rating >= 3)
        ? <FullStar />
        : (rating >= 2.5)
          ? <HalfStar />
          : <ZeroStar />
      }
      {(rating >= 4)
        ? <FullStar />
        : (rating >= 3.5)
          ? <HalfStar />
          : <ZeroStar />
      }
      {(rating >= 5)
        ? <FullStar />
        : (rating >= 4.5)
          ? <HalfStar />
          : <ZeroStar />
      }
    </div>
    )
  }

  console.log("quotesResultsArray final", quotesResultsArray)

  return (
    <>
      <Container fluid className="get-quotes-wrap">
        <Row className='p-3 first-row' style={{ boxShadow: 'none' }}>
          <Col sm={12} md={12}>
            <Form className='form-ui' style={{ maxWidth: '100%' }}>


              {/* Member Details Section */}
              <Row className="d-flex inner-form-box">
                <Col sm={12} md={12} style={{ textAlign: 'left' }}>
                  <div className='d-flex align-items-center'>
                    <b>Policy Details</b>
                    <Button
                      variant="link"
                      className="p-0"
                      onClick={() => setMemberDetailsOpen(!memberDetailsOpen)}
                      style={{ fontSize: '14px', marginLeft: '10px', color: '#ec2d82', verticalAlign: 'bottom' }}
                    >
                      {memberDetailsOpen ? <FaChevronUp /> : <FaChevronDown />}
                    </Button>
                  </div>
                </Col>

                <Collapse className='row' in={memberDetailsOpen}>
                  <div>
                    <Col sm={12} md={3}>
                      <InputFieldDropdown formik={formik} formikFieldName="sumInsured" label="Sum Insured" optionsArray={sumInsuredValues} />
                    </Col>
                    <Col sm={12} md={3}>
                      <InputFieldDropdown
                        formik={formik}
                        formikFieldName="policyTerm"
                        label="Policy Term"
                        optionsArray={policyTermValues}
                        value={formik.values.policyTerm} // Display current value
                      />
                    </Col>
                    <Col sm={12} md={3}>
                      <InputFieldDropdown formikFieldName="gender" label="Gender" formik={formik} optionsArray={genderValues} />
                    </Col>
                    <Col sm={12} md={3}>
                      <InputFieldText formik={formik} formikFieldName="email" label="Email" placeholder="Email" />
                    </Col>
                    <Col sm={12} md={3}>
                      <InputFieldText formik={formik} formikFieldName="phoneNumber" label="Phone no" placeholder="Phone no" />
                    </Col>
                    <Col sm={12} md={3}>
                      <InputFieldText formik={formik} formikFieldName="pincode" label="Pincode" placeholder="Pincode" />
                    </Col>
                  </div>
                </Collapse>
              </Row>
              <br></br>


              <Row className="inner-form-box">
                <Col sm={12} md={12} style={{ textAlign: 'left' }}>
                  <div className='d-flex align-items-center'>
                    <b>Insurance For</b>
                    <Button
                      variant="link"
                      className="p-0"
                      onClick={() => setInsuranceForOpen(!insuranceForOpen)}
                      style={{ fontSize: '14px', marginLeft: '10px', color: '#ec2d82' }}
                    >
                      {insuranceForOpen ? <FaChevronUp /> : <FaChevronDown />}
                    </Button>
                  </div>
                </Col>

                <Collapse in={insuranceForOpen} className='row'>
                  <div>
                    {formik.values.healthInsurefor.map((member) => (
                      <React.Fragment key={member.id}>
                        <Col sm={6} md={1} className="d-flex justify-content-left align-items-center">
                          <div>{member.relation}</div>
                        </Col>

                        <Col sm={12} md={3}>
                          <FloatingLabel
                            controlId={`dateOfBirth-${member.id}`}
                            label="Date of birth"
                            aria-autocomplete="off"
                          >
                            <Form.Control
                              name={`dateOfBirth-${member.id}`}
                              type="date"
                              placeholder=""
                              className="floating-input"
                              value={member.dateOfBirth}
                              max={new Date()?.toISOString()?.slice(0, 10)}
                              onChange={(event) => changeDOB(event, member.id)}
                            />
                          </FloatingLabel>
                        </Col>
                      </React.Fragment>
                    ))}
                  </div>
                </Collapse>

                <Col md={12} sm={12} className="mt-3">
                  <div className="health-input">
                    {formik.values.existanceIllness === "Yes" ? (
                      <>
                        <p className="text-left-align"><b>Medical History</b></p>
                        <Row className="custom-checkbox align-items-center">
                          <Col lg={2}>
                            <InputFieldCheckBox formik={formik}
                              style={{ width: '15px', height: '15px', marginLeft: '6px' }}
                              formikFieldName="illnesses.diabetes"
                              className="cust-checkbox"
                              label="Diabetes"
                              checkedValue={formik.values.illnesses.diabetes} />
                          </Col>
                          <Col lg={2}>
                            <InputFieldCheckBox formik={formik} formikFieldName="illnesses.bloodPressure" label="Blood Pressure" checkedValue={formik.values.illnesses.bloodPressure} />
                          </Col>
                          <Col lg={2}>
                            <InputFieldCheckBox formik={formik} formikFieldName="illnesses.anySurgery" label="Any Surgery" checkedValue={formik.values.illnesses.anySurgery} />
                          </Col>
                          <Col lg={2}>
                            <InputFieldCheckBox formik={formik} formikFieldName="illnesses.thyroid" label="Thyroid" checkedValue={formik.values.illnesses.thyroid} />
                          </Col>
                          <Col lg={2}>
                            <InputFieldCheckBox formik={formik} formikFieldName="illnesses.asthma" label="Asthma" checkedValue={formik.values.illnesses.asthma} />
                          </Col>
                          <Col lg={2}>
                            <InputFieldCheckBox formik={formik} formikFieldName="illnesses.otherDiseases" label="Other disease" checkedValue={formik.values.illnesses.otherDiseases} />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <p className="text-left-align"><b>Existing illness</b></p>
                        <InputFieldText formik={formik} formikFieldName="existanceIllness" label="existanceIllness" />
                      </>
                    )}
                  </div>
                </Col>
              </Row>

              {isFormDirty && (
                <div className="text-center mt-4 footer-btn-wrap">
                  <Button className="back-btn" onClick={handleCancel}>Cancel</Button>
                  <Button className="primary-btn" onClick={handleSave}>Save</Button>
                </div>
              )}
            </Form>
          </Col>
        </Row>
        <Row className="card-wrap">
          {quotesResultsArray && quotesResultsArray.length > 0 && quotesResultsArray?.filter((filterData) => (Number(filterData?.netPremium)) !== 0)?.map((data, index) => {
            const rating = (((data?.insurerIndex) + (data?.claimIndex) + 4.0) / 3)?.toFixed(1);
            return (
              <Card key={index}>
                <Card.Body>
                  <div className="card-top card-header">
                    <div className="logo-wrap">
                      <img
                        // src={imgArrayForCard?.[data?.insuranceCompany]}
                        src={careLogo}
                        alt=""
                        className="logo"
                      />
                    </div>

                    <div className="premium">
                      {/* <p className="label-text">Plan Type</p> */}
                      <p className="premium-text">{data?.planName}</p>
                    </div>

                  </div>

                  <div className="offer-wrap">
                  </div>
                  <div className="card-middle card-details">
                    <div className="card-rating-wrap">
                      <div className="card_rating mb-0">
                        <span className="label-text">Rating</span>
                        <span className="label-text badge">{rating}</span>
                      </div>
                      <div className="star-icon-wrap">
                        {giveRating(rating)}
                      </div>
                    </div>

                    <div className="premium">
                      <p className="label-text">Premium</p>
                      <p className="premium-text">₹ {new Intl.NumberFormat('en-IN').format(Math.round(data?.basePremium))}</p>
                    </div>

                  </div>
                  <div className="card-bottom rating d-flex gap-4 justify-content-center">
                    <div className="premium">
                      <div className="star rating-star">
                        <SVG
                          src={Star}
                          alt=""
                          className="rating-star-icon"
                          fill="#d4d4d4"
                        />
                        <span className="rating-point">4.0</span>
                      </div>
                      <p className="premium-text">Premium</p>
                    </div>
                    <div className="premium">
                      <div className="star rating-star">
                        <SVG
                          src={Star}
                          alt=""
                          className="rating-star-icon"
                          fill="#d4d4d4"
                        />
                        <span className="rating-point">{(data?.insurerIndex)?.toFixed(1)}</span>
                      </div>
                      <p className="premium-text">Servicing</p>
                    </div>

                    <div className="premium">
                      <div className="star rating-star">
                        <SVG
                          src={Star}
                          alt=""
                          className="rating-star-icon"
                          fill="#d4d4d4"
                        />
                        <span className="rating-point">{(data?.claimIndex)?.toFixed(1)}</span>
                      </div>
                      <p className="premium-text">Claims</p>
                    </div>
                  </div>


                  <div className="card-top card-header" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                      variant="primary"
                      className="buy-btn"
                      onClick={() => {
                        formContext.setSelectedHealthQuoteData(data);
                        formContext.setHealthQuoteFormikData(formik?.values);
                        history.push("/healthProposal");
                        formContext.setloaderStatus(false);

                      }}

                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      Buy Now
                    </Button>
                  </div>
                  <button
                    title="Back"
                    onClick={() => backButtonPressed()}
                    className="back-button"
                  >
                    {" "}
                    <SVG src={Arrow} alt="" className="back-arrow" />
                  </button>
                </Card.Body>
              </Card>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default HealthQuote;
