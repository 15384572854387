import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import MultistepProposal from './MultistepProposal';
import UseFormContext from '../../../context/UseFormContext';
import "../../style/healthProposal.scss";

const HealthProposal = (props) => {
  const formContext = UseFormContext();
  let history = useHistory();

  // Helper function to format premium amounts
  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(amount);
  };

  // Function to calculate the total premium
  const calculateTotalPremium = () => {
    const basePremium = Number(formContext.selectedHealthQuoteData?.basePremium) || 0;
    const totalRidersPremium = formContext.selectedRiders.reduce((sum, rider) => {
      return sum + (Number(rider.amount) || 0);
    }, 0);
    const totalAddOnsPremium = formContext.selectedAddOns.reduce((sum, addOn) => {
      return sum + (Number(addOn.amount) || 0);
    }, 0);
    // Return the total premium
    return basePremium + totalRidersPremium + totalAddOnsPremium;
  };

  // Navigate to specific section (Riders or Add-ons)
  const handleViewSection = (section) => {
    const sectionId = section === 'riders' ? '#riders-section' : '#addons-section';
    history.push({
      pathname: window.location.pathname,
      hash: sectionId
    });
  };

  const backButtonPressed = () => {
    formContext.setgetquotesApiFlag(false);
    history.push("/quotes/1");
  };

  useEffect(() => {
    document.body.classList.add("car-page");
    return () => {
      document.body.classList.remove("car-page");
    };
  }, []);

  // Default to empty arrays if selectedRiders or selectedAddOns are undefined
  const selectedRiders = formContext.selectedRiders || [];
  const selectedAddOns = formContext.selectedAddOns || [];
  const totalPremium = calculateTotalPremium();

  return (
    <div className="proposal-wrap rider-selection-wrap">
      <Container fluid className="form-bg">
        <Row>
          <Col className="p-left" sm={12} md={4}>
            <div className="leftside-form">
              <h3 className="title mb-3">Premium Amount ₹ {formatAmount(totalPremium)}</h3>
              <div className="summary-details mb-3" style={{ textAlign: "left" }}>
                <div className="heading-title pt-2 pb-2">
                  <h6 className="title_text mb-0 fw-600">Summary</h6>
                </div>
                <div className="content-wrap">
                  <div className="amount-info">
                    <div className="amount-item">
                      <span className="text-grey text-left">Base Premium - 1 Year:</span>
                      <span className="text-right">₹ {formatAmount(formContext.selectedHealthQuoteData?.basePremium)}</span>
                    </div>
                  </div>
                  <div className="mt-3 mb-3">
                    <label className="mb-1 fw-600">Select rider(s)</label>
                    <div className="amount-item border-box d-block">
                      {selectedRiders.length === 0 ? (
                        <div className="amount-item">
                          <label className="text-grey">Missing out on benefits</label>
                          {/* <a className="text-success decoration-none" onClick={() => handleViewSection('riders')}>View riders</a> */}
                        </div>
                      ) : (
                        <div>
                          {selectedRiders.map((rider, index) => (
                            <div key={index} className="add-ons-items">
                              <span className="text-grey text-left">{rider.name}: </span>
                              <span className="text-right">₹ {formatAmount(rider.amount)}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-3 mb-3">
                    <label className="mb-1 fw-600">Select Add-ons</label>
                    <div className="amount-item border-box d-block">
                      {selectedAddOns.length === 0 ? (
                        <div className="amount-item">
                          <label className="text-grey">No add-ons Selected</label>
                          {/* <a className="text-success decoration-none" onClick={() => handleViewSection('addons')}>View add-ons</a> */}
                        </div>
                      ) : (
                        <div>
                          {selectedAddOns.map((addOn, index) => (
                            <div key={index} className="add-ons-items">
                              <span className="text-grey text-left">{addOn.name}</span>
                              <span className="text-right">₹ {formatAmount(addOn.amount)}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="total-info content-bottom-wrap">
                  <div className="amount-item total-item" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="text-left">Total Premium</span>
                    <span className="text-right">₹ {formatAmount(totalPremium)}</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={8} className="mb-3">
            <MultistepProposal healthMainFormData={formContext.healthMainFormData} healthQuoteFormikData={formContext.healthQuoteFormikData} selectedHealthQuoteData={formContext.selectedHealthQuoteData} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HealthProposal;


