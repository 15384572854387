/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Button, Card } from "react-bootstrap";
import Arrow from "../../images/down-arrow.svg";
import Share from "../../images/share.svg";
import reliance from "../../images/reliance.png";
import Star from "../../images/star.svg";
import Best from "../../images/best-value.svg";
import Lowest from "../../images/lowest.svg";
import futureGenrali from "../../images/futureGenrali.png";
import ICICI from "../../images/ICICI-insurance.png";
import Godigit from "../../images/Godigit.png";
import SVG from "react-inlinesvg";
import { useHistory, useParams } from "react-router-dom";
import VerticallyCenteredModal from "../commonModules/Popups/VerticallyCenteredModal";
import { useLocation } from "react-router";
import InputFieldCheckBox from "../../components/commonModules/InputFieldCheckBox";
import InputFieldRadio from "../../components/commonModules/InputFieldRadio";
import NewIndia from "../../images/new-india.png";
import InputFieldText from "../commonModules/InputFieldText";
import * as Yup from "yup";
import { useFormik } from "formik";
import ErrorMessage from "../commonModules/ErrorMessage";
import InputFieldDropdown from "../commonModules/InputFieldDropdown";
import * as api from "../../API/authCurd";
import UseFormContext from "../../../src/context/UseFormContext";
import { PageNotFound } from "../commonModules/PageNotFound";
import { FullStar } from "../pages/Rating/FullStar";
import { HalfStar } from "../pages/Rating/HalfStar";
import { ZeroStar } from "../pages/Rating/ZeroStar";
import {
  yearValidation,
  expDateValidation,
  dateCompare,
  getYearDropdown,
} from "../commonModules/CommonCode";
import InputFieldTextRTO from "../commonModules/InputFieldTextRTO";
import { convertDate } from "../commonModules/CommonCode";
import { propTypes } from "react-bootstrap/esm/Image";
import AutoSuggestFile from "../commonModules/AutoSuggestFile";
import { SkeletonCard } from "../commonModules/SkeletonCard";
import SignIn from "../../components/pages/SignIn";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaEye } from "react-icons/fa";
import generatePDF from "react-to-pdf";
import HDFC from "../../images/hdfc-ergo-gi.png";
import Bajaj from "../../images/bajaj.png";
import {
  VehicalNotFoundModal,
  CarPolicy,
} from "../commonModules/Popups/PopupPages";

import {
  bikeFormikIntialData,
  initialDataQuotesPage,
} from "../../components/commonModules/CommonCode";
import IMAGE from "../../images/Godigit.png";
import {
  EmailIcon,
  EmailShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import CrmService from "../../services/crm.service";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { NonPaFormForFuture } from "../pages/NonPaFormForFuture";
var CryptoJS = require("crypto-js");
export default function QuotesPage(props) {
  // -----------------------State and var----------------------------
  const dealId = localStorage.getItem("dealId");
  console.log("dealId", dealId);
  let location = useLocation();
  const { id } = useParams();
  const formContext = UseFormContext();
  let history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [childForPopup, setchildForPopup] = useState();
  const [headingForPopup, setheadingForPopup] = useState();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [quotesResultsArray, setquotesResultsArray] = useState([]);
  const prevPageType = location?.state?.prePage;
  const valuesForExpDate = expDateValidation();
  const [optArrayForPolicyExp, setoptArrayForPolicyExp] = useState(
    valuesForExpDate?.optionsArray
  );
  const [tpPolicyStatus, settpPolicyStatus] = useState(false);
  const [quotePremiumPopup, setQuotePremiumPopup] = useState(false);
  const [riderStatus, setriderStatus] = useState(false);
  const [addOnArray, setaddOnArray] = useState([]);
  const [personalAccCover, setpersonalAccCover] = useState();
  const rtoPattern =
    /^(?:[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}|[A-Z]{2}\d{2} ?[A-Z]{1,2} ?\d{4}|CD \d{2} \d{3}|[A-Z]{2} \d{5}|[A-Z]{2}\d[A-Z]{1,2} ?[A-Z]{1,3} ?\d{4}|[0-9]{2}BH[0-9]{4})$/;

  const [isTextVisible, setIsTextVisible] = React.useState(
    rtoPattern.test(formContext.carbikeformikValues?.registeredRto) ? 0 : 1
  );
  console.log(
    "formContext.carbikeformikValues?.registeredRto",
    formContext.carbikeformikValues?.registeredRto,
    rtoPattern.test(formContext.carbikeformikValues?.registeredRto)
  );
  //console.log("isTextVisible",isTextVisible)
  const registeredRtoInputRef = useRef(null);
  const [errorForOdPopup, seterrorForOdPopup] = useState(false);
  const [errorMessageForOdPopup, seterrorMessageForOdPopup] = useState("");
  const [idvSelectedValue, setidvSelectedValue] = useState(0);
  const [idvPopupStatus, setidvPopupStatus] = useState(false);
  const [loaderForQuotes, setloaderForQuotes] = useState(false);
  const [ownedByPopup, setownedByPopup] = useState(false);
  const [ownedByPopuppreviousValue, setownedByPopuppreviousValue] = useState();
  const [netPremiumForBrekupvalue, setnetPremiumForBrekupvalue] = useState();
  const [count, setcount] = useState(0);
  const [vechicleInfoFromRto, setvechicleInfoFromRto] = useState();
  const [vehicalNotFound, setVehicalNotFound] = React.useState(false);
  const [rtoVarApi, setrtoVarApi] = useState(false);
  const [paPopup, setPaPopup] = useState(false);
  const [amountArray, setamountArray] = useState([
    { value: 10000, label: "10,000" },
    { value: 20000, label: "20,000" },
    { value: 30000, label: "30,000" },
    { value: 40000, label: "40,000" },
    { value: 50000, label: "50,000" },
    { value: 60000, label: "60,000" },
    { value: 70000, label: "70,000" },
    { value: 80000, label: "80,000" },
    { value: 90000, label: "90,000" },
    { value: 100000, label: "1,00,000" },
    { value: 120000, label: "1,20,000" },
    { value: 130000, label: "1,30,000" },
    { value: 140000, label: "1,40,000" },
    { value: 150000, label: "1,50,000" },
    { value: 160000, label: "1,60,000" },
    { value: 170000, label: "1,70,000" },
    { value: 180000, label: "1,80,000" },
    { value: 190000, label: "1,90,000" },
    { value: 200000, label: "2,00,000" },
  ]);
  const objForCreateLead = {
    customer_name: "",
    mobile_number: "",
    caller_name: "",
    email_id: "",
    residential_address: "",
    date_of_birth: "",
    pan_number: "",
    Policy_Exp_date: "",
    policy_no: "",
    insurance_company: "",
    state: "",
    pincode: "",
    RTO_no: "",
  };
  const objForUpdateStage = {
    customer_name: "",
    mobile_number: "",
    module: "motor",
    RTO_no: "",
    quote_stage: "completed",
    quote_link: "",
    KYC_Status: "Not completed",
    KYC_link: "",
    proposal_stage: "Not completed",
    proposal_link: "",
    payment_link: "",
    policy_stage: "Not completed",
    policy_link: "",
  };
  var loadercount = 0;
  const ref = useRef();
  const [idvValue, setidvValue] = useState({
    minValue: 1000000,
    maxValue: 0,
    insuredDeclareValue: 0,
    netPremium: 100000,
    betstValue: 0,
  });
  const [ManufacturingDateArray, setManufacturingDateArray] =
    useState(getYearDropdown);
  const [shareQuotesPopup, setshareQuotesPopup] = useState(false);
  const [shareUrl, setshareUrl] = useState(
    process.env.REACT_APP_FRONTEND_SERVER
  );
  const [shareQuotesData, setshareQuotesData] = useState();
  const title = "chek out this quotes on policies365.com";
  const policyType = [
    {
      value: "OD-TP",
      label: "Comprehensive",
      OverlayTriggerValue: "Covers damages to own vehicle and third party",
    },
    {
      value: "OD",
      label: "OD Only",
      OverlayTriggerValue: "Covers damages to own vehicle only",
    },
    {
      value: "TP",
      label: "TP Only",
      OverlayTriggerValue:
        "Covers damage to the third-party vehicle, personal property and physical injury",
    },
  ];
  const [arrayForPolicyType, setarrayForPolicyType] = useState(policyType);

  var riderName = "";
  var riderAmount = 0;
  const [riderAmoutForFuture, setriderAmoutForFuture] = useState(0);
  const [riderNameForFuture, setriderNameForFuture] = useState();

  const tpStartDateArray = location?.state?.values?.tpPolicyStartDate
    ? location?.state?.values?.tpPolicyStartDate.split("-")
    : null;
  const tpEndDateArray = location?.state?.values?.tpPolicyEndtDate
    ? location?.state?.values?.tpPolicyEndtDate.split("-")
    : null;

  const initialData = {
    registeredRto: formContext.carbikeformikValues?.registeredRto
      ? formContext.carbikeformikValues?.registeredRto
      : location?.state?.values?.registeredRto
      ? location?.state?.values?.registeredRto
      : "",
    make: location?.state?.values?.make ? location?.state?.values?.make : "",
    model: location?.state?.values?.model ? location?.state?.values?.model : "",
    varient: location?.state?.values?.varient
      ? location?.state?.values?.varient
      : "",
    registrationYear: location?.state?.values?.registrationYear
      ? location?.state?.values?.registrationYear
      : "",
    fuel: location?.state?.values?.fuel
      ? location?.state?.values?.fuel
      : "petrol",
    expiryDate: location?.state?.values?.previousPolicy
      ? convertDate(location?.state?.values?.previousPolicy, 2)
      : "",
    insuranceClaim: location?.state?.values?.insuranceClaim
      ? location?.state?.values?.insuranceClaim
      : "",
    noClaimBonus: location?.state?.values?.noClaimBonus
      ? location?.state?.values?.noClaimBonus
      : "",
    policyType: location?.state?.values?.policyType
      ? location?.state?.values?.policyType
      : "",
    policyTerms: location?.state?.values?.policyTerms
      ? location?.state?.values?.policyTerms
      : "",
    idv: "",
    ownedBy: "Individual",
    zeroDep: "",
    zeroDepPlan: "",
    personalCover: 100000,
    personalCoverFlag: "",
    personalAccidentCover: true,
    existingPersonalCoverFlag: "",
    driverAccidentCover: "",
    driverAccidentCoverAmount: 10000,
    lpgCngKit: true,
    lpgCngKitType: "",
    lpgCngKitAmount: 10000,
    lpgCngKitPYP: "No",
    accessories: "",
    electricalAccessories: "",
    electricalAccessoriesAmount: 10000,
    nonElectricalAccessories: "",
    nonElectricalAccessoriesAmount: 10000,
    roadSideAssistance: "",
    roadSideAssistancePlan: "RSA-199",
    ncbProtection: "",
    ncbPlan: "plan1",
    engineProtector: "",
    tyreProtection: "",
    tyreDetails: "",
    tyreProtectionAmount: "",
    keyProtection: "",
    keyProtectionAmount: "KP1",
    consumablesCover: "",
    baggageCover: "",
    invoiceCover: "",
    transportHotelExpenses: "",
    transportHotelExpensesAmount: "",
    employeeAccidentCover: "",
    employeeAccidentCoversAmount: 1,
    voluntaryDeductableCover: "",
    voluntaryDeductableCoverAmount: 1000,
    emeCover: "",
    emeCoverNumber: "1",
    emeCoverAmount:
      formContext?.carbikeformikValues?.formtype === "bike"
        ? "EME-99"
        : "EME-Standard",
    liabilityToPaidDriver: "",
    liabilityToPaidDriverAmount: 1,
    smartSaverPlus: false,
    smartSaverPlusPlan: "smartSaverPlus",
    smartSaver: false,
    smartSaverPlan: "T+2*750",
    garageCash: "",
    garageCashPlan: "garageCashPlan",
    emiProtect: "",
    emiCount: "1",
    emiAmount: "",
    IsTyreProtect: "no",
    IsEngineProtect: "no",
    IsZeroDepriciation: "no",
    IsConsumable: "no",
    IsRTI: "no",
    batteryProtectCover: "",
    validLicence: false,
    addOnCover: [],
    tpPolicyStartDate:
      tpStartDateArray === null
        ? null
        : tpStartDateArray?.[0] +
          "-" +
          tpStartDateArray?.[1] +
          "-" +
          tpStartDateArray?.[2],
    tpPolicyEndtDate:
      tpEndDateArray === null
        ? null
        : tpEndDateArray?.[0] +
          "-" +
          tpEndDateArray?.[1] +
          "-" +
          tpEndDateArray?.[2],

    insuranceFor: location?.state?.values?.insuranceFor,
    isvehNumberMissing: location?.state?.values?.isvehNumberMissing,
    idvSelectedValue: "",
    formtype: location?.state?.values.formtype
      ? location?.state?.values.formtype
      : "",
    alternatePolicyExpDateFuture: "",
    existingPAPolicyNo: "",
    existingPASumInsured: 1500000,
    insurerName: "",
    validLicense: "",
    futurePA:"",
  };

  const imgArrayForCard = {
    "Reliance General Insurance Co. Ltd": reliance,
    "New India Assurance": NewIndia,
    "Future Generali India Insurance Co. Ltd": futureGenrali,
    "GO DIGIT General Insurance CO. LTD": Godigit,
    "ICICI Lombard General Insurance Co. Ltd": ICICI,
    "HDFC ERGO General Insurance Company": HDFC,
    "Bajaj Allianz General Insurance Co. Ltd": Bajaj,
  };

  const validationSchema = Yup.object().shape({
    registeredRto: Yup.string().when("isvehNumberMissing", {
      is: (isvehNumberMissing) => isvehNumberMissing === true,
      then: () =>
        Yup.string()
          .required("Vehicle Rto code is required")
          .matches(
            /^[A-Za-z]{2}\d{2}$/,
            "Invalid Vehicle RTO code, e.g., MH12"
          ),
      otherwise: () =>
        Yup.string()
          .required("Vehicle Registration Number Is Required")
          .matches(
            /^(?:[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}|[A-Z]{2}\d{2} ?[A-Z]{1,2} ?\d{4}|CD \d{2} \d{3}|[A-Z]{2} \d{5}|[A-Z]{2}\d[A-Z]{1,2} ?[A-Z]{1,3} ?\d{4}|[0-9]{2}BH[0-9]{4})$/,
            "Invalid Vehicle Number, e.g., MH12AB1234, DL1SBF1234, or 22BH0000"
          ),
    }),

    make: Yup.string().trim().required("Make Field Is Required"),
    model: Yup.string().trim().required("Model Field Is Required"),
    varient: Yup.string().trim().required("Variant Field Is Required"),

    registrationYear: Yup.string()
      .required("Year Field is Required")
      .test("not blank", "Select value", (value) => value !== "")
      .test("valid date", "Future date is not allowed", (value) => {
        const currentDate = new Date();
        const inputDate = new Date(value);
        return inputDate <= currentDate;
      })
      .test(
        "valid range",
        "Date should be within the past 50 years",
        (value) => {
          return yearValidation(value, 50);
        }
      ),
    expiryDate: Yup.date().required("Date is Required"),
    insuranceClaim: Yup.string()
      .trim()
      .required("Insurance Claim Field Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),
    noClaimBonus: Yup.string()
      .trim()
      .required("Field Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),
    policyType: Yup.string().trim().required("Policy Type Field Is Required"),
    // policyTerms: Yup.string()
    //   .trim()
    //   .required("policy  Terms Field Is Required"),

    policyTerms: Yup.string().when(["formtype"], {
      is: (formtype) => formtype === "bike",
      then: () => Yup.string().required("Vehicle Rto code is required"),
    }),

    electricalAccessories: Yup.boolean(),
    electricalAccessoriesAmount: Yup.number()
      .typeError("Amount must be a number")
      .when("electricalAccessories", {
        is: true,
        then: (schema) =>
          schema
            .required("Electrical Accessories Amount is required")
            .min(1, "Amount must be greater than 0")
            .max(1000000, "Amount cannot exceed 10,00,000"),
        otherwise: (schema) => schema.notRequired(),
      }),
    nonElectricalAccessories: Yup.boolean(),
    nonElectricalAccessoriesAmount: Yup.number()
      .typeError("Amount must be a number")
      .when("nonElectricalAccessories", {
        is: true,
        then: (schema) =>
          schema
            .required("Non-Electrical Accessories Amount is required")
            .min(1, "Amount must be greater than 0")
            .max(200000, "Amount cannot exceed 2,00,000"),
        otherwise: (schema) => schema.notRequired(),
      }),
    lpgCngKit: Yup.boolean(),
    lpgCngKitAmount: Yup.number()
      .typeError("Amount must be a number")
      .when("lpgCngKit", {
        is: true,
        then: (schema) =>
          schema
            .required("Accessories Amount is required")
            .min(1, "Amount must be greater than 0")
            .max(200000, "Amount cannot exceed 2,00,000"),
        otherwise: (schema) => schema.notRequired(),
      }),
    emiAmount: Yup.number()
      .typeError("Amount must be a number")
      .min(0, "Amount must be greater than or equal to 0")
      .max(9999999, "Amount cannot exceed 99,99,999")
      .when("emiProtect", {
        is: true,
        then: (schema) =>
          schema.required(
            "EMI amount is required when EMI Protect is selected"
          ),
        otherwise: (schema) => schema.notRequired(),
      }),
    emiProtect: Yup.boolean(),
    // keyProtectionAmount: Yup.number()
    //   .typeError("Amount must be a number")
    //   .min(0, "Amount must be greater than or equal to 0")
    //   .max(200000, "Amount cannot exceed 2,00,000")
    //   .test(
    //     "is-multiple-of-500",
    //     "Amount must be a multiple of 500",
    //     (value) => value % 500 === 0
    //   ),
  });

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsFormDirty(false);
      apiTocreateQuotes(values, idvSelectedValue);
    },
  });
  console.log("formik.values", formik.values, formik.errors);

  useEffect(() => {
    // Set a timeout to fetch data after 2000 milliseconds

    const getData = setTimeout(() => {
      // Check if the vehicle registration number is valid and certain conditions are met
      if (
        formik.values.registeredRto?.length >= 9 &&
        formContext.rtoData?.rtoUserId &&
        !isTextVisible &&
        rtoVarApi
      ) {
        formContext.setloaderStatus(true);
        // Fetch data from the RTO API
        // api.rtoGetData(formContext.rtoData?.rtoUserId, formContext.rtoData?.rtoId, formik.values.registeredRto).then(
        api
          .vahanDataCouchbase(formik.values.registeredRto)
          .then((response) => {
            console.log("response", response);
            // Set vehicle information obtained from the RTO
            setvechicleInfoFromRto(response.data.data.result);
            // Check if the vehicle category is not "2WN" (2-wheeler)
            formContext.setvahanData(response.data.data.result);
            if (response?.data?.data?.result?.vehicleCategory !== "2WN") {
              // Set a flag indicating that it's a car policy
              // setCarPolicy(true)
            }
            formContext.setloaderStatus(false);
            if (response?.data?.data?.error?.message) {
              formContext.notifyError(response?.data?.data?.error?.message);
            }
          })
          .catch((err) => {
            console.log("err", err);
            if (
              err?.response?.data?.error?.message ===
              "Vehicle Number is not valid"
            ) {
              setVehicalNotFound(true);
            } else {
              formContext.notifyError("Could not found data for vechicle");
            }
          })
          .finally(() => {
            formContext.setloaderStatus(false);
          });
      } else if (
        formik.values.registeredRto?.length >= 9 &&
        !formContext.rtoData?.rtoUserId
      ) {
        formContext.setLoginData();
        // Update the registeredRto field in the formik values

        formik.setFieldValue("registeredRto", formik.values.registeredRto);

        formContext.setloaderStatus(false);
      }
    }, 2000);

    // Cleanup function to clear the timeout when the component unmounts or when the dependency changes
    return () => clearTimeout(getData);
  }, [formik.values.registeredRto]);

  useEffect(() => {
    if (vechicleInfoFromRto?.model) {
      const regDate = vechicleInfoFromRto.regDate.split("/");
      formik.setFieldValue("fuel", vechicleInfoFromRto.type);
      formik.setFieldValue(
        "registrationYear",
        regDate[2] + "-" + regDate[1] + "-" + regDate[0]
      );
      formik.setFieldValue(
        "expiryDate",
        convertDate(vechicleInfoFromRto?.vehicleInsuranceUpto, 2)
      );
    }
  }, [vechicleInfoFromRto]);
  // to bind make model varient
  useEffect(() => {
    if (vechicleInfoFromRto?.model) {
      const uniqueID = vechicleInfoFromRto?.mappings?.variantIds.find(
        (data) => data.score === 1
      );
      api
        .vahanDataCounch(
          uniqueID?.variantId,
          vechicleInfoFromRto?.vehicleCategory
        )
        .then((res) => {
          console.log("vahan data counch", res?.data?.data);
          if (res?.data?.data) {
            formik.setFieldValue("make", res?.data?.data?.make);
            formik.setFieldValue("model", res?.data?.data?.model);
            formik.setFieldValue("varient", res?.data?.data?.variant);
          }
        })
        .catch((err) => console.log("errr", err));
    }
  }, [vechicleInfoFromRto]);

  useEffect(() => {
    localStorage.setItem("policyTerms", formik.values.policyTerms);
  }, [formik.values.policyTerms]);
  useEffect(() => {
    setTimeout(() => {
      setrtoVarApi(true);
    }, 2000);
  }, []);

  // useEffect(() => {
  //   if (
  //     formik?.values?.personalAccidentCover &&
  //     formik?.values?.existingPersonalCoverFlag
  //   ) {
  //     formik.setFieldValue("existingPersonalCoverFlag", false);
  //   }
  // }, [formik?.values?.personalAccidentCover]);

  // useEffect(() => {
  //   if (
  //     formik?.values?.personalAccidentCover &&
  //     formik?.values?.existingPersonalCoverFlag
  //   ) {
  //     formik.setFieldValue("personalAccidentCover", false);
  //   }
  // }, [formik?.values?.existingPersonalCoverFlag]);

  useEffect(() => {
    // Check for validLicence effect on personalAccidentCover
    if (formik?.values?.validLicence) {
      if (formik?.values?.personalAccidentCover) {
        formik.setFieldValue("personalAccidentCover", false);
      }
    }
  }, [formik?.values?.validLicence]);

  useEffect(() => {
    // Check for personalAccidentCover effect on existingPersonalCoverFlag
    if (
      formik?.values?.personalAccidentCover &&
      formik?.values?.existingPersonalCoverFlag
    ) {
      formik.setFieldValue("existingPersonalCoverFlag", false);
    }
  }, [formik?.values?.personalAccidentCover]);

  useEffect(() => {
    // Check for existingPersonalCoverFlag effect on personalAccidentCover
    if (
      formik?.values?.personalAccidentCover &&
      formik?.values?.existingPersonalCoverFlag
    ) {
      formik.setFieldValue("personalAccidentCover", false);
    }
  }, [formik?.values?.existingPersonalCoverFlag]);

  useEffect(() => {
    // Check for validLicence and existingPersonalCoverFlag interaction
    if (
      formik?.values?.validLicence &&
      formik?.values?.existingPersonalCoverFlag
    ) {
      formik.setFieldValue("validLicence", false);
      formik.setFieldValue("personalAccidentCover", false);
    }
  }, [formik?.values?.validLicence, formik?.values?.existingPersonalCoverFlag]);

  useEffect(() => {
    if (formik?.values?.smartSaver && formik?.values?.smartSaverPlus) {
      formik.setFieldValue("smartSaverPlus", false);
    }
  }, [formik?.values?.smartSaver]);

  useEffect(() => {
    if (formik?.values?.smartSaver && formik?.values?.smartSaverPlus) {
      formik.setFieldValue("smartSaver", false);
    }
  }, [formik?.values?.smartSaverPlus]);

  const buyNow = (data) => {
    const userLogin = sessionStorage.getItem("userLoggin");
    formContext.setsingleQuotesData(data);
    console.log("data", data);
    if (userLogin) {
      history.push("/proposal");
      formContext.setquotesPageFormikData(formik?.values);
      formContext.setloaderStatus(false);
    } else {
      formContext.setPages("Quotes");
      formContext.setloginPopupStatus(true);
    }
  };
  const apiTocreateQuotes = (values, idv) => {
    localStorage.setItem("policyType", values.policyType);
    formContext.setloaderStatus(true);
    setloaderForQuotes(true);
    if (formContext.carbikeformikValues?.formtype === "bike") {
      api
        .createQoteApi(values, idv, formContext?.PolicyDates?.QUOTE_ID)
        .then((data) => {
          redirectMethod(values, data);
        })
        .catch((err) => {
          console.log("error", err);
          formContext.notifyError("An error occurred while fetching data");
          formContext.setquotesPageFormikData();
          formContext.setloaderStatus(false);
          setloaderForQuotes(false);
        })
        .finally(() => {
          // Set loader back to false
          setIsFormDirty(false);
        });
    } else {
      localStorage.setItem("policyType", values.policyType);
      api
        .createQoteApiCar(values, idv, formContext?.PolicyDates?.QUOTE_ID)
        .then((data) => {
          redirectMethod(values, data);
        })
        .catch((err) => {
          console.log("error", err);
          formContext.notifyError("An error occurred while fetching data");
          formContext.setquotesPageFormikData();
          formContext.setloaderStatus(false);
          setloaderForQuotes(false);
        })
        .finally(() => {
          // Set loader back to false
          setIsFormDirty(false);
        });
    }

    setTimeout(() => {
      setloaderForQuotes(false);
    }, 10000);
  };

  const handleIdvPopupSave = (carbikevalue, idvvalue) => {
    if (!idvvalue) {
      seterrorMessageForOdPopup("Please Select IDV");
      seterrorForOdPopup(true);
      return;
    }
    setidvPopupStatus(false);
    apiTocreateQuotes(carbikevalue, idvvalue);
  };
  const handleIdvCancle = () => {
    setidvPopupStatus(false);
    setidvSelectedValue();
    formik.setFieldValue("idv", "");
    seterrorForOdPopup(false);
  };

  const redirectMethod = (values, data) => {
    formContext.setgetquotesApiFlag(true);
    setquotesResultsArray([]);
    const JsonDataForCreateQuotes = JSON.parse(data.data);
    sessionStorage.setItem("quoteId", JsonDataForCreateQuotes?.QUOTE_ID);
    formContext.setPolicyDates(JsonDataForCreateQuotes);
    console.log("qotes create", JsonDataForCreateQuotes.data);
    formContext.setcreatequotesresult(JsonDataForCreateQuotes.data);
  };
  // ------------------------useEffect-----------------------------
  // to update share quotes url
  useEffect(() => {
    if (formContext?.PolicyDates?.QUOTE_ID) {
      const quoteID = CryptoJS.AES.encrypt(
        formContext?.PolicyDates?.data?.[0]?.QUOTE_ID,
        "dynamipass"
      ).toString();
      console.log(
        "formContext?.PolicyDates?.QUOTE_ID",
        formContext?.PolicyDates
      );

      setshareUrl(
        `${process.env.REACT_APP_FRONTEND_SERVER}/quotes?quoteID=${formContext?.PolicyDates?.QUOTE_ID}`
      );
    }
  }, [formContext?.PolicyDates]);

  // //BT
  // const checkForLead = async () => {
  //   await CrmService.getLeadInMotor({
  //     rtoNo: "MH01AA5309",
  //     module: "motor",
  //   }).then((res) => {
  //     if (res?.data[0]) {
  //       console.log("Lead is present ", res.data[0])
  //       setBusinesstrxn(formContext?.PolicyDates?.QUOTE_ID,);
  //     }
  //   });
  // };

  // const setBusinesstrxn = async (quote_id,) => {

  // };

  // to get share quote id from url , and pass that value to api
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    const quotesID = params.get("quoteID");
    if (quotesID) {
      // sessionStorage.setItem("quoteId", quotesID)

      var bytes = CryptoJS.AES.decrypt(quotesID, "dynamipass");
      var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      formContext.setloaderStatus(true);
      setloaderForQuotes(true);

      api
        .shareQuotes(quotesID)
        .then((data) => {
          const jsonData = JSON.parse(data.data);
          const businessLineId = jsonData?.data.businessLineId;
          var nestedDataVeh;
          var nestedData;
          var nestedDate;
          if (businessLineId === 2) {
            formContext.setroutingPath("/bike/1");
            setquotesResultsArray(jsonData?.data?.bikeQuoteResponse);
            nestedData = jsonData?.data?.bikeQuoteRequest?.quoteParam;
            localStorage.setItem("policyType", nestedData?.planType);
            nestedDataVeh = jsonData?.data?.bikeQuoteRequest?.vehicleInfo;
            nestedDate = jsonData?.data?.bikeQuoteRequest;
          } else {
            formContext.setroutingPath("/car/1");
            setquotesResultsArray(jsonData?.data?.carQuoteResponse);
            nestedData = jsonData?.data?.carQuoteRequest?.quoteParam;
            console.log("car data", nestedData);
            nestedDataVeh = jsonData?.data?.carQuoteRequest?.vehicleInfo;
            nestedDate = jsonData?.data?.carQuoteRequest;
          }
          console.log("share quotes jsonData", jsonData?.data);

          var policyTypeArray = nestedData?.planType?.split("-");
          var policyType;
          var policyTerm;

          if (policyTypeArray?.length === 3) {
            policyType = policyTypeArray[0] + "-" + policyTypeArray[1];
            policyTerm = policyTypeArray[2];
          } else {
            policyType = policyTypeArray[0];
            policyTerm = policyTypeArray[1];
            if (businessLineId === 3 && policyTypeArray?.length === 2) {
              policyType = policyTypeArray[0] + "-" + policyTypeArray[1];
            } else if (businessLineId === 3 && policyTypeArray?.length === 1) {
              policyType = policyTypeArray[0];
            }
          }

          const objForCarBike = {
            noClaimBonus: nestedData?.ncb,
            insuranceFor: nestedData?.policyType,
            addOnCover: nestedData?.riders,
            lastName: nestedData?.lastName,
            insuranceClaim: nestedDataVeh?.previousClaim,
            previousPolicy:
              nestedData?.policyType === "new"
                ? valuesForExpDate.todayConvertedValue
                : nestedDataVeh?.PreviousPolicyExpiryDate,
            firstName: nestedData?.firstName,
            email: nestedData?.email,
            phoneNumber: nestedData?.phoneNumber,
            currentStepIndex: "1",
            policyType: policyType,
            policyTerms: policyTerm,
            make: nestedDataVeh.make,
            model: nestedDataVeh.model,
            varient: nestedDataVeh.variant,
            registeredRto: nestedDataVeh?.registrationPlace
              ? nestedDataVeh?.registrationPlace
              : nestedDataVeh?.RTOCode,
            registrationYear: convertDate(nestedDataVeh?.dateOfRegistration, 2),
            formtype: jsonData?.data.businessLineId === 2 ? "bike" : "car",
            fuel: nestedDataVeh.fuel ? nestedDataVeh.fuel : "petrol",
          };

          const objForQuotesPage = {
            noClaimBonus: nestedData?.ncb,
            insuranceFor: nestedData?.policyType,
            addOnCover: nestedData?.riders,
            insuranceClaim: nestedDataVeh?.previousClaim,
            policyType: policyType,
            policyTerms: policyTerm,
            make: nestedDataVeh.make,
            model: nestedDataVeh.model,
            varient: nestedDataVeh.variant,
            registeredRto: nestedDataVeh?.registrationPlace
              ? nestedDataVeh?.registrationPlace
              : nestedDataVeh.RTOCode,
            registrationYear: convertDate(nestedDataVeh?.dateOfRegistration, 2),
            expiryDate: convertDate(nestedDataVeh?.PreviousPolicyExpiryDate, 2),
            fuel: nestedDataVeh.fuel ? nestedDataVeh.fuel : "petrol",
            idv: nestedDataVeh?.IDV,
            ownedBy:
              nestedData?.ownedBy === "Individual"
                ? "Individual"
                : "Organization",
            zeroDep: "",
            zeroDepPlan: "",
            personalCover: 100000,
            personalCoverFlag: "",
            personalAccidentCover: false,
            driverAccidentCover: "",
            driverAccidentCoverAmount: 10000,
            lpgCngKit: "",
            lpgCngKitAmount: "",
            accessories: "",
            electricalAccessories: "",
            electricalAccessoriesAmount: 10000,
            nonElectricalAccessories: "",
            nonElectricalAccessoriesAmount: 10000,
            roadSideAssistance: "",
            roadSideAssistancePlan: "RSA-199",
            ncbProtection: "",
            ncbPlan: "plan1",
            engineProtector: "",
            tyreProtection: "",
            tyreDetails: "",
            tyreProtectionAmount: "",
            keyProtection: "",
            keyProtectionAmount: "",
            consumablesCover: "",
            baggageCover: "",
            invoiceCover: "",
            transportHotelExpenses: "",
            transportHotelExpensesAmount: "",
            employeeAccidentCover: "",
            employeeAccidentCoversAmount: 1,
            voluntaryDeductableCover: "",
            voluntaryDeductableCoverAmount: 1000,
            emeCover: "",
            emeCoverNumber: "1",
            emeCoverAmount:
              formContext.carbikeformikValues?.formtype === "bike"
                ? "EME-99"
                : "EME-Standard",
            liabilityToPaidDriver: "",
            liabilityToPaidDriverAmount: 1,
            batteryProtectCover: "",
            smartSaverPlus: false,
            smartSaverPlusPlan: "smartSaverPlus",
            smartSaver: false,
            smartSaverPlan: "T+2*500",
            garageCash: "",
            garageCashPlan: "garageCashPlan",
            emiProtect: "",
            emiCount: "1",
            emiAmount: "",
            IsTyreProtect: "no",
            IsEngineProtect: "no",
            IsZeroDepriciation: "no",
            IsConsumable: "no",
            IsRTI: "no",
            validLicence: false,
            tpPolicyStartDate: nestedDataVeh?.TPPolicyStartDate
              ? nestedDataVeh.TPPolicyStartDate === "undefined-undefined-"
                ? null
                : nestedDataVeh?.TPPolicyStartDate
              : null,
            tpPolicyEndtDate: nestedDataVeh?.TPPolicyExpiryDate
              ? nestedDataVeh?.TPPolicyExpiryDate === "undefined-undefined-"
                ? null
                : nestedDataVeh?.TPPolicyExpiryDate
              : null,
            isvehNumberMissing: true,
            idvSelectedValue: nestedDataVeh?.IDV,
            formtype: jsonData?.data.businessLineId === 2 ? "bike" : "car",
            alternatePolicyExpDateFuture: "",
            existingPAPolicyNo: "",
            existingPASumInsured: 1500000,
            insurerName: "",
            validLicense: "",
          };

          const valuesForCarBike = {
            ...bikeFormikIntialData,
            ...objForCarBike,
          };
          const valuesForQuotes = {
            ...initialDataQuotesPage,
            ...objForQuotesPage,
          };

          formik.setValues(valuesForQuotes);
          formContext.setquotesPageFormikData(valuesForQuotes);
          formContext.setcarbikeformikValues(valuesForCarBike);

          // saving dates and date which are required while creating quotes and on propsal page respectively
          const policydates = {
            QUOTE_ID: jsonData?.data?.QUOTE_ID,
            policyDate: {
              policyStartDate:
                nestedDate?.systemPolicyStartDate?.sysPolicyStartDate,
            },
          };
          formContext.setPolicyDates(policydates);

          // setting up rider values
          nestedData?.riders?.forEach((element) => {
            // riders for bike
            if (businessLineId === 2) {
              switch (element.riderId) {
                case 10:
                  formik.setFieldValue("personalAccidentCover", true);
                  break;
                case 11:
                  formik.setFieldValue("zeroDep", true);
                  formik.setFieldValue("zeroDepPlan", element?.riderAmount);

                  break;
                case 28:
                  formik.setFieldValue("personalCoverFlag", true);
                  formik.setFieldValue("personalCover", element?.riderAmount);
                  break;

                case 12:
                  formik.setFieldValue("ncbProtection", true);
                  formik.setFieldValue("ncbPlan", element?.riderAmount);
                  break;
                case 14:
                  formik.setFieldValue("roadSideAssistance", true);
                  formik.setFieldValue(
                    "roadSideAssistancePlan",
                    element?.riderAmount
                  );
                  break;
                case 15:
                  formik.setFieldValue("invoiceCover", true);
                  break;
                case 13:
                  formik.setFieldValue("engineProtector", true);
                  break;
                case 25:
                  formik.setFieldValue("electricalAccessories", true);
                  formik.setFieldValue(
                    "electricalAccessoriesAmount",
                    element?.riderAmount
                  );
                  formik.setFieldValue("accessories", true);
                  break;
                case 30:
                  formik.setFieldValue("nonElectricalAccessories", true);
                  formik.setFieldValue(
                    "nonElectricalAccessoriesAmount",
                    element?.riderAmount
                  );
                  formik.setFieldValue("accessories", true);
                  break;
                case 50:
                  formik.setFieldValue("consumablesCover", true);
                  break;
                case 19:
                  formik.setFieldValue("employeeAccidentCover", true);
                  formik.setFieldValue(
                    "employeeAccidentCoversAmount",
                    element.riderAmount
                  );
                  break;
                case 23:
                  formik.setFieldValue("voluntaryDeductableCover", true);
                  formik.setFieldValue(
                    "voluntaryDeductableCoverAmount",
                    element?.riderAmount
                  );
                  break;
                case 24:
                  formik.setFieldValue("tyreSecure", true);
                  break;
                case 22:
                  formik.setFieldValue("batteryProtectCover", true);
                  break;
                case 16:
                  formik.setFieldValue("emeCover", true);
                  formik.setFieldValue("emeCoverNumber", element?.riderNumber);
                  formik.setFieldValue("emeCoverAmount", element?.riderAmount);
                  break;
                case 17:
                  formik.setFieldValue("keyProtection", true);
                  formik.setFieldValue(
                    "keyProtectionAmount",
                    element?.riderAmount
                  );
                  break;
                case 44:
                  formik.setFieldValue("liabilityToPaidDriver", true);
                  formik.setFieldValue(
                    "liabilityToPaidDriverAmount",
                    element?.riderAmount
                  );
                  break;

                case 51:
                  formik.setFieldValue("existingPersonalCoverFlag", true);
                  break;

                case 52:
                  formik.setFieldValue("validLicence", true);
                  break;

                default:
                  console.log("Default123");
                  break;
              }
            } else {
              // riders for cars
              switch (element.riderId) {
                case 11:
                  formik.setFieldValue("personalAccidentCover", true);
                  break;
                case 6:
                  formik.setFieldValue("zeroDep", true);
                  formik.setFieldValue("zeroDepPlan", element?.riderAmount);
                  formik.setFieldValue("IsZeroDepriciation", element?.riderPYP);
                  break;
                case 21:
                  formik.setFieldValue("personalCoverFlag", true);
                  formik.setFieldValue("personalCover", element?.riderAmount);
                  break;

                case 20:
                  formik.setFieldValue("driverAccidentCover", true);
                  formik.setFieldValue(
                    "driverAccidentCoverAmount",
                    element?.riderAmount
                  );
                  break;

                case 35:
                  formik.setFieldValue("lpgCngKit", true);
                  formik.setFieldValue("lpgCngKitAmount", element?.riderAmount);
                  break;

                case 25:
                  formik.setFieldValue("electricalAccessories", true);
                  formik.setFieldValue(
                    "electricalAccessoriesAmount",
                    element?.riderAmount
                  );
                  formik.setFieldValue("accessories", true);
                  break;

                case 30:
                  formik.setFieldValue("nonElectricalAccessories", true);
                  formik.setFieldValue(
                    "nonElectricalAccessoriesAmount",
                    element?.riderAmount
                  );
                  formik.setFieldValue("accessories", true);
                  break;

                case 9:
                  formik.setFieldValue("roadSideAssistance", true);
                  formik.setFieldValue(
                    "roadSideAssistancePlan",
                    element?.riderAmount
                  );
                  break;

                case 7:
                  formik.setFieldValue("ncbProtection", true);
                  formik.setFieldValue("ncbPlan", element?.riderAmount);
                  break;

                case 8:
                  formik.setFieldValue("engineProtector", true);
                  formik.setFieldValue("IsEngineProtect", element?.riderPYP);
                  break;

                case 37:
                  formik.setFieldValue("tyreProtection", true);
                  formik.setFieldValue(
                    "tyreProtectionAmount",
                    element?.riderAmount
                  );
                  formik.setFieldValue("IsTyreProtect", element?.riderPYP);
                  break;
                case 23:
                  formik.setFieldValue("keyProtection", true);
                  formik.setFieldValue(
                    "keyProtectionAmount",
                    element?.riderAmount
                  );
                  break;

                case 10:
                  formik.setFieldValue("invoiceCover", true);
                  formik.setFieldValue("IsRTI", element?.riderPYP);
                  break;

                case 41:
                  formik.setFieldValue("transportHotelExpenses", true);
                  formik.setFieldValue(
                    "transportHotelExpensesAmount",
                    element?.riderAmount
                  );
                  break;
                case 24:
                  formik.setFieldValue("consumablesCover", true);
                  formik.setFieldValue("IsConsumable", element?.riderPYP);
                  break;
                case 40:
                  formik.setFieldValue("baggageCover", true);
                  break;
                case 39:
                  formik.setFieldValue("employeeAccidentCover", true);
                  formik.setFieldValue(
                    "employeeAccidentCoversAmount",
                    element.riderAmount
                  );
                  break;
                case 45:
                  formik.setFieldValue("voluntaryDeductableCover", true);
                  formik.setFieldValue(
                    "voluntaryDeductableCoverAmount",
                    element?.riderAmount
                  );
                  break;
                case 42:
                  formik.setFieldValue("batteryProtectCover", true);
                  break;
                case 43:
                  formik.setFieldValue("emeCover", true);
                  formik.setFieldValue("emeCoverNumber", element?.riderNumber);
                  formik.setFieldValue("emeCoverAmount", element?.riderAmount);
                  break;
                case 44:
                  formik.setFieldValue("liabilityToPaidDriver", true);
                  formik.setFieldValue(
                    "liabilityToPaidDriverAmount",
                    element?.riderAmount
                  );
                  break;
                case 47:
                  formik.setFieldValue("smartSaverPlus", true);
                  formik.setFieldValue(
                    "smartSaverPlusPlan",
                    element?.riderPlan
                  );
                  break;
                case 46:
                  formik.setFieldValue("smartSaver", true);
                  formik.setFieldValue("smartSaverPlan", element?.riderPlan);
                  break;
                case 51:
                  formik.setFieldValue("existingPersonalCoverFlag", true);
                  break;
                case 54:
                  formik.setFieldValue("validLicence", true);
                  break;
                case 53:
                  formik.setFieldValue("garageCash", true);
                  formik.setFieldValue("garageCashPlan", element?.riderPlan);

                  break;
                case 52:
                  formik.setFieldValue("emiProtect", true);
                  formik.setFieldValue("emiCount", element?.riderNumber);
                  formik.setFieldValue("emiAmount", element?.riderAmount);
                  break;
                default:
                  break;
              }
            }
          });

          // idv selected value
          setidvSelectedValue(nestedDataVeh?.IDV);
          // to fix personalAccCover checkbox issue(by default selected)
          const singleRider = nestedData?.riders.find(
            (data) => data.riderName === "Personal Accident cover"
          );
          const singleRiderForLpg = nestedData?.riders.find(
            (data) => data.riderName === "LPG-CNG Kit"
          );
          setTimeout(() => {
            if (!singleRider) {
              formik.setFieldValue("personalAccidentCover", false);
            }
            if (!singleRiderForLpg) {
              formik.setFieldValue("lpgCngKit", false);
            }
            settimeoutMethod();
          }, 1000);

          setshareQuotesData(valuesForQuotes);
        })
        .catch((err) => {
          console.log("share quotes err", err);
        })
        .finally((fin) => {
          formContext.setloaderStatus(false);
          setloaderForQuotes(false);
        });
    }
  }, []);

  useEffect(() => {
    if (formik?.values?.zeroDep) {
      if (formContext.carbikeformikValues?.formtype === "bike") {
        formik.setFieldValue("zeroDepPlan", "silver");
      } else {
        formik.setFieldValue("zeroDepPlan", "zd");
      }
    }
  }, [formik?.values?.zeroDep]);

  // to redirect user after login success
  useEffect(() => {
    if (
      formContext?.loginPopupStatus === false &&
      sessionStorage.getItem("userLoggin") &&
      quotesResultsArray[0]?.carrierVariantDisplayName
    ) {
      history.push("/proposal");
      formContext.setquotesPageFormikData(formik?.values);
      formContext.setloaderStatus(false);
    }
  }, [formContext?.loginPopupStatus]);
  useEffect(() => {
    const isPageRefreshed = sessionStorage.getItem("isPageRefreshed");

    if (isPageRefreshed) {
      // Redirect to the home page
      let params = new URLSearchParams(window.location.search);
      const quotesID = params.get("quoteID");
      const shareId = sessionStorage.getItem("quoteId")
        ? sessionStorage.getItem("quoteId")
        : quotesID;
      // Redirect to the home page
      history.replace(`/paymentFailed/${shareId}`);
    }

    // Set the flag indicating the page has been refreshed
    sessionStorage.setItem("isPageRefreshed", "true");

    // Clean up the sessionStorage when the component is unmounted
    return () => {
      sessionStorage.removeItem("isPageRefreshed");
    };
  }, [history]);

  // useEffect to track changes in formik values and set a flag if the form is dirty

  useEffect(() => {
    if (formik.dirty) {
      setIsFormDirty(true);
    }
  }, [formik?.values, formik?.dirty]);

  useEffect(() => {
    settimeoutMethod();
    formContext.setPages("Quotes");
    sessionStorage.removeItem("nextPage");
  }, []);
  useEffect(() => {
    // to hide policy type if new one is selected
    if (formContext.carbikeformikValues?.insuranceFor === "new") {
      formik.setFieldValue("policyTerms", 3);
      setarrayForPolicyType(
        policyType.filter((task) => task.label === "Comprehensive")
      );
    } else {
      setarrayForPolicyType(policyType);
    }
  }, [formContext.carbikeformikValues?.insuranceFor]);

  //useEffect is used to set the RegisteredRto values
  useEffect(() => {
    formContext?.setselectedRto("");
  }, []);

  useEffect(() => {
    if (id) {
      formContext.setgetquotesApiFlag(false);
    }

    if (id && formContext.quotesPageFormikData) {
      formik.setValues({
        ...formik.initialValues,
        ...formContext.quotesPageFormikData,
      });
      setquotesResultsArray(formContext.quotesList);
      setTimeout(() => {
        setidvSelectedValue(formContext.quotesPageFormikData?.idvSelectedValue);
      }, 1000);
    }
  }, []);
  useEffect(() => {
    if (formContext.selectedRto?.length > 0) {
      console.log("formContext.selectedRto", formContext.selectedRto);
      formik.setFieldValue("registeredRto", formContext.selectedRto);
    }
  }, [formContext.selectedRto]);

  useEffect(() => {
    setIsFormDirty(true);
  }, [formik.values.personalAccidentCover]);

  //useEffect is used to fetch and display a single record based on certain conditions.
  useEffect(() => {
    setcount(0);
    loadercount = 0;
    // Finding a single record from the API response based on the condition that qname includes "RelianceGenBikeResQ"
    const singlerecord = formContext.createquotesresult;
    if (singlerecord?.length > 0 && formContext.getquotesApiFlag) {
      setloaderForQuotes(true);
      for (let i = 0; i < singlerecord?.length; i++) {
        getQoteResult(singlerecord[i], i, singlerecord?.length);
      }
    }
  }, [formContext.createquotesresult]);

  useEffect(() => {
    if (formik?.values?.policyType === "OD") {
      if (!formik?.values?.tpPolicyStartDate) {
        settpPolicyStatus(true);
      }
    } else if (
      formik?.values?.policyType === "TD" ||
      formik?.values?.policyType === "OD-TP"
    ) {
      formik.setFieldValue("tpPolicyStartDate", "");
      formik.setFieldValue("tpPolicyEndtDate", "");
    }
  }, [formik?.values?.policyType]);

  // -------------------------methods and jsx----------------------
  const settimeoutMethod = () => {
    setTimeout(() => {
      setIsFormDirty(false);
    }, 500);
  };
  const backButtonPressed = () => {
    history.push(formContext.routingPath);
  };
  const popupReturnMethod = (child, heading) => {
    setModalShow(true);
    setheadingForPopup(heading);
    setchildForPopup(child);
  };

  // Function to retrieve a quote result using API
  const getQoteResult = (singlerecord, currentIndex, actuallength) => {
    formContext.setloaderStatus(true);

    api
      .getQoteApi(
        singlerecord?.qname,
        singlerecord?.messageId,
        singlerecord?.QUOTE_ID,
        formContext.carbikeformikValues?.formtype
      )
      .then((data) => {
        apiResopnse(data, actuallength, currentIndex);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setcount((prev) => prev + 1);
        loadercount = loadercount + 1;
        setTimeout(() => {
          if (actuallength === loadercount) {
            formContext.setloaderStatus(false);
            setloaderForQuotes(false);
          }
        }, 1000);
      });
  };

  const apiResopnse = (data, actuallength, currentIndex) => {
    const jsonData = JSON.parse(data?.data)?.data;
    if (jsonData?.quotes) {
      setquotesResultsArray((prevdata) => {
        return [...prevdata, ...jsonData?.quotes];
      });
    }
  };

  useEffect(() => {
    console.log("addOnArray", addOnArray);
    formik.setFieldValue("addOnCover", addOnArray);
  }, [addOnArray]);

  useEffect(() => {
    console.log("quotesResultsArray", quotesResultsArray);
    if (quotesResultsArray?.length > 0) {
      setTimeout(() => {
        let tempArray = {
          minValue: 1000000,
          maxValue: 0,
          insuredDeclareValue: 0,
          netPremium: 100000,
          betstValue: 0,
        };
        const filterdData = quotesResultsArray?.filter(
          (filterData) => Number(filterData?.netPremium) !== 0
        );

        for (let i = 0; i < filterdData?.length; i++) {
          const minIdvValue = Number(filterdData[i]?.minIdvValue);
          const maxIdvValue = Number(filterdData[i]?.maxIdvValue);
          const netPremium = Number(filterdData[i]?.netPremium);
          const insuredDeclareValue = Number(
            filterdData[i]?.insuredDeclareValue
          );

          const minValueidv = Number(tempArray?.minValue);
          const maxValueidv = Number(tempArray?.maxValue);
          const netPremiumIdv = Number(tempArray?.netPremium);
          const betstValue = Number(tempArray?.betstValue);

          if (minIdvValue <= minValueidv && minIdvValue !== 0) {
            tempArray.minValue = minIdvValue;
          }
          if (maxIdvValue >= maxValueidv && maxIdvValue !== 0) {
            tempArray.maxValue = maxIdvValue;
          }

          if (netPremium <= netPremiumIdv) {
            tempArray.netPremium = netPremium;
          }
          if (insuredDeclareValue >= betstValue && insuredDeclareValue > 0) {
            tempArray.betstValue = insuredDeclareValue;
          }
        }

        setidvValue(tempArray);
        tempArray = {
          minValue: 1000000,
          maxValue: 0,
          insuredDeclareValue: 0,
          netPremium: 100000,
          betstValue: 0,
        };
      }, 1000);
      formContext.setquotesList(quotesResultsArray);
    } else {
      setidvValue({
        minValue: 0,
        maxValue: 0,
        insuredDeclareValue: 0,
        netPremium: 100000,
        betstValue: 0,
      });
    }
  }, [quotesResultsArray]);

  const openQuotePopup = (data) => {
    console.log("data", data);
    var premium = 0;
    var odpremium = 0;
    // for new india we get array for net premium and for idv so we need this logic
    if (typeof data?.netPremium === "object") {
      data?.netPremium?.forEach((foreachdata, index) => {
        premium = premium + foreachdata;
      });
    } else {
      premium = data?.netPremium;
    }
    if (typeof data?.odpremium === "object") {
      data?.odpremium?.forEach((foreachdata, index) => {
        odpremium = odpremium + foreachdata;
      });
    } else {
      odpremium = data?.odpremium;
    }
    setnetPremiumForBrekupvalue({ premium: premium, odpremium: odpremium });
    formContext.setsingleQuotesData(data);
    setQuotePremiumPopup(!quotePremiumPopup);
  };

  useEffect(() => {
    riderName = "";
    riderAmount = 0;
    formContext.singleQuotesData?.ridersList?.map((mapdata, index) => {
      riderName += mapdata?.riderName + ",";
      if (mapdata?.riderValue) {
        riderAmount = Number(riderAmount) + Number(mapdata?.riderValue);
      }
      riderAmount = Number(riderAmount)?.toFixed(0);
    });

    setriderAmoutForFuture(riderAmount);
    setriderNameForFuture(riderName);
  }, [formContext.singleQuotesData]);

  const QpPopupClose = () => {
    setQuotePremiumPopup(false);
  };
  useEffect(() => {
    let arrayForAddon = [];

    if (formContext.carbikeformikValues?.formtype === "bike") {
      if (formik?.values?.zeroDep) {
        arrayForAddon.push({
          riderId: 11,
          riderName: "Zero Depreciation cover",
          riderAmount: formik.values?.zeroDepPlan,
        });
      }
      if (formik?.values?.personalCoverFlag) {
        arrayForAddon.push({
          riderId: 28,
          riderName: "Passenger Accident Cover",
          riderAmount: formik.values?.personalCover,
        });
      }
      if (formik?.values?.personalAccidentCover) {
        arrayForAddon.push({
          riderName: "Personal Accident cover",
          riderId: 10,
          riderAmount: formik?.values?.driverAccidentCoverAmount,
        });
      }
      if (formik?.values?.driverAccidentCover) {
        arrayForAddon.push({
          riderName: "Driver Accident Cover",
          riderId: 20,
          riderAmount: formik.values?.driverAccidentCoverAmount,
        });
      }
      if (formik?.values?.lpgCngKit) {
        arrayForAddon.push({
          riderName: "LPG/CNG Kit",
          riderId: 35,
          riderAmount: formik.values?.lpgCngKitAmount,
        });
      }
      if (formik?.values?.electricalAccessories) {
        arrayForAddon.push({
          riderName: "Electrical Accessories cover",
          riderId: 25,
          riderAmount: formik.values?.electricalAccessoriesAmount,
        });
      }
      if (formik?.values?.nonElectricalAccessories) {
        arrayForAddon.push({
          riderName: "Non-Electrical Accessories cover",
          riderId: 30,
          riderAmount: formik.values?.nonElectricalAccessoriesAmount,
        });
      }
      if (formik?.values?.roadSideAssistance) {
        arrayForAddon.push({
          riderName: "24X7 Road Side Assistance",
          riderId: 14,
          riderAmount: formik.values?.roadSideAssistancePlan,
        });
      }
      if (formik?.values?.ncbProtection) {
        arrayForAddon.push({
          riderName: "NCB Protection",
          riderId: 12,
          riderAmount: formik?.values?.ncbPlan,
        });
      }
      if (formik?.values?.engineProtector) {
        arrayForAddon.push({
          riderName: "Engine Protector",
          riderId: 13,
          // riderPYP: formik.values?.IsEngineProtect
        });
      }
      if (formik?.values?.tyreProtection) {
        arrayForAddon.push({
          riderName: "Tyre Secure",
          riderId: 24,
          riderAmount: formik.values?.tyreProtectionAmount,
          riderPYP: formik.values?.IsTyreProtect,
        });
      }
      if (formik?.values?.keyProtection) {
        arrayForAddon.push({
          riderName: "Key Replacement cover",
          riderId: 17,
          // riderAmount: formik?.values?.keyProtectionAmount,
        });
      }
      if (formik?.values?.consumablesCover) {
        arrayForAddon.push({
          riderName: "Consumables cover",
          riderId: 50,
        });
      }
      if (formik?.values?.invoiceCover) {
        arrayForAddon.push({
          riderName: "Invoice Cover",
          riderId: 15,
        });
      }
      if (formik?.values?.employeeAccidentCover) {
        arrayForAddon.push({
          riderName: "Employee Accident Cover",
          riderId: 19,
          riderAmount: formik.values?.employeeAccidentCoversAmount,
        });
      }
      if (formik?.values?.voluntaryDeductableCover) {
        arrayForAddon.push({
          riderName: "Voluntary Deductable Cover",
          riderId: 23,
          riderAmount: formik.values?.voluntaryDeductableCoverAmount,
        });
      }
      if (formik?.values?.batteryProtectCover) {
        arrayForAddon.push({
          riderName: "Battery Protect Cover",
          riderId: 22,
        });
      }
      if (formik?.values?.emeCover) {
        arrayForAddon.push({
          riderName: "EME Cover",
          riderId: 16,
          riderAmount: formik.values.emeCoverAmount,
          riderNumber: formik.values.emeCoverNumber,
        });
      }
      if (formik?.values?.liabilityToPaidDriver) {
        arrayForAddon.push({
          riderName: "Liability to Paid Driver",
          riderId: 44,
          riderAmount: formik.values?.liabilityToPaidDriverAmount,
        });
      }
      if (formik?.values?.existingPersonalCoverFlag) {
        arrayForAddon.push({
          riderName: "Existing Personal Cover Flag",
          riderId: 51,
        });
      }
      if (formik?.values?.validLicence) {
        arrayForAddon.push({
          riderName: "Valid Licence",
          riderId: 52,
        });
      }
    }

    // Check if Passenger Accident Cover is selected
    else {
      if (formik?.values?.emiProtect) {
        arrayForAddon.push({
          riderName: "EMI Protect Cover",
          riderId: 52,
          riderAmount: formik?.values?.emiAmount,
          riderNumber: formik?.values?.emiCount,
        });
      }
      if (formik?.values?.smartSaver) {
        arrayForAddon.push({
          riderName: "Smart Saver cover",
          riderId: 46,
          riderPlan: formik.values?.smartSaverPlan,
        });
      }
      if (formik?.values?.smartSaverPlus) {
        arrayForAddon.push({
          riderName: "Smart Saver Plus cover",
          riderId: 47,
          riderPlan: formik.values?.smartSaverPlusPlan,
        });
      }
      if (formik?.values?.existingPersonalCoverFlag) {
        arrayForAddon.push({
          riderName: "Existing Personal Cover Flag",
          riderId: 51,
        });
      }
      if (formik?.values?.personalCoverFlag) {
        arrayForAddon.push({
          riderId: 21,
          riderName: "Passenger Accident Cover",
          riderAmount: formik.values?.personalCover,
        });
      }
      // Check if Zero Depreciation cover is selected
      if (formik?.values?.zeroDep) {
        arrayForAddon.push({
          riderId: 6,
          riderName: "Zero Depreciation cover",
          riderAmount: formik?.values?.zeroDepPlan,
          riderPYP: formik.values?.IsZeroDepriciation,
        });
      }
      // Check if Personal Accident Cover is selected
      if (formik?.values?.personalAccidentCover) {
        arrayForAddon.push({
          riderId: 11,
          riderName: "Personal Accident Cover",
          riderAmount: formik?.values?.driverAccidentCoverAmount,
        });
      }
      // Check if Driver Accident cover is selected
      if (formik?.values?.driverAccidentCover) {
        arrayForAddon.push({
          riderId: 20,
          riderName: "Driver Accident Cover",
          riderAmount: formik.values?.driverAccidentCoverAmount,
        });
      }
      // Check if LPG-CNG Kit cover is selected
      if (formik?.values?.lpgCngKit) {
        arrayForAddon.push({
          riderId: 35,
          riderName: "LPG-CNG Kit",
          riderAmount: formik.values?.lpgCngKitAmount,
        });
      }
      if (formik?.values?.accessories) {
        // Check if Electrical Accessories cover is selected
        if (formik?.values?.electricalAccessories) {
          arrayForAddon.push({
            riderId: 25,
            riderName: "Electrical Accessories",
            riderAmount: formik.values?.electricalAccessoriesAmount,
          });
        }
        // Check if Non-Electrical Accessories cover is selected
        if (formik?.values?.nonElectricalAccessories) {
          arrayForAddon.push({
            riderId: 30,
            riderName: "Non-Electrical Accessories",
            riderAmount: formik.values?.nonElectricalAccessoriesAmount,
          });
        }
      }
      // Check if Road Side Assistance cover is selected
      if (formik?.values?.roadSideAssistance) {
        arrayForAddon.push({
          riderId: 9,
          riderName: "24X7 Road Side Assistance",
          riderAmount: formik.values?.roadSideAssistancePlan,
        });
      }
      // Check if NCB Protection cover is selected
      if (formik?.values?.ncbProtection) {
        arrayForAddon.push({
          riderId: 7,
          riderName: "NCB Protection",
          riderAmount: formik?.values?.ncbPlan,
        });
      }

      // Check if Engine Protector cover is selected
      if (formik?.values?.engineProtector) {
        arrayForAddon.push({
          riderId: 8,
          riderName: "Engine Protector",
          riderPYP: formik.values?.IsEngineProtect,
        });
      }

      // Check if Tyre Protection is selected
      if (formik?.values?.tyreProtection) {
        arrayForAddon.push({
          riderId: 37,
          riderName: "Tyre Secure",
          TyreDetails: formik.values?.tyreProtectionAmount,
          riderPYP: formik.values?.IsTyreProtect,
        });
      }
      // Check if Key Protection is selected
      if (formik?.values?.keyProtection) {
        arrayForAddon.push({
          riderId: 23,
          riderName: "Key Replacement cover",
          riderAmount: formik?.values?.keyProtectionAmount,
        });
      }
      // Check if invoice cover is selected
      if (formik?.values?.invoiceCover) {
        arrayForAddon.push({
          riderId: 10,
          riderName: "Invoice Cover",
          riderPYP: formik.values?.IsRTI,
        });
      }

      // Check if Transport Hotel Expenses cover is selected
      if (formik?.values?.transportHotelExpenses) {
        arrayForAddon.push({
          riderId: 41,

          riderName: "Emergency Transport and Hotel Expenses",
          riderAmount: formik.values?.transportHotelExpensesAmount,
        });
      }
      // Check if Consumable cover is selected
      if (formik?.values?.consumablesCover) {
        arrayForAddon.push({
          riderId: 24,
          riderName: "Consumables cover",
          riderPYP: formik.values?.IsConsumable,
        });
      }

      // Check if Baggage cover is selected
      if (formik?.values?.baggageCover) {
        arrayForAddon.push({
          riderId: 40,

          riderName: "Baggage cover",
        });
      }

      if (formik?.values?.employeeAccidentCover) {
        arrayForAddon.push({
          riderId: 39,
          riderName: "Employee Accident Cover",
          riderAmount: formik.values?.employeeAccidentCoversAmount,
        });
      }
      if (formik?.values?.batteryProtectCover) {
        arrayForAddon.push({
          riderId: 42,
          riderName: "Battery Protect Cover",
        });
      }
      if (formik?.values?.emeCover) {
        arrayForAddon.push({
          riderId: 43,
          riderName: "EME Cover",
          riderAmount: formik.values.emeCoverAmount,
          riderNumber: formik.values.emeCoverNumber,
        });
      }
      if (formik?.values?.liabilityToPaidDriver) {
        arrayForAddon.push({
          riderId: 44,
          riderName: "Liability to Paid Driver",
          riderAmount: 1,
        });
      }
      if (formik?.values?.voluntaryDeductableCover) {
        arrayForAddon.push({
          riderId: 41,
          riderName: "Voluntary Deductable Cover",
          riderAmount: formik?.values?.voluntaryDeductableCoverAmount,
        });
      }
      if (formik?.values?.validLicence) {
        arrayForAddon.push({
          riderName: "Valid Licence",
          riderId: 54,
        });
      }
      if (formik?.values?.garageCash) {
        arrayForAddon.push({
          riderName: "Garage Cash Cover",
          riderPlan: formik?.values?.garageCashPlan,
          riderId: 53,
        });
      }
    }
    setaddOnArray(arrayForAddon);
  }, [
    formik?.values?.personalCoverFlag,
    formik?.values?.transportHotelExpensesAmount,
    formik?.values?.tyreProtectionAmount,
    formik?.values?.nonElectricalAccessoriesAmount,
    formik?.values?.lpgCngKitAmount,
    formik.values?.electricalAccessoriesAmount,
    formik?.values?.driverAccidentCoverAmount,
    formik?.values?.personalCover,
    formik?.values?.zeroDep,
    formik?.values?.zeroDepPlan,
    formik?.values?.personalAccidentCover,
    formik?.values?.consumablesCover,
    formik?.values?.baggageCover,
    formik?.values?.invoiceCover,
    formik?.values?.roadSideAssistance,
    formik?.values?.roadSideAssistancePlan,
    formik?.values?.tyreProtection,
    formik?.values?.keyProtection,
    formik?.values?.keyProtectionAmount,
    formik?.values?.engineProtector,
    formik?.values?.ncbProtection,
    formik?.values?.ncbPlan,
    formik?.values?.driverAccidentCover,
    formik?.values?.lpgCngKit,
    formik?.values?.accessories,
    formik?.values?.transportHotelExpenses,
    formik?.values?.nonElectricalAccessories,
    formik?.values?.electricalAccessories,
    formik?.values?.voluntaryDeductableCover,
    formik?.values?.voluntaryDeductableCoverAmount,
    formik?.values?.employeeAccidentCover,
    formik?.values?.employeeAccidentCoversAmount,
    formik?.values?.emeCover,
    formik?.values?.emeCoverNumber,
    formik?.values?.emeCoverAmount,
    formik?.values?.liabilityToPaidDriver,
    formik?.values?.liabilityToPaidDriverAmount,
    formik?.values?.smartSaver,
    formik?.values?.smartSaverPlan,
    formik?.values?.smartSaverPlus,
    formik?.values?.smartSaverPlusPlan,
    formik?.values?.garageCash,
    formik?.values?.garageCashPlan,
    formik?.values?.batteryProtectCover,
    formik?.values?.emiProtect,
    formik?.values?.emiCount,
    formik?.values?.emiAmount,
    formik?.values?.IsTyreProtect,
    formik?.values?.IsEngineProtect,
    formik?.values?.IsZeroDepriciation,
    formik?.values?.IsConsumable,
    formik?.values?.IsRTI,
    formik?.values?.existingPersonalCoverFlag,
    formik?.values?.validLicence,
  ]);

  useEffect(() => {
    if (formik.values.policyType === "TP") {
      formik.setFieldValue("zeroDep", false);
      formik.setFieldValue("roadSideAssistance", false);
      formik.setFieldValue("consumablesCover", false);
      formik.setFieldValue("engineProtector", false);
      formik.setFieldValue("ncbProtection", false);
      formik.setFieldValue("tyreProtection", false);
      formik.setFieldValue("keyProtection", false);
      formik.setFieldValue("baggageCover", false);
      formik.setFieldValue("invoiceCover", false);
      formik.setFieldValue("transportHotelExpenses", false);
      formik.setFieldValue("electricalAccessories", false);
      formik.setFieldValue("nonElectricalAccessories", false);
      formik.setFieldValue("accessories", false);
      formik.setFieldValue("personalAccidentCover", true);
    }
    if (formik.values.policyType === "OD") {
      formik.setFieldValue("personalCoverFlag", false);
      formik.setFieldValue("driverAccidentCover", false);
      formik.setFieldValue("personalAccidentCover", false);
      // if (formik.values.type === "bike") {
      //   formik.setFieldValue("electricalAccessories", false);
      //   formik.setFieldValue("nonElectricalAccessories", false);
      //   formik.setFieldValue("accessories", false);
      // }
    }
    if (formik.values.policyType === "OD-TP") {
      formik.setFieldValue("personalAccidentCover", true);
    }
  }, [formik.values.policyType]);

  // lpg/cng flag bydefault true logic
  useEffect(() => {
    const value1 = "petrol+lpg";
    const value2 = "petrol+cng";
    if (formik.values.fuel === value1 || formik.values.fuel === value2) {
      formik.setFieldValue("lpgCngKit", true);
    } else {
      formik.setFieldValue("lpgCngKit", false);
    }
  }, [formik.values.fuel]);

  // Resets the form using Formik's resetForm function and sets form dirty state to false.
  const handleCancel = () => {
    console.log("setshareQuotesData", shareQuotesData);
    const personalAccCoverBackup = formik.values.personalAccidentCover;
    formik.resetForm();
    formik.setFieldValue("personalAccidentCover", personalAccCoverBackup);
    if (shareQuotesData) {
      formik.setValues(shareQuotesData);
    }
    settimeoutMethod();
  };

  useEffect(() => {
    if (formik?.values?.idv) {
      setidvSelectedValue(idvValue?.[formik?.values?.idv]);
    }
  }, [formik?.values?.idv]);
  // to set idv selected value when comes back from proposal
  useEffect(() => {
    formik.setFieldValue("idvSelectedValue", Number(idvSelectedValue));
  }, [idvSelectedValue]);

  // CRM code
  useEffect(() => {
    if (!sessionStorage.getItem("isAdmin")) {
      CrmMethod();
      setTimeout(() => {
        crmStateUpdate();
      }, 3000);
    }
  }, []);

  const crmStateUpdate = async () => {
    // console.log("11111111111111111", `${process.env.REACT_APP_FRONTEND_SERVER}/quotes?quoteID=${formContext?.PolicyDates?.QUOTE_ID}`)
    objForUpdateStage.customer_name =
      formContext.carbikeformikValues?.firstName +
      " " +
      formContext.carbikeformikValues?.lastName;
    objForUpdateStage.mobile_number =
      formContext.carbikeformikValues?.phoneNumber;
    objForUpdateStage.quote_link = `${process.env.REACT_APP_FRONTEND_SERVER}/quotes?quoteID=${formContext?.PolicyDates?.QUOTE_ID}`;
    objForUpdateStage.RTO_no = formContext.carbikeformikValues?.registeredRto;
    const crmToken = await formContext.setCrmTokenMethod();
    api
      .crmStageUpdateApi(crmToken?.data?.token, objForUpdateStage)
      .then((res) => {})
      .catch((err) => {});
  };

  const CrmMethod = () => {
    // console.log("00000000", formContext.crmLoggedUser)

    setTimeout(() => {
      if (!formContext.crmLoggedUser) {
        //  console.log("1111111111111111111")
        api
          .crmLogin()
          .then((res) => {
            formContext.setcrmToken(res.data.token);
            setLoggeduser(res.data.token);
          })
          .catch((err) => {
            formContext.setcrmToken("");
          });
      } else {
        // call crm api
        //  console.log("22222222")
        createCrmLead();
      }
    }, 2000);
  };

  const setLoggeduser = (token) => {
    api
      .crmGetLoginUser(token)
      .then((res) => {
        formContext.setcrmLoggedUser(res?.data?.output?.motor?.[0]);
        console.log("demo1111111", res?.data?.output?.motor?.[0]);
        createCrmLead();
      })
      .catch((err) => {
        formContext.setcrmLoggedUser();
      });
  };
  const createCrmLead = async () => {
    objForCreateLead.customer_name =
      formContext.carbikeformikValues?.firstName +
      " " +
      formContext.carbikeformikValues?.lastName;
    objForCreateLead.mobile_number =
      formContext.carbikeformikValues?.phoneNumber;
    objForCreateLead.email_id = formContext.carbikeformikValues?.email;
    objForCreateLead.RTO_no = formContext.carbikeformikValues?.registeredRto;

    objForCreateLead.make = formContext.carbikeformikValues?.make;
    objForCreateLead.model = formContext.carbikeformikValues?.model;
    objForCreateLead.varient = formContext.carbikeformikValues?.varient;
    objForCreateLead.registration_date = convertDate(
      formContext.carbikeformikValues?.registrationYear,
      1
    );
    objForCreateLead.caller_name = formContext?.crmLoggedUser?.username;

    const crmToken = await formContext.setCrmTokenMethod();
    api
      .crmCreateLead(crmToken?.data?.token, objForCreateLead)
      .then((res) => {})
      .catch((err) => {});
  };

  // Marks the form as not dirty when the save action is triggered.
  const handleSave = () => {
    //toast.error("Error : ")
    setshareQuotesData(formik?.values);
    formContext.setquotesPageFormikData(formik?.values);
    formik.handleSubmit();
  };

  // to give ratings
  const giveRating = (rating) => {
    return (
      <div className="">
        {rating >= 1 ? (
          <FullStar />
        ) : rating >= 0.5 ? (
          <HalfStar />
        ) : (
          <ZeroStar />
        )}
        {rating >= 2 ? (
          <FullStar />
        ) : rating >= 1.5 ? (
          <HalfStar />
        ) : (
          <ZeroStar />
        )}
        {rating >= 3 ? (
          <FullStar />
        ) : rating >= 2.5 ? (
          <HalfStar />
        ) : (
          <ZeroStar />
        )}
        {rating >= 4 ? (
          <FullStar />
        ) : rating >= 3.5 ? (
          <HalfStar />
        ) : (
          <ZeroStar />
        )}
        {rating >= 5 ? (
          <FullStar />
        ) : rating >= 4.5 ? (
          <HalfStar />
        ) : (
          <ZeroStar />
        )}
      </div>
    );
  };
  const handleSpanClick = () => {
    formContext.setisvehNumberMissing(!formContext.isvehNumberMissing);
    formik.setFieldValue("isvehNumberMissing", !formContext.isvehNumberMissing);
    setIsTextVisible((predata) => !isTextVisible);
    if (isTextVisible) {
      registeredRtoInputRef.current.focus();
    } else {
      formContext.setModalShow(true);
    }
  };
  const odPopupClose = (type) => {
    if (
      !formik?.values?.tpPolicyEndtDate ||
      !formik?.values?.tpPolicyStartDate
    ) {
    }
    if (type === 1) {
      formik.setFieldValue("policyType", "");
      formik.setFieldValue("tpPolicyEndtDate", "");
      formik.setFieldValue("tpPolicyStartDate", "");
      seterrorForOdPopup(false);
    } else {
      const {
        properDates,
        dateValid,
        isFutureDate,
        policyStartAge,
        policyEndAge,
        validRegYear,
        dateshouldGreaterThanReg,
        isvalidEndDate,
      } = dateCompare(
        formik?.values?.tpPolicyStartDate,
        formik?.values?.tpPolicyEndtDate,
        formik.values?.registrationYear,
        formik.values?.formtype
      );
      if (validRegYear?.length < 2) {
        setErrorMessage("Please first select  registration year");
        return;
      }
      if (dateValid?.length < 2) {
        setErrorMessage("start date and end date are required");
        return;
      }
      if (!properDates) {
        setErrorMessage("Start date should be smaller than end date");
        return;
      }
      if (isFutureDate) {
        setErrorMessage("Future dates are not");
        return;
      }
      if (!policyEndAge) {
        setErrorMessage("End date should be greater than  registration date");
        return;
      }
      if (!policyStartAge) {
        setErrorMessage(
          `Policy end date should be with in ${
            formik.values?.formtype === "bike" ? "five" : "three"
          } year from Policy start date`
        );
        return;
      }
      // if (!dateshouldGreaterThanReg) {
      //   setErrorMessage(
      //     "Policy Start date should be greater than vehicle registration date"
      //   );
      //   return;
      // }
      if (!isvalidEndDate) {
        setErrorMessage("Policy End date should be greater than current date");
        return;
      }
      if (!isvalidEndDate) {
        setErrorMessage("Policy End date should be greater than current date");
        return;
      }
    }
    settpPolicyStatus(false);
    seterrorForOdPopup(false);
  };
  const setErrorMessage = (message) => {
    seterrorMessageForOdPopup(message);
    seterrorForOdPopup(true);
  };

  const closeShareQuptes = () => {
    setshareQuotesPopup(false);
  };
  // jsx
  // const popForIdvMinIdv = (
  //   <>
  //     {" "}
  //     <Form className="IDV">
  //       <InputFieldRadio
  //         formikFieldName="idv"
  //         optionsArray={[
  //           { value: "maxValue", label: "BEST DEAL", name: "IDV" },
  //           { value: "minValue", label: "MIN IDV", name: "IDV" },
  //           { value: "insuredDeclareValue", label: "YOUR IDV", name: "IDV" },
  //         ]}
  //         formik={formik}
  //       />
  //       <FloatingLabel controlId="floatingPassword">
  //         <Form.Control
  //           type="number"
  //           placeholder=""
  //           className="floating-input"
  //           value={Number(idvSelectedValue)?.toFixed(0)}
  //           onChange={(e) => {
  //             let newValue = Number(e.target.value);
  //             setidvSelectedValue(newValue);
  //             formik.setFieldValue("idv", "insuredDeclareValue");
  //           }}
  //         />
  //       </FloatingLabel>
  //       {errorForOdPopup && (
  //         <div style={{ color: "red" }}>{errorMessageForOdPopup}</div>
  //       )}

  //       <div className="price-wrap">
  //         <span>₹ {Number(idvValue?.minValue || 0).toFixed(0)}</span>
  //         <span>₹ {Number(idvValue?.maxValue || 0).toFixed(0)}</span>
  //       </div>

  //       <div className="range">
  //         <input
  //           className="rangeinput"
  //           type="range"
  //           id="points"
  //           name="points"
  //           min={idvValue?.minValue || 0}
  //           max={idvValue?.maxValue || 0}
  //           value={idvSelectedValue}
  //           onChange={(e) => {
  //             const newValue = Number(e.target.value);
  //             setidvSelectedValue(newValue);
  //             formik.setFieldValue("idv", "insuredDeclareValue");
  //           }}
  //         />
  //       </div>

  //       <div className="text-center mt-4 footer-btn-wrap">
  //         <Button
  //           className="back-btn"
  //           onClick={() => {
  //             handleIdvCancle();
  //           }}
  //         >
  //           Cancel
  //         </Button>
  //         <Button
  //           className="primary-btn"
  //           onClick={() => {
  //             handleIdvPopupSave(formik.values, idvSelectedValue);
  //           }}
  //         >
  //           Save
  //         </Button>
  //       </div>
  //     </Form>
  //   </>
  // );

  const popForIdvMinIdv = (
    <>
      <Form className="IDV">
        <InputFieldRadio
          formikFieldName="idv"
          optionsArray={[
            { value: "maxValue", label: "BEST DEAL", name: "IDV" },
            { value: "minValue", label: "MIN IDV", name: "IDV" },
            { value: "insuredDeclareValue", label: "YOUR IDV", name: "IDV" },
          ]}
          formik={formik}
        />
        <FloatingLabel controlId="floatingPassword">
          <Form.Control
            type="number"
            placeholder=""
            className="floating-input"
            value={Number(idvSelectedValue)?.toFixed(0)}
            onChange={(e) => {
              let newValue = Number(e.target.value);
              setidvSelectedValue(newValue);
              formik.setFieldValue("idv", "insuredDeclareValue");
            }}
          />
        </FloatingLabel>
        {errorForOdPopup && (
          <div style={{ color: "red" }}>{errorMessageForOdPopup}</div>
        )}

        <div className="price-wrap">
          <span>₹ {Number(idvValue?.minValue || 0).toFixed(0)}</span>
          <span>₹ {Number(idvValue?.maxValue || 0).toFixed(0)}</span>
        </div>

        <div className="range" style={{ position: "relative" }}>
          <input
            className="rangeinput"
            type="range"
            id="points"
            name="points"
            min={idvValue?.minValue || 0}
            max={idvValue?.maxValue || 0}
            value={idvSelectedValue}
            onChange={(e) => {
              const newValue = Number(e.target.value);
              setidvSelectedValue(newValue);
              formik.setFieldValue("idv", "insuredDeclareValue");
            }}
          />
          {/* Display the range value dynamically */}
          <span
            className="range-value"
            style={{
              position: "absolute",
              top: "-20px", // Adjusts the vertical position
              left: `${
                ((idvSelectedValue - (idvValue?.minValue || 0)) /
                  ((idvValue?.maxValue || 1) - (idvValue?.minValue || 0))) *
                100
              }%`, // Dynamically set horizontal position
              transform: "translateX(-50%)",
              fontWeight: "bold",
            }}
          >
            {idvSelectedValue}
          </span>
        </div>

        <div className="text-center mt-4 footer-btn-wrap">
          <Button
            className="back-btn"
            onClick={() => {
              handleIdvCancle();
            }}
          >
            Cancel
          </Button>
          <Button
            className="primary-btn"
            onClick={() => {
              handleIdvPopupSave(formik.values, idvSelectedValue);
            }}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );

  const popPersonalAccident = (
    <>
      {" "}
      <Form>
        <div className="personal-accident">
          <p className="text-color">
            This policy covers the owner for death or disability due to an
            accident. Owner (in case of disability) or nominee (in case of
            death) will get 15 lakhs.
          </p>
          <p>YOU CAN BUY A PLAN WITHOUT PA COVER IN FOLLOWING CASES.</p>
          <ul className="list-style text-color">
            <li>The bike is registered in a company's name.</li>
            <li> You already have a Standalone PA Cover of 15 lakhs.</li>
            <li>You don't have valid driving license.</li>
          </ul>
          <div className="checkbox-row">
            <InputFieldCheckBox
              formik={formik}
              formikFieldName="personalAccidentCover"
              label="I declare that the vehicle is either company owned by an individual with existing Personal Accident(PA) cover of Rs.15 lakhs"
            />
          </div>
          <div className="text-center mt-4 footer-btn-wrap">
            <Button
              className="cancel-btn"
              onClick={() => {
                setpersonalAccCover(!personalAccCover);
                formik.setFieldValue("personalAccidentCover", "");
              }}
            >
              No Need
            </Button>
          </div>
        </div>
      </Form>
    </>
  );

  const popForAdditionalCover = (
    <>
      <Form className="additional-cover-form">
        <div className="inner-container">
          <div className="left-cover">
            {formik.values.policyType !== "OD" && (
              <div className="checkbox-row mb-3">
                <div className="checkbox-input">
                  <InputFieldCheckBox
                    formik={formik}
                    formikFieldName="driverAccidentCover"
                    label="Driver Accident cover"
                  />
                  {formik.values.driverAccidentCover && (
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="driverAccidentCoverAmount"
                        label=""
                        optionsArray={[
                          { value: 10000, label: "10,000" },
                          { value: 20000, label: "20,000" },
                          { value: 30000, label: "30,000" },
                          { value: 40000, label: "40,000" },
                          { value: 50000, label: "50,000" },
                          { value: 60000, label: "60,000" },
                          { value: 70000, label: "70,000" },
                          { value: 80000, label: "80,000" },
                          { value: 90000, label: "90,000" },
                          { value: 100000, label: "1,00,000" },
                          { value: 120000, label: "1,20,000" },
                          { value: 130000, label: "1,30,000" },
                          { value: 140000, label: "1,40,000" },
                          { value: 150000, label: "1,50,000" },
                          { value: 160000, label: "1,60,000" },
                          { value: 170000, label: "1,70,000" },
                          { value: 180000, label: "1,80,000" },
                          { value: 190000, label: "1,90,000" },
                          { value: 200000, label: "2,00,000" },
                        ]}
                        formik={formik}
                      />
                    </div>
                  )}
                </div>
                <div className="padding-left">
                  <span className="help-text">
                    In case of any unfortunate accident the add on will provides
                    coverage for any bodily injury or death of driver up to the
                    sum insured selected.
                  </span>
                </div>
              </div>
            )}
            <div className="checkbox-row">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="lpgCngKit"
                  label="LPG-CNG Kit"
                />
                {formik.values.lpgCngKit && (
                  <>
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="lpgCngKitAmount"
                        label=""
                        optionsArray={amountArray}
                        formik={formik}
                      />
                    </div>
                  </>
                )}
              </div>
              {formik.values.lpgCngKit && (
                <>
                  <div className=" padding-left form-floating ">
                    <InputFieldDropdown
                      formikFieldName="lpgCngKitPYP"
                      label="Addon opted in previous year policy?"
                      optionsArray={[
                        { value: "No", label: "No" },
                        { value: "Yes", label: "Yes" },
                      ]}
                      formik={formik}
                    />
                  </div>
                </>
              )}
              <div className="padding-left">
                <span className="help-text">
                  External fitted LPG-CNG kit will not be the part of your
                  selected IDV. this add on will provide the coverage to your
                  external fitted LPG-CNG Kit.
                </span>
              </div>
            </div>
            {formik.values.policyType !== "TP" && (
              <div className="checkbox-row mb-3">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="ncbProtection"
                  label="NCB Protection"
                />
                <div className="padding-left">
                  <span className="help-text">
                    The add-on safeguards the earned discount on premiums by
                    preventing its reduction even after making a certain number
                    of claims.
                  </span>
                </div>
              </div>
            )}
            {formik.values.policyType !== "TP" && (
              <div className="checkbox-row">
                <div className="checkbox-input">
                  <InputFieldCheckBox
                    formik={formik}
                    formikFieldName="transportHotelExpenses"
                    label="Emergency Transport and Hotel Expenses"
                  />
                  {formik.values.transportHotelExpenses && (
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="transportHotelExpensesAmount"
                        label=""
                        optionsArray={[
                          { value: 1000, label: "1000" },
                          { value: 2000, label: "2000" },
                        ]}
                        formik={formik}
                      />
                    </div>
                  )}
                </div>
                <div className="padding-left">
                  <span className="help-text">
                    The add-on provides coverage for transportation and
                    accommodation costs in case of a breakdown or accident
                  </span>
                </div>
              </div>
            )}
            {formik.values.policyType !== "TP" && (
              <div className="checkbox-row mb-3">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="baggageCover"
                  label="Loss of Baggage Cover"
                />
                <div className="padding-left">
                  <span className="help-text">
                    In case of any unfortunate accident, the add on will
                    provides coverage for any bodily injury or death of driver
                    up to the Sum Insured selected
                  </span>
                </div>
              </div>
            )}
            {formik.values.policyType !== "TP" && (
              <div className="checkbox-row mb-3">
                <div className="checkbox-input">
                  <InputFieldCheckBox
                    formik={formik}
                    formikFieldName="tyreProtection"
                    label="Tire Protection"
                  />
                  {formik.values.tyreProtection && (
                    <div className="form-floating">
                      <InputFieldText
                        formikFieldName="tyreProtectionAmount"
                        placeholder="Tire Details"
                        maxLength={100}
                        formik={formik} // Pass formik down to InputFieldText
                        // onlyNumber={true}
                      />
                    </div>
                  )}
                </div>
                <div className="padding-left">
                  <span className="help-text">
                    The add-on provides coverage for repair or replacement costs
                    of damaged tires.
                  </span>
                </div>
              </div>
            )}
            {formik.values.policyType !== "TP" && (
              <div className="checkbox-row">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="keyProtection"
                  label="Key Protection"
                />
                <div className="padding-left">
                  <span className="help-text">
                    The add-on provides coverage for the repair or replacement
                    of lost, stolen, or damaged vehicle keys, including the
                    associated costs of locksmith services
                  </span>
                </div>
              </div>
            )}
            {dealId && (
              <>
                {/* Battery Protect Cover == checkbox*/}
                <div className="checkbox-row mb-3">
                  <InputFieldCheckBox
                    formik={formik}
                    formikFieldName="batteryProtectCover"
                    label="Battery Protect Cover"
                  />
                  <div className="padding-left">
                    <span className="help-text">
                      The add-on provides protection for repair or replacement
                      costs incurred due to damage to the insured vehicle's
                      Battery
                    </span>
                  </div>
                </div>
                {/* EME Cover == 2 fields */}
                <div className="checkbox-row mb-3">
                  <div className="checkbox-input">
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="emeCover"
                      label="EME Cover"
                    />
                    {formik.values.emeCover && (
                      <>
                        <div className="form-floating ">
                          <InputFieldDropdown
                            formikFieldName="emeCoverNumber"
                            label="No. Of passenger"
                            optionsArray={[
                              { value: 1, label: "1" },
                              { value: 2, label: "2" },
                            ]}
                            formik={formik}
                          />
                        </div>
                        <div className="form-floating ">
                          <InputFieldDropdown
                            formikFieldName="emeCoverAmount"
                            label="EME Cover"
                            optionsArray={[
                              { value: "EME-99", label: "EME-99" },
                              { value: "EME-500", label: "EME-500" },
                              { value: "EME-199", label: "EME-199" },
                              { value: "EME-749", label: "EME-749" },
                              { value: "EME-LT 749", label: "EME-LT 749" },
                              { value: "EME-LT", label: "EME-LT" },
                            ]}
                            formik={formik}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="padding-left">
                    <span className="help-text">
                      The add-on provides Emergency medical expenses.
                    </span>
                  </div>
                </div>
                {/* Liability to Paid Driver */}
                <div className="checkbox-row mb-3">
                  <div className="checkbox-input">
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="liabilityToPaidDriver"
                      label="Liability to Paid Driver"
                    />
                    {formik.values.liabilityToPaidDriver && (
                      <div className="form-floating ">
                        <InputFieldDropdown
                          formikFieldName="liabilityToPaidDriverAmount"
                          label=""
                          optionsArray={[
                            { value: 1, label: "1" },
                            { value: 2, label: "2" },
                          ]}
                          formik={formik}
                        />
                      </div>
                    )}
                  </div>
                  <div className="padding-left">
                    <span className="help-text">
                      The add-on provides protection for repair or replacement
                      costs incurred due to damage to the insured vehicle's
                      engine and gearbox.
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="right-cover">
            {formik.values.policyType !== "OD" && (
              <div className="checkbox-row">
                <div className="checkbox-input">
                  <InputFieldCheckBox
                    formik={formik}
                    formikFieldName="personalCoverFlag"
                    label="Passenger Cover"
                  />
                  {formik.values.personalCoverFlag && (
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="personalCover"
                        label=""
                        optionsArray={[
                          { value: 10000, label: "10,000" },
                          { value: 20000, label: "20,000" },
                          { value: 30000, label: "30,000" },
                          { value: 40000, label: "40,000" },
                          { value: 50000, label: "50,000" },
                          { value: 60000, label: "60,000" },
                          { value: 70000, label: "70,000" },
                          { value: 80000, label: "80,000" },
                          { value: 90000, label: "90,000" },
                          { value: 100000, label: "1,00,000" },
                          { value: 120000, label: "1,20,000" },
                          { value: 130000, label: "1,30,000" },
                          { value: 140000, label: "1,40,000" },
                          { value: 150000, label: "1,50,000" },
                          { value: 160000, label: "1,60,000" },
                          { value: 170000, label: "1,70,000" },
                          { value: 180000, label: "1,80,000" },
                          { value: 190000, label: "1,90,000" },
                          { value: 200000, label: "2,00,000" },
                        ]}
                        formik={formik}
                      />
                    </div>
                  )}
                </div>

                <div>
                  <span className="help-text">
                    In case of any unfortunate accident, the add on will
                    provides coverage for any bodily injury or death of
                    passenger up to sum insured selected
                  </span>
                </div>
              </div>
            )}
            {formik.values.policyType !== "TP" && (
              <div className="checkbox-row accessories-wrap">
                <div className="accessories">
                  <InputFieldCheckBox
                    formik={formik}
                    formikFieldName="accessories"
                    label="Accessories"
                  />
                  <div className="padding-left">
                    <span className="help-text">
                      Additional accessories will not be a part of standard
                      policy, protect your additional accessories under the
                      accessories add on.
                    </span>
                  </div>
                </div>
                {formik.values.accessories && (
                  <div className="accessories-child">
                    {formik.values.policyType !== "TP" && (
                      <div className="checkbox-row mt-3">
                        <div className="checkbox-input">
                          <InputFieldCheckBox
                            formik={formik}
                            formikFieldName="electricalAccessories"
                            label="Electrical"
                          />
                          {formik.values.electricalAccessories && (
                            <div className="form-floating ">
                              {/* <InputFieldText
                                formikFieldName="electricalAccessoriesAmount"
                                formik={formik}
                              /> */}
                              {dealId ? (
                                <>
                                  <Form.Group controlId="electricalAccessoriesAmount">
                                    <Form.Control
                                      className="floating-input"
                                      type="number"
                                      name="electricalAccessoriesAmount"
                                      value={
                                        formik.values
                                          .electricalAccessoriesAmount
                                      }
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        formik.setFieldValue(
                                          "electricalAccessoriesAmount",
                                          value
                                        );
                                        // Run validation manually after setting value
                                        formik.validateField(
                                          "electricalAccessoriesAmount"
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                      isInvalid={
                                        formik.touched
                                          .electricalAccessoriesAmount &&
                                        formik.errors
                                          .electricalAccessoriesAmount
                                      }
                                      min="0"
                                      max="1000000"
                                      placeholder="Enter amount"
                                    />
                                    {formik.touched
                                      .electricalAccessoriesAmount &&
                                      formik.errors
                                        .electricalAccessoriesAmount && (
                                        <Form.Control.Feedback type="invalid">
                                          {
                                            formik.errors
                                              .electricalAccessoriesAmount
                                          }
                                        </Form.Control.Feedback>
                                      )}
                                  </Form.Group>
                                </>
                              ) : (
                                // Render dropdown if dealId is not present
                                <InputFieldDropdown
                                  formikFieldName="electricalAccessoriesAmount"
                                  label=""
                                  optionsArray={amountArray}
                                  formik={formik}
                                />
                              )}
                            </div>
                          )}
                        </div>
                        <div className="padding-left">
                          <span className="help-text">
                            A cover on electrical accessories fitted in your
                            car. Most insurer provide a cover for your car
                            accessories at an additional premium of 4% on its
                            value.
                          </span>
                        </div>
                      </div>
                    )}
                    {formik.values.policyType !== "TP" && (
                      <div className="checkbox-row">
                        <div className="checkbox-input">
                          <InputFieldCheckBox
                            formik={formik}
                            formikFieldName="nonElectricalAccessories"
                            label="Non-Electrical"
                          />
                          {formik.values.nonElectricalAccessories && (
                            <div className="form-floating ">
                              {/* <InputFieldText
                                formikFieldName="nonElectricalAccessoriesAmount"
                                formik={formik}
                              /> */}

                              {dealId ? (
                                <>
                                  <Form.Group controlId="nonElectricalAccessoriesAmount">
                                    <Form.Control
                                      className="floating-input"
                                      type="number"
                                      name="nonElectricalAccessoriesAmount"
                                      value={
                                        formik.values
                                          .nonElectricalAccessoriesAmount
                                      }
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        formik.setFieldValue(
                                          "nonElectricalAccessoriesAmount",
                                          value
                                        );
                                        // Run validation manually after setting value
                                        formik.validateField(
                                          "nonElectricalAccessoriesAmount"
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                      isInvalid={
                                        formik.touched
                                          .nonElectricalAccessoriesAmount &&
                                        formik.errors
                                          .nonElectricalAccessoriesAmount
                                      }
                                      min="0"
                                      max="200000"
                                      placeholder="Enter amount"
                                    />
                                    {formik.touched
                                      .nonElectricalAccessoriesAmount &&
                                      formik.errors
                                        .nonElectricalAccessoriesAmount && (
                                        <Form.Control.Feedback type="invalid">
                                          {
                                            formik.errors
                                              .nonElectricalAccessoriesAmount
                                          }
                                        </Form.Control.Feedback>
                                      )}
                                  </Form.Group>
                                </>
                              ) : (
                                // Render dropdown if dealId is not present
                                <InputFieldDropdown
                                  formikFieldName="nonElectricalAccessoriesAmount"
                                  label=""
                                  optionsArray={amountArray}
                                  formik={formik}
                                />
                              )}
                            </div>
                          )}
                        </div>
                        <div className="padding-left">
                          <span className="help-text">
                            A cover on non-electrical accessories fitted in your
                            car. Most insurer provide a cover for your car
                            accessories at an additional premium of 4% on its
                            value.
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {formik.values.policyType !== "TP" && (
              <div className="checkbox-row mb-3">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="zeroDep"
                  label="Zero Depreciation cover"
                />
                <div className="padding-left">
                  <span className="help-text">
                    Also known as "nil depreciation" or "bumper-to-bumper"
                    insurance, provides full coverage for repair or replacement
                    costs without factoring the vehicle body part depreciation.
                  </span>
                </div>
              </div>
            )}
            {formik.values.policyType !== "TP" && (
              <div className="checkbox-row mb-3">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="consumablesCover"
                  label="Consumables cover"
                />
                <div>
                  <span className="help-text">
                    The add-on provides protection for the reimbursement of
                    expenses related to consumable items, such as engine oil,
                    coolant, and nuts and bolts, incurred during repairs.
                  </span>
                </div>
              </div>
            )}
            {formik.values.policyType !== "TP" && (
              <div className="checkbox-row mb-3">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="roadSideAssistance"
                  label="Roadside Assistance"
                />
                <div className="padding-left">
                  <span className="help-text">
                    In the event of vehicle breakdowns or emergencies, the add
                    on will provide assistance and support services to
                    policyholders.
                  </span>
                </div>
              </div>
            )}
            {formik.values.policyType !== "TP" && (
              <div className="checkbox-row">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="invoiceCover"
                  label="Return to Invoice"
                />
                <div className="padding-left">
                  <span className="help-text">
                    In the event of a total loss or theft of the insured
                    vehicle, the policyholder receives the original invoice
                    value of the vehicle.
                  </span>
                </div>
              </div>
            )}
            {formik.values.policyType !== "TP" && (
              <div className="checkbox-row mb-3">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="engineProtector"
                  label="Engine and Gearbox Protection"
                />
                <div className="padding-left">
                  <span className="help-text">
                    The add-on provides protection for repair or replacement
                    costs incurred due to damage to the insured vehicle's engine
                    and gearbox.
                  </span>
                </div>
              </div>
            )}

            {/* ----------------------------------------- */}
            {dealId && (
              <>
                {/* Employee Accident Cover */}
                <div className="checkbox-row mb-3">
                  <div className="checkbox-input">
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="employeeAccidentCover"
                      label="Liability to Paid employee"
                    />
                    {formik.values.employeeAccidentCover && (
                      <div className="form-floating ">
                        <InputFieldDropdown
                          formikFieldName="employeeAccidentCoversAmount"
                          label=""
                          optionsArray={[
                            { value: 1, label: "1" },
                            { value: 2, label: "2" },
                          ]}
                          formik={formik}
                        />
                      </div>
                    )}
                  </div>
                  <div className="padding-left">
                    <span className="help-text">
                      The add-on provides Liability to Paid employee.
                    </span>
                  </div>
                </div>
                {/* Voluntary Deductable Cover */}
                <div className="checkbox-row mb-3">
                  <div className="checkbox-input">
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="voluntaryDeductableCover"
                      label="Voluntary Deductable Cover"
                    />
                    {formik.values.voluntaryDeductableCover && (
                      <div className="form-floating ">
                        <InputFieldDropdown
                          formikFieldName="voluntaryDeductableCoverAmount"
                          label=""
                          optionsArray={[
                            { value: 500, label: "500" },
                            { value: 750, label: "750" },
                            { value: 1000, label: "1,000" },
                            { value: 1250, label: "1,250" },
                            { value: 1500, label: "1,500" },
                            { value: 3000, label: "3,000" },
                            { value: 10000, label: "10,000" },
                          ]}
                          formik={formik}
                        />
                      </div>
                    )}
                  </div>
                  <div className="padding-left">
                    <span className="help-text">
                      In case of any unfortunate accident the add on will
                      provides coverage for any bodily injury or death of driver
                      up to the sum insured selected.
                    </span>
                  </div>
                </div>
              </>
            )}
            {/* --------------------------------------- */}
          </div>
        </div>
        <div className="text-center mt-4 footer-btn-wrap">
          <Button
            className="back-btn"
            onClick={() => {
              setriderStatus(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="primary-btn"
            onClick={() => {
              setriderStatus(false);
              // formik.validateForm().then((errors) => {
              //   if (Object.keys(errors).length === 0) {
              //     // No errors, change riderStatus
              //     setriderStatus(false);
              //   }
              // });
            }}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );

  const bikePopForAdditionalCover = (
    <>
      <Form className="additional-cover-form">
        <div className="inner-container">
          <div className="left-cover">
            {" "}
            {formik.values.policyType !== "OD" && (
              <div className="checkbox-row">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="personalCoverFlag"
                  label="Passenger Cover"
                />
                <div>
                  {formik.values.personalCoverFlag && (
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="personalCover"
                        label=""
                        optionsArray={[
                          { value: 50000, label: "50,000" },
                          { value: 100000, label: "1,00,000" },
                          { value: 150000, label: "1,50,000" },
                          { value: 200000, label: "2,00,000" },
                        ]}
                        formik={formik}
                      />
                    </div>
                  )}
                  <span className="help-text">
                    In case of any unfortunate accident, the add on will
                    provides coverage for any bodily injury or death of
                    passenger up to sum insured selected
                  </span>
                </div>
              </div>
            )}
            <div className="checkbox-row mb-3">
              <InputFieldCheckBox
                formik={formik}
                formikFieldName="zeroDep"
                label="Zero Depreciation"
              />
              <div>
                <span className="help-text">
                  Also known as "nil depreciation" or "bumper-to-bumper"
                  insurance, provides full coverage for repair or replacement
                  costs without factoring the vehicle body part depreciation.
                </span>
              </div>
            </div>
            {dealId && (
              <>
                {/* Employee Accident Cover */}
                <div className="checkbox-row mb-3">
                  <div className="checkbox-input">
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="employeeAccidentCover"
                      label="Liability to Paid employee"
                    />
                    {formik.values.employeeAccidentCover && (
                      <div className="form-floating ">
                        <InputFieldDropdown
                          formikFieldName="employeeAccidentCoversAmount"
                          label=""
                          optionsArray={[
                            { value: 1, label: "1" },
                            { value: 2, label: "2" },
                          ]}
                          formik={formik}
                        />
                      </div>
                    )}
                  </div>
                  <div className="padding-left">
                    <span className="help-text">
                      The add-on provides Liability to Paid employee.
                    </span>
                  </div>
                </div>

                {formik.values.policyType !== "TP" && (
                  <div className="checkbox-row mb-3">
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="ncbProtection"
                      label="NCB Protection"
                    />
                    <div className="padding-left">
                      <span className="help-text">
                        The add-on safeguards the earned discount on premiums by
                        preventing its reduction even after making a certain
                        number of claims.
                      </span>
                    </div>
                  </div>
                )}

                {formik.values.policyType !== "TP" && (
                  <div className="checkbox-row mb-3">
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="roadSideAssistance"
                      label="Roadside Assistance"
                    />
                    <div className="padding-left">
                      <span className="help-text">
                        In the event of vehicle breakdowns or emergencies, the
                        add on will provide assistance and support services to
                        policyholders.
                      </span>
                    </div>
                  </div>
                )}

                {formik.values.policyType !== "TP" && (
                  <div className="checkbox-row">
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="invoiceCover"
                      label="Return to Invoice"
                    />
                    <div className="padding-left">
                      <span className="help-text">
                        In the event of a total loss or theft of the insured
                        vehicle, the policyholder receives the original invoice
                        value of the vehicle.
                      </span>
                    </div>
                  </div>
                )}

                {formik.values.policyType !== "TP" && (
                  <div className="checkbox-row mb-3">
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="engineProtector"
                      label="Engine and Gearbox Protection"
                    />
                    <div className="padding-left">
                      <span className="help-text">
                        The add-on provides protection for repair or replacement
                        costs incurred due to damage to the insured vehicle's
                        engine and gearbox.
                      </span>
                    </div>
                  </div>
                )}

                {formik.values.policyType !== "TP" && (
                  <div className="checkbox-row accessories-wrap">
                    <div className="accessories">
                      <InputFieldCheckBox
                        formik={formik}
                        formikFieldName="accessories"
                        label="Accessories"
                      />
                      <div className="padding-left">
                        <span className="help-text">
                          Additional accessories will not be a part of standard
                          policy, protect your additional accessories under the
                          accessories add on.
                        </span>
                      </div>
                    </div>
                    {formik.values.accessories && (
                      <div className="accessories-child">
                        {formik.values.policyType !== "TP" && (
                          <div className="checkbox-row mt-3">
                            <div className="checkbox-input">
                              <InputFieldCheckBox
                                formik={formik}
                                formikFieldName="electricalAccessories"
                                label="Electrical"
                              />
                              {formik.values.electricalAccessories && (
                                <div className="form-floating ">
                                  {/* <InputFieldText
                                formikFieldName="electricalAccessoriesAmount"
                                formik={formik}
                              /> */}
                                  {dealId ? (
                                    <>
                                      <Form.Group controlId="electricalAccessoriesAmount">
                                        <Form.Control
                                          className="floating-input"
                                          type="number"
                                          name="electricalAccessoriesAmount"
                                          value={
                                            formik.values
                                              .electricalAccessoriesAmount
                                          }
                                          onChange={(e) => {
                                            const { value } = e.target;
                                            formik.setFieldValue(
                                              "electricalAccessoriesAmount",
                                              value
                                            );
                                            // Run validation manually after setting value
                                            formik.validateField(
                                              "electricalAccessoriesAmount"
                                            );
                                          }}
                                          onBlur={formik.handleBlur}
                                          isInvalid={
                                            formik.touched
                                              .electricalAccessoriesAmount &&
                                            formik.errors
                                              .electricalAccessoriesAmount
                                          }
                                          min="0"
                                          max="1000000"
                                          placeholder="Enter amount"
                                        />
                                        {formik.touched
                                          .electricalAccessoriesAmount &&
                                          formik.errors
                                            .electricalAccessoriesAmount && (
                                            <Form.Control.Feedback type="invalid">
                                              {
                                                formik.errors
                                                  .electricalAccessoriesAmount
                                              }
                                            </Form.Control.Feedback>
                                          )}
                                      </Form.Group>
                                    </>
                                  ) : (
                                    // Render dropdown if dealId is not present
                                    <InputFieldDropdown
                                      formikFieldName="electricalAccessoriesAmount"
                                      label=""
                                      optionsArray={amountArray}
                                      formik={formik}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="padding-left">
                              <span className="help-text">
                                A cover on electrical accessories fitted in your
                                car. Most insurer provide a cover for your car
                                accessories at an additional premium of 4% on
                                its value.
                              </span>
                            </div>
                          </div>
                        )}
                        {formik.values.policyType !== "TP" && (
                          <div className="checkbox-row">
                            <div className="checkbox-input">
                              <InputFieldCheckBox
                                formik={formik}
                                formikFieldName="nonElectricalAccessories"
                                label="Non-Electrical"
                              />
                              {formik.values.nonElectricalAccessories && (
                                <div className="form-floating ">
                                  {/* <InputFieldText
                                formikFieldName="nonElectricalAccessoriesAmount"
                                formik={formik}
                              /> */}

                                  {dealId ? (
                                    <>
                                      <Form.Group controlId="nonElectricalAccessoriesAmount">
                                        <Form.Control
                                          className="floating-input"
                                          type="number"
                                          name="nonElectricalAccessoriesAmount"
                                          value={
                                            formik.values
                                              .nonElectricalAccessoriesAmount
                                          }
                                          onChange={(e) => {
                                            const { value } = e.target;
                                            formik.setFieldValue(
                                              "nonElectricalAccessoriesAmount",
                                              value
                                            );
                                            // Run validation manually after setting value
                                            formik.validateField(
                                              "nonElectricalAccessoriesAmount"
                                            );
                                          }}
                                          onBlur={formik.handleBlur}
                                          isInvalid={
                                            formik.touched
                                              .nonElectricalAccessoriesAmount &&
                                            formik.errors
                                              .nonElectricalAccessoriesAmount
                                          }
                                          min="0"
                                          max="200000"
                                          placeholder="Enter amount"
                                        />
                                        {formik.touched
                                          .nonElectricalAccessoriesAmount &&
                                          formik.errors
                                            .nonElectricalAccessoriesAmount && (
                                            <Form.Control.Feedback type="invalid">
                                              {
                                                formik.errors
                                                  .nonElectricalAccessoriesAmount
                                              }
                                            </Form.Control.Feedback>
                                          )}
                                      </Form.Group>
                                    </>
                                  ) : (
                                    // Render dropdown if dealId is not present
                                    <InputFieldDropdown
                                      formikFieldName="nonElectricalAccessoriesAmount"
                                      label=""
                                      optionsArray={amountArray}
                                      formik={formik}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="padding-left">
                              <span className="help-text">
                                A cover on non-electrical accessories fitted in
                                your car. Most insurer provide a cover for your
                                car accessories at an additional premium of 4%
                                on its value.
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          {dealId && (
            <>
              <div className="right-cover">
                {formik.values.policyType !== "TP" && (
                  <div className="checkbox-row mb-3">
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="consumablesCover"
                      label="Consumables cover"
                    />
                    <div>
                      <span className="help-text">
                        The add-on provides protection for the reimbursement of
                        expenses related to consumable items, such as engine
                        oil, coolant, and nuts and bolts, incurred during
                        repairs.
                      </span>
                    </div>
                  </div>
                )}

                <div className="checkbox-row mb-3">
                  <div className="checkbox-input">
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="voluntaryDeductableCover"
                      label="Voluntary Deductable Cover"
                    />
                    {formik.values.voluntaryDeductableCover && (
                      <div className="form-floating ">
                        <InputFieldDropdown
                          formikFieldName="voluntaryDeductableCoverAmount"
                          label=""
                          optionsArray={[
                            { value: 500, label: "500" },
                            { value: 750, label: "750" },
                            { value: 1000, label: "1,000" },
                            { value: 1250, label: "1,250" },
                            { value: 1500, label: "1,500" },
                            { value: 3000, label: "3,000" },
                            { value: 10000, label: "10,000" },
                          ]}
                          formik={formik}
                        />
                      </div>
                    )}
                  </div>
                  <div className="padding-left">
                    <span className="help-text">
                      In case of any unfortunate accident the add on will
                      provides coverage for any bodily injury or death of driver
                      up to the sum insured selected.
                    </span>
                  </div>
                </div>

                {formik.values.policyType !== "TP" && (
                  <div className="checkbox-row mb-3">
                    <div className="checkbox-input">
                      <InputFieldCheckBox
                        formik={formik}
                        formikFieldName="tyreProtection"
                        label="Tire Protection"
                      />
                      {formik.values.tyreProtection && (
                        <div className="form-floating">
                          <InputFieldText
                            formikFieldName="tyreProtectionAmount"
                            placeholder="Tire Details"
                            maxLength={100}
                            formik={formik} // Pass formik down to InputFieldText
                            // onlyNumber={true}
                          />
                        </div>
                      )}
                    </div>
                    <div className="padding-left">
                      <span className="help-text">
                        The add-on provides coverage for repair or replacement
                        costs of damaged tires.
                      </span>
                    </div>
                  </div>
                )}

                <div className="checkbox-row mb-3">
                  <InputFieldCheckBox
                    formik={formik}
                    formikFieldName="batteryProtectCover"
                    label="Battery Protect Cover"
                  />
                  <div className="padding-left">
                    <span className="help-text">
                      The add-on provides protection for repair or replacement
                      costs incurred due to damage to the insured vehicle's
                      Battery
                    </span>
                  </div>
                </div>

                <div className="checkbox-row mb-3">
                  <div className="checkbox-input">
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="employeeAccidentCover"
                      label="Liability to Paid employee"
                    />
                    {formik.values.employeeAccidentCover && (
                      <div className="form-floating ">
                        <InputFieldDropdown
                          formikFieldName="employeeAccidentCoversAmount"
                          label=""
                          optionsArray={[
                            { value: 1, label: "1" },
                            { value: 2, label: "2" },
                          ]}
                          formik={formik}
                        />
                      </div>
                    )}
                  </div>
                  <div className="padding-left">
                    <span className="help-text">
                      The add-on provides Liability to Paid employee.
                    </span>
                  </div>
                </div>

                {formik.values.policyType !== "TP" && (
                  <div className="checkbox-row">
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="keyProtection"
                      label="Key Protection"
                    />
                    <div className="padding-left">
                      <span className="help-text">
                        The add-on provides coverage for the repair or
                        replacement of lost, stolen, or damaged vehicle keys,
                        including the associated costs of locksmith services
                      </span>
                    </div>
                  </div>
                )}

                <div className="checkbox-row mb-3">
                  <div className="checkbox-input">
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="liabilityToPaidDriver"
                      label="Liability to Paid Driver"
                    />
                    {formik.values.liabilityToPaidDriver && (
                      <div className="form-floating ">
                        <InputFieldDropdown
                          formikFieldName="liabilityToPaidDriverAmount"
                          label=""
                          optionsArray={[
                            { value: 1, label: "1" },
                            { value: 2, label: "2" },
                          ]}
                          formik={formik}
                        />
                      </div>
                    )}
                  </div>
                  <div className="padding-left">
                    <span className="help-text">
                      The add-on provides protection for repair or replacement
                      costs incurred due to damage to the insured vehicle's
                      engine and gearbox.
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="text-center mt-4 footer-btn-wrap">
          <Button
            className="back-btn"
            onClick={() => {
              setriderStatus(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="primary-btn"
            onClick={() => {
              setriderStatus(false);
              // formik.validateForm().then((errors) => {
              //   if (Object.keys(errors).length === 0) {
              //     // No errors, change riderStatus
              //     setriderStatus(false);
              //   }
              // });
            }}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );

  const iciciPopForAdditionalCoverBike = (
    <>
      <Form className="additional-cover-form">
        <div className="inner-container">
          <div className="left-cover">
            {/* PA cover */}
            <div className="checkbox-row">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  disabledValue={formik?.values?.validLicence}
                  formik={formik}
                  formikFieldName="personalAccidentCover"
                  label="PA Cover for Owner Driver"
                />
              </div>
              <div className="padding-left">
                <span className="help-text">
                  In case of any unfortunate accident, the add on will provides
                  coverage for any bodily injury or death up to 15 Lakh sum
                  insured
                </span>
              </div>
            </div>
            {/* Toggle PA cover */}
            <div className="checkbox-row">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="existingPersonalCoverFlag"
                  label="Already holds a PA cover of 15L Sum Insured"
                />
              </div>
              <div className="padding-left">
                <span className="help-text">
                  In case of existing PA cover is present
                </span>
              </div>
            </div>
            {/* unnamed passenger cover  */}
            <div className="checkbox-row">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="personalCoverFlag"
                  label="Unnamed Passenger Accident Cover"
                />
                {/* {formik.values.personalCoverFlag && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="personalCover"
                      label=""
                      optionsArray={[
                        { value: 50000, label: "50,000" },

                        { value: 100000, label: "1,00,000" },

                        { value: 150000, label: "1,50,000" },

                        { value: 200000, label: "2,00,000" },
                      ]}
                      formik={formik}
                    />
                  </div>
                )} */}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  In case of any unfortunate accident, the add on will provides
                  coverage for any bodily injury or death of Unnamed Passenger
                  Accident Cover up to 2 Lakh sum insured
                </span>
              </div>
            </div>
            {/* Paid employee */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="employeeAccidentCover"
                  label="Liability to Paid employee"
                />
                {formik.values.employeeAccidentCover && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="employeeAccidentCoversAmount"
                      label=""
                      optionsArray={[
                        { value: 1, label: "1" },
                        { value: 2, label: "2" },
                      ]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides Liability to Paid employee.
                </span>
              </div>
            </div>
            {/* NCB */}
            <div className="checkbox-row mb-3">
              <InputFieldCheckBox
                formik={formik}
                formikFieldName="ncbProtection"
                label="NCB Protection"
              />
              {/* {formik?.values?.ncbProtection && (
                <div className="form-floating ">
                  <InputFieldDropdown
                    formikFieldName="ncbPlan"
                    label=""
                    optionsArray={[
                      { value: "plan1", label: "Plan 1" },
                      { value: "plan2", label: "Plan 2" },
                    ]}
                    formik={formik}
                  />
                </div>
              )} */}
              <div className="padding-left">
                <span className="help-text">
                  The add-on safeguards the earned discount on premiums by
                  preventing its reduction even after making a certain number of
                  claims.
                </span>
              </div>
            </div>
            {/* Zero Dep  */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="zeroDep"
                  label="Zero Depreciation cover"
                />
                {formik.values.zeroDep && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="zeroDepAmount"
                      label=""
                      optionsArray={[{ value: "silver", label: "Silver" }]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  Also known as "nil depreciation" or "bumper-to-bumper"
                  insurance, provides full coverage for repair or replacement
                  costs without factoring the vehicle body part depreciation.
                </span>
              </div>
            </div>
            {/* Consumable */}
            <div className="checkbox-row mb-3">
              <InputFieldCheckBox
                formik={formik}
                formikFieldName="consumablesCover"
                label="Consumables cover"
              />
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides protection for the reimbursement of
                  expenses related to consumable items, such as engine oil,
                  coolant, and nuts and bolts, incurred during repairs.
                </span>
              </div>
            </div>
            {/* Tyre */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="tyreProtection"
                  label="Tire Protection"
                />
              </div>
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides coverage for repair or replacement costs
                  of damaged tires.
                </span>
              </div>
            </div>
            {/* EME */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="emeCover"
                  label="EME Cover"
                />
                {formik.values.emeCover && (
                  <>
                    <br />
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="emeCoverNumber"
                        label="No. Of passenger"
                        optionsArray={[
                          { value: 1, label: "1" },
                          { value: 2, label: "2" },
                        ]}
                        formik={formik}
                      />
                    </div>
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="emeCoverAmount"
                        label="EME Cover"
                        optionsArray={[
                          { value: "EME-99", label: "EME-99" },
                          { value: "EME-500", label: "EME-500" },
                          { value: "EME-199", label: "EME-199" },
                          { value: "EME-749", label: "EME-749" },
                          { value: "EME-LT 749", label: "EME-LT 749" },
                          { value: "EME-LT", label: "EME-LT" },
                        ]}
                        formik={formik}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides Emergency medical expenses.
                </span>
              </div>
            </div>
          </div>
          <div className="right-cover">
            {/* valid Licence */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="validLicence"
                  label="Does not have a valid license"
                />
              </div>
              <div className="padding-left">
                <span className="help-text">
                  PA Cover for OD gets neglected
                </span>
              </div>
            </div>
            {/* Key Protect */}
            <div className="checkbox-row">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="keyProtection"
                  label="Key Protection"
                />
                {/* {formik?.values?.keyProtection && (
                  <div className="form-floating ">
                    <Form.Group controlId="keyProtectionAmount">
                      <Form.Control
                        className="floating-input"
                        type="number"
                        name="keyProtectionAmount"
                        value={formik.values.keyProtectionAmount}
                        onChange={(e) => {
                          const { value } = e.target;
                          formik.setFieldValue("keyProtectionAmount", value);
                          // Run validation manually after setting value
                        }}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.touched.keyProtectionAmount &&
                          formik.errors.keyProtectionAmount
                        }
                        min="0"
                        max="200000"
                        placeholder="Enter amount"
                      />
                      {formik.touched.keyProtectionAmount &&
                        formik.errors.keyProtectionAmount && (
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.keyProtectionAmount}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                )} */}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides coverage for the repair or replacement of
                  lost, stolen, or damaged vehicle keys, including the
                  associated costs of locksmith services
                </span>
              </div>
            </div>
            {/* paid Driver  */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="liabilityToPaidDriver"
                  label="Liability to Paid Driver"
                />
                {formik.values.liabilityToPaidDriver && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="liabilityToPaidDriverAmount"
                      label=""
                      optionsArray={[
                        { value: 1, label: "1" },
                        { value: 2, label: "2" },
                      ]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides protection for repair or replacement costs
                  incurred due to damage to the insured vehicle's engine and
                  gearbox.
                </span>
              </div>
            </div>
            {/* Voluntary Deductable */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="voluntaryDeductableCover"
                  label="Voluntary Deductable Cover"
                />
                {formik.values.voluntaryDeductableCover && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="voluntaryDeductableCoverAmount"
                      label=""
                      optionsArray={[
                        { value: 500, label: "500" },
                        { value: 750, label: "750" },
                        { value: 1000, label: "1,000" },
                        { value: 1250, label: "1,250" },
                        { value: 1500, label: "1,500" },
                        { value: 3000, label: "3,000" },
                        { value: 10000, label: "10,000" },
                      ]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  In case of any unfortunate accident the add on will provides
                  coverage for any bodily injury or death of driver up to the
                  sum insured selected.
                </span>
              </div>
            </div>
            {/* RSA  */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="roadSideAssistance"
                  label="Roadside Assistance"
                />
                {formik.values.roadSideAssistance && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="roadSideAssistancePlan"
                      label=""
                      optionsArray={[
                        { value: "RSA-199", label: "RSA-199" },
                        { value: "RSA-299", label: "RSA-299" },
                        { value: "RSA-338", label: "RSA-338" },
                        { value: "RSA-399", label: "RSA-399" },
                        { value: "RSA-LT", label: "RSA-LT" },
                        { value: "TW-99", label: "TW-99" },
                        { value: "TW-199", label: "TW-199" },
                        { value: "TW-249", label: "TW-249" },
                        { value: "TW-299", label: "TW-299" },
                        { value: "TW-398", label: "TW-398" },
                        { value: "TW-597", label: "TW-597" },
                        { value: "TW-598", label: "TW-598" },
                        { value: "TW-897", label: "TW-897" },
                        { value: "TW-995", label: "TW-995" },
                        { value: "TW-1495", label: "TW-1495" },
                      ]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  In the event of vehicle breakdowns or emergencies, the add on
                  will provide assistance and support services to policyholders.
                </span>
              </div>
            </div>
            {/* RTI  */}
            <div className="checkbox-row">
              <InputFieldCheckBox
                formik={formik}
                formikFieldName="invoiceCover"
                label="Return to Invoice"
              />
              <div className="padding-left">
                <span className="help-text">
                  In the event of a total loss or theft of the insured vehicle,
                  the policyholder receives the original invoice value of the
                  vehicle.
                </span>
              </div>
            </div>
            {/* Engine protect plus  */}
            <div className="checkbox-row mb-3">
              <InputFieldCheckBox
                formik={formik}
                formikFieldName="engineProtector"
                label="Engine and Gearbox Protection"
              />
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides protection for repair or replacement costs
                  incurred due to damage to the insured vehicle's engine and
                  gearbox.
                </span>
              </div>
            </div>
            {/* battery protect  */}
            <div className="checkbox-row mb-3">
              <InputFieldCheckBox
                formik={formik}
                formikFieldName="batteryProtectCover"
                label="Battery Protect Cover"
              />
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides protection for repair or replacement costs
                  incurred due to damage to the insured vehicle's Battery
                </span>
              </div>
            </div>
            {/* accessories  */}
            <div className="checkbox-row accessories-wrap">
              <div className="accessories">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="accessories"
                  label="Accessories"
                />
                <div className="padding-left">
                  <span className="help-text">
                    Additional accessories will not be a part of standard
                    policy, protect your additional accessories under the
                    accessories add on.
                  </span>
                </div>
              </div>
              {formik.values.accessories && (
                <div className="accessories-child">
                  {formik.values.policyType !== "TP" && (
                    <div className="checkbox-row mt-3">
                      <div className="checkbox-input">
                        <InputFieldCheckBox
                          formik={formik}
                          formikFieldName="electricalAccessories"
                          label="Electrical"
                        />
                        {formik.values.electricalAccessories && (
                          <div className="form-floating ">
                            {/* <InputFieldText
                              formikFieldName="electricalAccessoriesAmount"
                              formik={formik}
                            /> */}
                            {dealId ? (
                              <>
                                <Form.Group controlId="electricalAccessoriesAmount">
                                  <Form.Control
                                    className="floating-input"
                                    type="number"
                                    name="electricalAccessoriesAmount"
                                    value={
                                      formik.values.electricalAccessoriesAmount
                                    }
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      formik.setFieldValue(
                                        "electricalAccessoriesAmount",
                                        value
                                      );
                                      // Run validation manually after setting value
                                      formik.validateField(
                                        "electricalAccessoriesAmount"
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                    isInvalid={
                                      formik.touched
                                        .electricalAccessoriesAmount &&
                                      formik.errors.electricalAccessoriesAmount
                                    }
                                    min="0"
                                    max="1000000"
                                    placeholder="Enter amount"
                                  />
                                  {formik.touched.electricalAccessoriesAmount &&
                                    formik.errors
                                      .electricalAccessoriesAmount && (
                                      <Form.Control.Feedback type="invalid">
                                        {
                                          formik.errors
                                            .electricalAccessoriesAmount
                                        }
                                      </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                              </>
                            ) : (
                              // Render dropdown if dealId is not present
                              <InputFieldDropdown
                                formikFieldName="electricalAccessoriesAmount"
                                label=""
                                optionsArray={amountArray}
                                formik={formik}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      <div className="padding-left">
                        <span className="help-text">
                          A cover on electrical accessories fitted in your car.
                          Most insurer provide a cover for your car accessories
                          at an additional premium of 4% on its value.
                        </span>
                      </div>
                    </div>
                  )}
                  {formik.values.policyType !== "TP" && (
                    <div className="checkbox-row">
                      <div className="checkbox-input">
                        <InputFieldCheckBox
                          formik={formik}
                          formikFieldName="nonElectricalAccessories"
                          label="Non-Electrical"
                        />
                        {formik.values.nonElectricalAccessories && (
                          <div className="form-floating ">
                            {/* <InputFieldText
                              formikFieldName="nonElectricalAccessoriesAmount"
                              formik={formik}
                            /> */}

                            {dealId ? (
                              <>
                                <Form.Group controlId="nonElectricalAccessoriesAmount">
                                  <Form.Control
                                    className="floating-input"
                                    type="number"
                                    name="nonElectricalAccessoriesAmount"
                                    value={
                                      formik.values
                                        .nonElectricalAccessoriesAmount
                                    }
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      formik.setFieldValue(
                                        "nonElectricalAccessoriesAmount",
                                        value
                                      );
                                      // Run validation manually after setting value
                                      formik.validateField(
                                        "nonElectricalAccessoriesAmount"
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                    isInvalid={
                                      formik.touched
                                        .nonElectricalAccessoriesAmount &&
                                      formik.errors
                                        .nonElectricalAccessoriesAmount
                                    }
                                    min="0"
                                    max="200000"
                                    placeholder="Enter amount"
                                  />
                                  {formik.touched
                                    .nonElectricalAccessoriesAmount &&
                                    formik.errors
                                      .nonElectricalAccessoriesAmount && (
                                      <Form.Control.Feedback type="invalid">
                                        {
                                          formik.errors
                                            .nonElectricalAccessoriesAmount
                                        }
                                      </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                              </>
                            ) : (
                              // Render dropdown if dealId is not present
                              <InputFieldDropdown
                                formikFieldName="nonElectricalAccessoriesAmount"
                                label=""
                                optionsArray={amountArray}
                                formik={formik}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      <div className="padding-left">
                        <span className="help-text">
                          A cover on non-electrical accessories fitted in your
                          car. Most insurer provide a cover for your car
                          accessories at an additional premium of 4% on its
                          value.
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-center mt-4 footer-btn-wrap">
          <Button
            className="back-btn"
            onClick={() => {
              setriderStatus(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="primary-btn"
            onClick={() => {
              // console.log("gggg");
              //setriderStatus(false);
              formik.validateForm().then((errors) => {
                if (Object.keys(errors).length === 0) {
                  // No errors, change riderStatus
                  setriderStatus(false);
                }
              });
            }}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );

  const iciciPopForAdditionalCoverCar = (
    <>
      <Form className="additional-cover-form">
        <div className="inner-container">
          <div className="left-cover">
            {/* smart saver  */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="smartSaver"
                  label="Smart Saver"
                  smartSaverPlus
                />
                {formik.values.smartSaver && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="smartSaverPlan"
                      label=""
                      optionsArray={[
                        { value: "T+2*750", label: "T+2*750" },
                        { value: "T+2*500", label: "T+2*500" },
                      ]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides protection for repair or replacement costs
                  incurred due to damage to the insured vehicle's engine and
                  gearbox.
                </span>
              </div>
            </div>
            {/* PA cover */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  disabledValue={formik?.values?.validLicence}
                  // value={!formik.values?.validLicence}
                  // disabled={formik?.values?.validLicence}
                  formik={formik}
                  formikFieldName="personalAccidentCover"
                  label="PA Cover For Owner Driver"
                />
                {/* {formik.values.personalAccidentCover && (
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="driverAccidentCoverAmount"
                        label=""
                        optionsArray={[
                          { value: 10000, label: "10,000" },
                          { value: 20000, label: "20,000" },
                          { value: 30000, label: "30,000" },
                          { value: 40000, label: "40,000" },
                          { value: 50000, label: "50,000" },
                          { value: 60000, label: "60,000" },
                          { value: 70000, label: "70,000" },
                          { value: 80000, label: "80,000" },
                          { value: 90000, label: "90,000" },
                          { value: 100000, label: "1,00,000" },
                          { value: 120000, label: "1,20,000" },
                          { value: 130000, label: "1,30,000" },
                          { value: 140000, label: "1,40,000" },
                          { value: 150000, label: "1,50,000" },
                          { value: 160000, label: "1,60,000" },
                          { value: 170000, label: "1,70,000" },
                          { value: 180000, label: "1,80,000" },
                          { value: 190000, label: "1,90,000" },
                          { value: 200000, label: "2,00,000" },
                        ]}
                        formik={formik}
                      />
                    </div>
                  )} */}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  In case of any unfortunate accident, the add on will provides
                  coverage for any bodily injury or death up to 15 Lakh sum
                  insured
                </span>
              </div>
            </div>
            {/* Toggle PA cover */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="existingPersonalCoverFlag"
                  label="Already holds a PA cover of 15L Sum Insured"
                />
              </div>
              <div className="padding-left">
                <span className="help-text">
                  In case of existing PA cover is present
                </span>
              </div>
            </div>
            {/* unnamed passenger cover  */}
            <div className="checkbox-row">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="personalCoverFlag"
                  label="Unnamed Passenger Accident Cover"
                />
                {/* {formik.values.personalCoverFlag && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="personalCover"
                      label=""
                      optionsArray={[
                        { value: 50000, label: "50,000" },

                        { value: 100000, label: "1,00,000" },

                        { value: 150000, label: "1,50,000" },

                        { value: 200000, label: "2,00,000" },
                      ]}
                      formik={formik}
                    />
                  </div>
                )} */}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  In case of any unfortunate accident, the add on will provides
                  coverage for any bodily injury or death of Unnamed Passenger
                  Accident Cover up to 2 Lakh sum insured
                </span>
              </div>
            </div>
            {/* Paid employee */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="employeeAccidentCover"
                  label="Liability to Paid employee"
                />
                {formik.values.employeeAccidentCover && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="employeeAccidentCoversAmount"
                      label=""
                      optionsArray={[
                        { value: 1, label: "1" },
                        { value: 2, label: "2" },
                        { value: 3, label: "3" },
                        { value: 4, label: "4" },
                        { value: 5, label: "5" },
                      ]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides Liability to Paid employee.
                </span>
              </div>
            </div>
            {/* NCB */}
            <div className="checkbox-row mb-3">
              <InputFieldCheckBox
                formik={formik}
                formikFieldName="ncbProtection"
                label="NCB Protection"
              />
              {/* {formik?.values?.ncbProtection && (
                <div className="form-floating ">
                  <InputFieldDropdown
                    formikFieldName="ncbPlan"
                    label=""
                    optionsArray={[
                      { value: "plan1", label: "Plan 1" },
                      { value: "plan2", label: "Plan 2" },
                    ]}
                    formik={formik}
                  />
                </div>
              )} */}
              <div className="padding-left">
                <span className="help-text">
                  The add-on safeguards the earned discount on premiums by
                  preventing its reduction even after making a certain number of
                  claims.
                </span>
              </div>
            </div>
            {/* Zero Dep  */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="zeroDep"
                  label="Zero Depreciation cover"
                />
                {formik.values.zeroDep && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="zeroDepAmount"
                      label=""
                      optionsArray={[{ value: "zd", label: "ZD" }]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              {formik?.values?.zeroDep &&
                formContext.carbikeformikValues?.insuranceFor === "renew" && (
                  <>
                    <div
                      style={{ paddingTop: "20px" }}
                      className="padding-left"
                    >
                      <div className="form-floating ">
                        <InputFieldDropdown
                          formikFieldName="IsZeroDepriciation"
                          label="Addon opted in previous year policy?"
                          optionsArray={[
                            { value: "yes", label: "Yes" },
                            { value: "no", label: "No" },
                          ]}
                          formik={formik}
                        />
                        <br />
                      </div>
                    </div>
                  </>
                )}
              <div className="padding-left">
                <span className="help-text">
                  Also known as "nil depreciation" or "bumper-to-bumper"
                  insurance, provides full coverage for repair or replacement
                  costs without factoring the vehicle body part depreciation.
                </span>
              </div>
            </div>
            {/* Consumable */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="consumablesCover"
                  label="Consumables cover"
                />
              </div>
              {formik?.values?.consumablesCover &&
                formContext.carbikeformikValues?.insuranceFor === "renew" && (
                  <div style={{ paddingTop: "10px" }} className="padding-left">
                    <div className="form-floating ">
                      {/* <InputFieldCheckBox
                        formik={formik}
                        formikFieldName="IsConsumable"
                        label="Addon opted in previous year policy?"
                      /> */}
                      <InputFieldDropdown
                        formikFieldName="IsConsumable"
                        label="Addon opted in previous year policy?"
                        optionsArray={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        formik={formik}
                      />
                      <br />
                    </div>
                  </div>
                )}
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides protection for the reimbursement of
                  expenses related to consumable items, such as engine oil,
                  coolant, and nuts and bolts, incurred during repairs.
                </span>
              </div>
            </div>
            {/* Tyre */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="tyreProtection"
                  label="Tire Protection"
                />
              </div>
              {formik?.values?.tyreProtection &&
                formContext.carbikeformikValues?.insuranceFor === "renew" && (
                  <div style={{ paddingTop: "10px" }} className="padding-left">
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="IsTyreProtect"
                        label="Addon opted in previous year policy?"
                        optionsArray={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        formik={formik}
                      />
                      <br />
                    </div>
                  </div>
                )}
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides coverage for repair or replacement costs
                  of damaged tires.
                </span>
              </div>
            </div>
            {/* EME */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="emeCover"
                  label="EME Cover"
                />
                {formik.values.emeCover && (
                  <>
                    <br />
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="emeCoverNumber"
                        label="No. Of passenger"
                        optionsArray={[
                          { value: 1, label: "1" },
                          { value: 2, label: "2" },
                        ]}
                        formik={formik}
                      />
                    </div>
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="emeCoverAmount"
                        label="EME Cover"
                        optionsArray={[
                          { value: "EME-Standard", label: "EME-Standard" },
                        ]}
                        formik={formik}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides Emergency medical expenses.
                </span>
              </div>
            </div>
            {/* Diver accident cover  */}
            {/* <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="driverAccidentCover"
                  label="Driver Accident cover"
                />
                {formik.values.driverAccidentCover && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="driverAccidentCoverAmount"
                      label=""
                      optionsArray={[
                        { value: 10000, label: "10,000" },
                        { value: 20000, label: "20,000" },
                        { value: 30000, label: "30,000" },
                        { value: 40000, label: "40,000" },
                        { value: 50000, label: "50,000" },
                        { value: 60000, label: "60,000" },
                        { value: 70000, label: "70,000" },
                        { value: 80000, label: "80,000" },
                        { value: 90000, label: "90,000" },
                        { value: 100000, label: "1,00,000" },
                        { value: 120000, label: "1,20,000" },
                        { value: 130000, label: "1,30,000" },
                        { value: 140000, label: "1,40,000" },
                        { value: 150000, label: "1,50,000" },
                        { value: 160000, label: "1,60,000" },
                        { value: 170000, label: "1,70,000" },
                        { value: 180000, label: "1,80,000" },
                        { value: 190000, label: "1,90,000" },
                        { value: 200000, label: "2,00,000" },
                      ]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  In case of any unfortunate accident the add on will provides
                  coverage for any bodily injury or death of driver up to the
                  sum insured selected.
                </span>
              </div>
            </div> */}
            {/* emergency transport  */}
            {/* <div className="checkbox-row">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="transportHotelExpenses"
                  label="Emergency Transport and Hotel Expenses"
                />
                {formik.values.transportHotelExpenses && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="transportHotelExpensesAmount"
                      label=""
                      optionsArray={[
                        { value: 1000, label: "1000" },
                        { value: 2000, label: "2000" },
                      ]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides coverage for transportation and
                  accommodation costs in case of a breakdown or accident
                </span>
              </div>
            </div> */}
            {/* Garage Cash Cover */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="garageCash"
                  label="Garage Cash Cover"
                />
                {formik.values.garageCash && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="garageCashPlan"
                      label=""
                      optionsArray={[
                        {
                          value: "garageCashPlan",
                          label: "Rs. 500 per day for 14 days",
                        },
                      ]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides protection for repair or replacement costs
                  incurred due to damage to the insured vehicle's engine and
                  gearbox.
                </span>
              </div>
            </div>
          </div>

          {/* ---------------------------------------------------------------------- */}

          <div className="right-cover">
            {/* smart saver plus  */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="smartSaverPlus"
                  label="Smart Saver Plus"
                />
                {formik.values.smartSaverPlus && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="smartSaverPlusPlan"
                      label=""
                      optionsArray={[
                        { value: "smartSaverPlus", label: "Smart Saver Plus" },
                      ]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides protection for repair or replacement costs
                  incurred due to damage to the insured vehicle's engine and
                  gearbox.
                </span>
              </div>
            </div>
            {/* valid Licence */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="validLicence"
                  label="Does not have a valid license"
                />
              </div>
              <div className="padding-left">
                <span className="help-text">
                  PA Cover for OD gets neglected
                </span>
              </div>
            </div>
            {/* Key Protect */}
            <div className="checkbox-row">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="keyProtection"
                  label="Key Protection"
                />
                {formik?.values?.keyProtection && (
                  <InputFieldDropdown
                    formikFieldName="keyProtectionAmount"
                    label=""
                    optionsArray={[
                      { value: "KP1", label: "KP1" },
                      { value: "KP2", label: "KP2" },
                      { value: "KP3", label: "KP3" },
                      { value: "KP8", label: "KP8" },
                    ]}
                    formik={formik}
                  />
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides coverage for the repair or replacement of
                  lost, stolen, or damaged vehicle keys, including the
                  associated costs of locksmith services
                </span>
              </div>
            </div>
            {/* paid Driver  */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="liabilityToPaidDriver"
                  label="Liability to Paid Driver"
                />
                {formik.values.liabilityToPaidDriver && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="liabilityToPaidDriverAmount"
                      label=""
                      optionsArray={[
                        { value: 1, label: "1" },
                        { value: 2, label: "2" },
                      ]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides protection for repair or replacement costs
                  incurred due to damage to the insured vehicle's engine and
                  gearbox.
                </span>
              </div>
            </div>
            {/* Voluntary Deductable */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="voluntaryDeductableCover"
                  label="Voluntary Deductable Cover"
                />
                {formik.values.voluntaryDeductableCover && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="voluntaryDeductableCoverAmount"
                      label=""
                      optionsArray={[
                        { value: 5000, label: "5000" },
                        { value: 5500, label: "5500" },
                        { value: 3500, label: "3500" },
                        { value: 6500, label: "6500" },
                        { value: 1499, label: "1499" },
                        { value: 10000, label: "10000" },
                        { value: 4000, label: "4000" },
                        { value: 17500, label: "17500" },
                        { value: 8000, label: "8000" },
                        { value: 15000, label: "15000" },
                        { value: 1999, label: "1999" },
                        { value: 20000, label: "20000" },
                        { value: 7500, label: "7500" },
                        { value: 2000, label: "2000" },
                        { value: 7499, label: "7499" },
                        { value: 1500, label: "1500" },
                        { value: 2000, label: "2000" },
                        { value: 7499, label: "7499" },
                        { value: 1500, label: "1500" },
                        { value: 2499, label: "2499" },
                        { value: 4999, label: "4999" },
                        { value: 1000, label: "1000" },
                        { value: 2500, label: "2500" },
                        { value: 4500, label: "4500" },
                        { value: 12500, label: "12500" },
                        { value: 15500, label: "15500" },
                      ]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  In case of any unfortunate accident the add on will provides
                  coverage for any bodily injury or death of driver up to the
                  sum insured selected.
                </span>
              </div>
            </div>
            {/* RSA  */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="roadSideAssistance"
                  label="Roadside Assistance"
                />
                {formik.values.roadSideAssistance && (
                  <div className="form-floating ">
                    <InputFieldDropdown
                      formikFieldName="roadSideAssistancePlan"
                      label=""
                      optionsArray={[
                        { value: "RSA-Standard", label: "RSA-Standard" },
                      ]}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
              <div className="padding-left">
                <span className="help-text">
                  In the event of vehicle breakdowns or emergencies, the add on
                  will provide assistance and support services to policyholders.
                </span>
              </div>
            </div>
            {/* RTI  */}
            <div className="checkbox-row">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="invoiceCover"
                  label="Return to Invoice"
                />
              </div>
              {formik?.values?.invoiceCover &&
                formContext.carbikeformikValues?.insuranceFor === "renew" && (
                  <div style={{ paddingTop: "10px" }} className="padding-left">
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="IsRTI"
                        label="Addon opted in previous year policy?"
                        optionsArray={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        formik={formik}
                      />
                      <br />
                      {/* <InputFieldCheckBox
                        formik={formik}
                        formikFieldName="IsRTI"
                        label="Addon opted in previous year policy?"
                      /> */}
                    </div>
                  </div>
                )}
              <div className="padding-left">
                <span className="help-text">
                  In the event of a total loss or theft of the insured vehicle,
                  the policyholder receives the original invoice value of the
                  vehicle.
                </span>
              </div>
            </div>
            {/* Engine protect plus  */}
            <div className="checkbox-row mb-3">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="engineProtector"
                  label="Engine and Gearbox Protection"
                />
              </div>
              {formik?.values?.engineProtector &&
                formContext.carbikeformikValues?.insuranceFor === "renew" && (
                  <div style={{ paddingTop: "10px" }} className="padding-left">
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="IsEngineProtect"
                        label="Addon opted in previous year policy?"
                        optionsArray={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        formik={formik}
                      />
                      <br />
                    </div>
                  </div>
                )}
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides protection for repair or replacement costs
                  incurred due to damage to the insured vehicle's engine and
                  gearbox.
                </span>
              </div>
            </div>
            {/* battery protect  */}
            <div className="checkbox-row mb-3">
              <InputFieldCheckBox
                formik={formik}
                formikFieldName="batteryProtectCover"
                label="Battery Protect Cover"
              />
              <div className="padding-left">
                <span className="help-text">
                  The add-on provides protection for repair or replacement costs
                  incurred due to damage to the insured vehicle's Battery
                </span>
              </div>
            </div>
            {/* accessories  */}
            <div className="checkbox-row accessories-wrap">
              <div className="accessories">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="accessories"
                  label="Accessories"
                />
                <div className="padding-left">
                  <span className="help-text">
                    Additional accessories will not be a part of standard
                    policy, protect your additional accessories under the
                    accessories add on.
                  </span>
                </div>
              </div>
              {formik.values.accessories && (
                <div className="accessories-child">
                  {formik.values.policyType !== "TP" && (
                    <div className="checkbox-row mt-3">
                      <div className="checkbox-input">
                        <InputFieldCheckBox
                          formik={formik}
                          formikFieldName="electricalAccessories"
                          label="Electrical"
                        />
                        {formik.values.electricalAccessories && (
                          <div className="form-floating ">
                            {/* <InputFieldText
                              formikFieldName="electricalAccessoriesAmount"
                              formik={formik}
                            /> */}
                            {dealId ? (
                              <>
                                <Form.Group controlId="electricalAccessoriesAmount">
                                  <Form.Control
                                    className="floating-input"
                                    type="number"
                                    name="electricalAccessoriesAmount"
                                    value={
                                      formik.values.electricalAccessoriesAmount
                                    }
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      formik.setFieldValue(
                                        "electricalAccessoriesAmount",
                                        value
                                      );
                                      // Run validation manually after setting value
                                      formik.validateField(
                                        "electricalAccessoriesAmount"
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                    isInvalid={
                                      formik.touched
                                        .electricalAccessoriesAmount &&
                                      formik.errors.electricalAccessoriesAmount
                                    }
                                    min="0"
                                    max="1000000"
                                    placeholder="Enter amount"
                                  />
                                  {formik.touched.electricalAccessoriesAmount &&
                                    formik.errors
                                      .electricalAccessoriesAmount && (
                                      <Form.Control.Feedback type="invalid">
                                        {
                                          formik.errors
                                            .electricalAccessoriesAmount
                                        }
                                      </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                              </>
                            ) : (
                              // Render dropdown if dealId is not present
                              <InputFieldDropdown
                                formikFieldName="electricalAccessoriesAmount"
                                label=""
                                optionsArray={amountArray}
                                formik={formik}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      <div className="padding-left">
                        <span className="help-text">
                          A cover on electrical accessories fitted in your car.
                          Most insurer provide a cover for your car accessories
                          at an additional premium of 4% on its value.
                        </span>
                      </div>
                    </div>
                  )}
                  {formik.values.policyType !== "TP" && (
                    <div className="checkbox-row">
                      <div className="checkbox-input">
                        <InputFieldCheckBox
                          formik={formik}
                          formikFieldName="nonElectricalAccessories"
                          label="Non-Electrical"
                        />
                        {formik.values.nonElectricalAccessories && (
                          <div className="form-floating ">
                            {/* <InputFieldText
                              formikFieldName="nonElectricalAccessoriesAmount"
                              formik={formik}
                            /> */}

                            {dealId ? (
                              <>
                                <Form.Group controlId="nonElectricalAccessoriesAmount">
                                  <Form.Control
                                    className="floating-input"
                                    type="number"
                                    name="nonElectricalAccessoriesAmount"
                                    value={
                                      formik.values
                                        .nonElectricalAccessoriesAmount
                                    }
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      formik.setFieldValue(
                                        "nonElectricalAccessoriesAmount",
                                        value
                                      );
                                      // Run validation manually after setting value
                                      formik.validateField(
                                        "nonElectricalAccessoriesAmount"
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                    isInvalid={
                                      formik.touched
                                        .nonElectricalAccessoriesAmount &&
                                      formik.errors
                                        .nonElectricalAccessoriesAmount
                                    }
                                    min="0"
                                    max="200000"
                                    placeholder="Enter amount"
                                  />
                                  {formik.touched
                                    .nonElectricalAccessoriesAmount &&
                                    formik.errors
                                      .nonElectricalAccessoriesAmount && (
                                      <Form.Control.Feedback type="invalid">
                                        {
                                          formik.errors
                                            .nonElectricalAccessoriesAmount
                                        }
                                      </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                              </>
                            ) : (
                              // Render dropdown if dealId is not present
                              <InputFieldDropdown
                                formikFieldName="nonElectricalAccessoriesAmount"
                                label=""
                                optionsArray={amountArray}
                                formik={formik}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      <div className="padding-left">
                        <span className="help-text">
                          A cover on non-electrical accessories fitted in your
                          car. Most insurer provide a cover for your car
                          accessories at an additional premium of 4% on its
                          value.
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* accessories lpg-cng kit  */}
            <div className="checkbox-row">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="lpgCngKit"
                  label="Accessories-LPG/CNG Kit"
                />
              </div>
              {formik.values.lpgCngKit && (
                <>
                  <div className="checkbox-input padding-left">
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="lpgCngKitPlan"
                        label=""
                        optionsArray={[
                          { value: 1, label: "LPG" },
                          { value: 2, label: "CNG" },
                          { value: 3, label: "Factory Fitted CNG" },
                          { value: 4, label: "Factory Fitted LPG" },
                        ]}
                        formik={formik}
                      />
                    </div>
                    <div className="form-floating ">
                      <Form.Group controlId="lpgCngKitAmount">
                        <Form.Control
                          className="floating-input"
                          type="number"
                          name="lpgCngKitAmount"
                          value={formik.values.lpgCngKitAmount}
                          onChange={(e) => {
                            const { value } = e.target;
                            formik.setFieldValue("lpgCngKitAmount", value);
                            // Run validation manually after setting value
                            formik.validateField("lpgCngKitAmount");
                          }}
                          onBlur={formik.handleBlur}
                          isInvalid={formik.errors.lpgCngKitAmount}
                          placeholder="Enter amount"
                        />
                        {formik.touched.lpgCngKitAmount &&
                          formik.errors.lpgCngKitAmount && (
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.lpgCngKitAmount}
                            </Form.Control.Feedback>
                          )}
                      </Form.Group>
                    </div>
                  </div>
                </>
              )}

              <div className="padding-left">
                <span className="help-text">
                  External fitted LPG-CNG kit will not be the part of your
                  selected IDV. this add on will provide the coverage to your
                  external fitted LPG-CNG Kit.
                </span>
              </div>
            </div>
            {/* loss of bagage  */}
            <div className="checkbox-row mb-3">
              <InputFieldCheckBox
                formik={formik}
                formikFieldName="baggageCover"
                label="Loss of Baggage Cover"
              />
              <div className="padding-left">
                <span className="help-text">
                  In case of any unfortunate accident, the add on will provides
                  coverage for any bodily injury or death of driver up to the
                  Sum Insured selected
                </span>
              </div>
            </div>
            {/* emi protect  */}
            <div className="checkbox-row">
              <div className="checkbox-input">
                <InputFieldCheckBox
                  formik={formik}
                  formikFieldName="emiProtect"
                  label="EMI Protect Plan for Individual"
                />
              </div>
              {formik.values.emiProtect && (
                <>
                  <div className="checkbox-input padding-left">
                    <div className="form-floating ">
                      <InputFieldDropdown
                        formikFieldName="emiCount"
                        label="Number Of EMI"
                        optionsArray={[
                          { value: 1, label: "1" },
                          { value: 2, label: "2" },
                        ]}
                        formik={formik}
                      />
                    </div>
                    <div className="form-floating ">
                      <Form.Group controlId="emiAmount">
                        <Form.Control
                          className="floating-input"
                          type="number"
                          name="emiAmount"
                          value={formik.values.emiAmount}
                          onChange={(e) => {
                            const { value } = e.target;
                            formik.setFieldValue("emiAmount", value);
                            // Run validation manually after setting value
                            formik.validateField("emiAmount");
                          }}
                          onBlur={formik.handleBlur}
                          isInvalid={formik.errors.emiAmount}
                          min="0"
                          max="9999999"
                          placeholder="Enter EMI amount"
                        />
                        {formik.errors.emiAmount && (
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.emiAmount}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </>
              )}

              <div className="padding-left">
                <span className="help-text">
                  External fitted LPG-CNG kit will not be the part of your
                  selected IDV. this add on will provide the coverage to your
                  external fitted LPG-CNG Kit.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-4 footer-btn-wrap">
          <Button
            className="back-btn"
            onClick={() => {
              // console.log("gggg");
              //setriderStatus(false);
              formik.validateForm().then((errors) => {
                if (Object.keys(errors).length === 0) {
                  // No errors, change riderStatus
                  setriderStatus(false);
                }
              });
            }}
          >
            Cancel
          </Button>
          <Button
            className="primary-btn"
            onClick={() => {
              // console.log("gggg");
              //setriderStatus(false);
              formik.validateForm().then((errors) => {
                if (Object.keys(errors).length === 0) {
                  // No errors, change riderStatus
                  setriderStatus(false);
                }
              });
            }}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );

  const popIndividual = (
    <>
      <Form className="indiviual-form">
        <InputFieldRadio
          formikFieldName="ownedBy"
          optionsArray={[
            { value: "Individual", label: "Individual", name: "Individual" },
            {
              value: "Organization",
              label: "Organization",
              name: "Organization",
            },
          ]}
          formik={formik}
        />
        <div className="text-center mt-4 footer-btn-wrap">
          <Button
            className="back-btn"
            onClick={() => {
              setownedByPopup(false);
              formik.setFieldValue("ownedBy", ownedByPopuppreviousValue);
            }}
          >
            Cancel
          </Button>
          <Button
            className="primary-btn"
            onClick={() => {
              setownedByPopup(false);
            }}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );

  const popForOd = (
    <>
      <Form>
        <div className="inner-form">
          <InputFieldText
            formikFieldName="tpPolicyStartDate"
            placeholder="TP Policy Start Date "
            formik={formik}
            type="date"
          />
          <InputFieldText
            formikFieldName="tpPolicyEndtDate"
            placeholder="TP Policy End Date "
            formik={formik}
            type="date"
          />
          {errorForOdPopup && (
            <div style={{ color: "red" }}>{errorMessageForOdPopup}</div>
          )}
          <div className="text-center footer-btn-wrap">
            <Button
              className="back-btn"
              onClick={() => {
                odPopupClose(1);
              }}
            >
              Cancel
            </Button>
            <Button
              className="primary-btn"
              onClick={() => {
                odPopupClose(2);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </>
  );

  const expiryDateSpit = formik.values.expiryDate.split("-");
  const expiryDate =
    expiryDateSpit[2] + "/" + expiryDateSpit[1] + "/" + expiryDateSpit[0];
  const quotePremium = (
    <div>
      <div className="premium-container" ref={ref}>
        <div className="topSection">
          <div className="img-wrap">
            <img
              src={
                imgArrayForCard?.[
                  formContext.singleQuotesData?.insuranceCompany
                ]
              }
              alt=""
            />
          </div>
          <div className="premium-amount-wrap">
            <div className="premium-amount-box">
              <span className="fieldset">Idv</span>
              <h3>₹ {formContext.singleQuotesData?.insuredDeclareValue}</h3>
            </div>
          </div>
          <div className="premium-amount-wrap">
            <div className="premium-amount-box">
              <span className="fieldset">Premium</span>
              <h3>₹ {Number(netPremiumForBrekupvalue?.premium)?.toFixed(0)}</h3>
            </div>
            <span className="help-text">Exclusive of GST</span>
          </div>
        </div>
        <p>{formContext.singleQuotesData?.insuranceCompany}</p>
        <div className="topSection">
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Row>
                <Col>
                  <Card.Text>
                    <span className="help-text">Name : </span>
                    <span>
                      {formContext.carbikeformikValues?.firstName +
                        " " +
                        formContext.carbikeformikValues?.lastName}
                    </span>
                  </Card.Text>
                </Col>

                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">Registration Number : </span>
                    <span>{formik?.values?.registeredRto}</span>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">Plan Type: </span>
                    <span>
                      {formik.values.policyType === "OD-TP"
                        ? "Comprehensive"
                        : formik.values.policyType === "OD"
                        ? "Own Damage only"
                        : formik.values.policyType === "TP"
                        ? "Third Party Only"
                        : ""}
                    </span>
                  </Card.Text>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">Make : </span>
                    <span>{formik.values.make}</span>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">Model : </span>
                    <span>{formik.values.model}</span>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">Varient : </span>
                    <span>{formik.values.varient}</span>
                  </Card.Text>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">Fuel : </span>
                    <span>{formik.values.fuel}</span>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">Policy Expiry Date : </span>
                    <span type="date">{expiryDate}</span>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text></Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
        <br></br>

        <div className="tab-section">
          <div className="tabs">
            <span className="tab-text">Premium Details</span>
          </div>
          <div className="tab-content">
            {netPremiumForBrekupvalue?.odpremium !== 0 && (
              <div className="tab-row">
                <p>OD premium</p>
                {typeof netPremiumForBrekupvalue?.odpremium === "number" && (
                  <p>
                    ₹ {Number(netPremiumForBrekupvalue?.odpremium)?.toFixed(0)}
                  </p>
                )}
                {typeof netPremiumForBrekupvalue?.odpremium === "string" && (
                  <p>
                    ₹{" "}
                    {Number(
                      netPremiumForBrekupvalue?.odpremium.replace("INR", "")
                    )?.toFixed(0)}
                  </p>
                )}
              </div>
            )}
            {formContext.singleQuotesData?.insuranceCompany ===
            "New India Assurance"
              ? formContext.singleQuotesData?.tppremium !== 0 && (
                  <div className="tab-row">
                    <p>TP premium</p>
                    {typeof formContext.singleQuotesData?.tppremium ===
                      "number" && (
                      <p>
                        ₹{" "}
                        {Number(
                          formContext.singleQuotesData?.tppremium
                        )?.toFixed(0) *
                          (formContext.singleQuotesData?.quoteType === "3"
                            ? Number(formik?.values?.policyTerms)
                            : Number(formik?.values?.policyTerms) === 3
                            ? 5
                            : Number(formik?.values?.policyTerms))}
                      </p>
                    )}
                    {typeof formContext.singleQuotesData?.tppremium ===
                      "string" && (
                      <p>
                        ₹{" "}
                        {Number(
                          formContext.singleQuotesData?.tppremium.replace(
                            "INR",
                            ""
                          )
                        )?.toFixed(0) *
                          (formContext.singleQuotesData?.quoteType === "3"
                            ? Number(formik?.values?.policyTerms)
                            : Number(formik?.values?.policyTerms) === 3
                            ? 5
                            : Number(formik?.values?.policyTerms))}
                      </p>
                    )}
                  </div>
                )
              : formContext.singleQuotesData?.tppremium !== 0 && (
                  <div className="tab-row">
                    <p>TP premium</p>
                    {typeof formContext.singleQuotesData?.tppremium ===
                      "number" && (
                      <p>
                        ₹{" "}
                        {Number(
                          formContext.singleQuotesData?.tppremium
                        )?.toFixed(0)}
                      </p>
                    )}
                    {typeof formContext.singleQuotesData?.tppremium ===
                      "string" && (
                      <p>
                        ₹{" "}
                        {Number(
                          formContext.singleQuotesData?.tppremium.replace(
                            "INR",
                            ""
                          )
                        )?.toFixed(0)}
                      </p>
                    )}
                  </div>
                )}

            {formContext.singleQuotesData?.insuranceCompany !==
              "Future Generali India Insurance Co. Ltd" &&
              formContext.singleQuotesData?.ridersList?.map((mapdata) => {
                // Check if riderValue is non-zero and valid
                if (
                  mapdata?.riderValue &&
                  mapdata?.riderValue !== 0 &&
                  mapdata?.riderValue !== "0" &&
                  mapdata?.riderValue !== null &&
                  mapdata?.riderValue !== undefined
                ) {
                  return (
                    <div className="tab-row" key={mapdata.riderId}>
                      <p>{mapdata?.riderName}</p>
                      {mapdata?.riderValue?.toString().includes("INR") ? (
                        <p>
                          ₹{" "}
                          {Number(
                            mapdata?.riderValue.replace("INR", "")
                          ).toFixed(0)}
                        </p>
                      ) : (
                        <p>
                          {mapdata?.riderName ===
                            "Voluntary Deductable Cover" && "-"}{" "}
                          ₹ {Number(mapdata?.riderValue).toFixed(0)}
                        </p>
                      )}
                    </div>
                  );
                }
                return null; // Return null if riderValue is 0
              })}
            {formContext.singleQuotesData?.insuranceCompany ===
              "Reliance General Insurance Co. Ltd" &&
              formContext.singleQuotesData?.InspectionCharges !== "0.0" &&
              formContext.singleQuotesData?.quoteType === 2 &&
              formContext.singleQuotesData?.InspectionCharges && (
                <div className="tab-row">
                  <p>Inspection Charges</p>
                  <p>
                    ₹{" "}
                    {Number(
                      formContext.singleQuotesData?.InspectionCharges
                    )?.toFixed(0)}
                  </p>
                </div>
              )}
            {formContext.singleQuotesData?.insuranceCompany ===
              "Future Generali India Insurance Co. Ltd" &&
              formContext.singleQuotesData?.quoteType === 3 && (
                <div className="tab-row">
                  <p>{riderNameForFuture}</p>
                  <p>₹ {riderAmoutForFuture}</p>
                </div>
              )}
            {formContext.singleQuotesData?.insuranceCompany ===
              "Future Generali India Insurance Co. Ltd" &&
              formContext.singleQuotesData?.quoteType !== 3 &&
              formContext.singleQuotesData?.ridersList?.map((mapdata) => {
                return (
                  <>
                    {mapdata?.riderValue && (
                      <div className="tab-row">
                        <p>{mapdata?.riderName}</p>
                        {mapdata?.riderValue?.length > 0 &&
                        mapdata?.riderValue?.includes("INR") ? (
                          <p>
                            ₹{" "}
                            {Number(
                              mapdata?.riderValue?.replace("INR", "")
                            )?.toFixed(0)}
                          </p>
                        ) : (
                          <p>₹ {Number(mapdata?.riderValue)?.toFixed(0)}</p>
                        )}
                      </div>
                    )}
                  </>
                );
              })}

            {formContext.carbikeformikValues?.formtype === "bike"
              ? formContext.singleQuotesData?.discountList?.map(
                  (mapdataNcb) => {
                    const isDiscount = mapdataNcb?.type?.includes("Discount");
                    const sign = isDiscount ? "-" : "";
                    // If the type is undefined, skip rendering
                    if (!mapdataNcb?.type) {
                      return null;
                    } else if (!mapdataNcb?.discountAmount) {
                      return null;
                    } else {
                      // If the conditions for skipping the discount are met, skip rendering
                      if (
                        formContext.singleQuotesData?.insuranceCompany ===
                          "New India Assurance" &&
                        mapdataNcb?.type === "OD Discount" &&
                        formik.values.policyTerms !== "1" &&
                        (formik.values.policyTerms === "2" ||
                          formik.values.policyTerms === "3")
                      ) {
                        let data = mapdataNcb?.discountAmount?.reduce(
                          (acc, curr) => acc + curr,
                          0
                        );

                        console.log("in od for new india", data);
                        return (
                          <div className="tab-row" key={mapdataNcb?.type}>
                            <p>{mapdataNcb?.type}</p>
                            <p>
                              {" "}
                              {sign} ₹{data?.toFixed(0)}
                            </p>
                          </div>
                        );
                      } else if (
                        formContext.singleQuotesData?.insuranceCompany ===
                          "Future Generali India Insurance Co. Ltd" &&
                        mapdataNcb?.type === "NCB Discount"
                      ) {
                        return (
                          <div className="tab-row" key={mapdataNcb?.type}>
                            <p>
                              {mapdataNcb?.type} {formik?.values?.noClaimBonus}%
                            </p>
                            <p>
                              {" "}
                              {sign} ₹
                              {Number(mapdataNcb?.discountAmount)?.toFixed(0)}
                            </p>
                          </div>
                        );
                      } else {
                        // Render the discount item
                        return (
                          <div className="tab-row" key={mapdataNcb?.type}>
                            <p>{mapdataNcb?.type}</p>
                            <p>
                              {" "}
                              {sign} ₹
                              {Number(mapdataNcb?.discountAmount)?.toFixed(0)}
                            </p>
                          </div>
                        );
                      }
                    }
                  }
                )
              : formContext.singleQuotesData?.discountList?.map(
                  (mapdataNcb) => {
                    const isDiscount = mapdataNcb?.type?.includes("Discount");
                    const sign = isDiscount ? "-" : "";

                    // If the type is undefined, skip rendering
                    if (!mapdataNcb?.type) {
                      return null;
                    } else if (!mapdataNcb?.discountAmount) {
                      return null;
                    } else {
                      // If the conditions for skipping the discount are met, skip rendering
                      if (
                        (formContext.singleQuotesData?.insuranceCompany ===
                          "Reliance General Insurance Co. Ltd" ||
                          formContext.singleQuotesData?.insuranceCompany ===
                            "New India Assurance" ||
                          formContext.singleQuotesData?.insuranceCompany ===
                            "Future Generali India Insurance Co. Ltd") &&
                        mapdataNcb?.type === "OD Discount"
                      ) {
                        return null;
                      } else if (
                        formContext.singleQuotesData?.insuranceCompany ===
                          "Future Generali India Insurance Co. Ltd" &&
                        mapdataNcb?.type === "NCB Discount"
                      ) {
                        return (
                          <div className="tab-row" key={mapdataNcb?.type}>
                            <p>
                              {mapdataNcb?.type} {formik?.values?.noClaimBonus}%
                            </p>
                            <p>
                              {" "}
                              {sign} ₹
                              {Number(mapdataNcb?.discountAmount)?.toFixed(0)}
                            </p>
                          </div>
                        );
                      } else {
                        // Render the discount item
                        return (
                          <div className="tab-row" key={mapdataNcb?.type}>
                            <p>{mapdataNcb?.type}</p>
                            <p>
                              {" "}
                              {sign} ₹
                              {Number(mapdataNcb?.discountAmount)?.toFixed(0)}
                            </p>
                          </div>
                        );
                      }
                    }
                  }
                )}

            <div className="tab-row borderTop ">
              <p className="font-weight-bold">Net premium</p>
              <p className="font-weight-bold">
                ₹ {Number(netPremiumForBrekupvalue?.premium)?.toFixed(0)}
              </p>
            </div>

            <div className="tab-row">
              <p>GST 18% </p>
              <p>
                ₹ {Number(formContext.singleQuotesData?.serviceTax)?.toFixed(0)}
              </p>
            </div>
            <div className="tab-row borderTop total-wrap">
              <p>Total Premium</p>
              <p>
                ₹{" "}
                {Number(formContext.singleQuotesData?.grossPremium)?.toFixed(0)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-3">
        <button
          className="primary-btn"
          onClick={() =>
            generatePDF(ref, { filename: "PremiumBreakpoints.pdf" })
          }
        >
          Download PDF
        </button>
      </div>
    </div>
  );

  const popShareQuotes = (
    <div className="Demo__container share-quote-content">
      <div className="Demo__some-network social-icon" title="Email">
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body="body"
          className="Demo__some-network__share-button"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>

      <div
        className="Demo__some-network "
        onClick={closeShareQuptes}
        title="Whatsapp"
      >
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className="Demo__some-network__share-button"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Container fluid className="get-quotes-wrap">
        <Row className="first-row">
          <Col sm={12} md={12}>
            <Form className="" onSubmit={formik.handleSubmit}>
              <Row>
                <Col md={3} sm={12}>
                  <div className="addlink">
                    {formContext.secondLoaderStatus && (
                      <span>{formContext.spinner}</span>
                    )}
                  </div>
                  <InputFieldTextRTO
                    isTextVisible={isTextVisible}
                    handleSpanClick={handleSpanClick}
                    formikFieldName="registeredRto"
                    placeholder="MH01AAXXXX"
                    formik={formik}
                    showPopup={() => {
                      if (isTextVisible) {
                        formContext.setModalShow(true);
                        registeredRtoInputRef.current.blur();
                      }
                    }}
                    inputRef={registeredRtoInputRef}
                    capitalize={true}
                  />
                  <VerticallyCenteredModal
                    show={formContext.modalShow}
                    onHide={() => formContext.setModalShow(false)}
                    heading="Let's locate your registration office"
                  >
                    {formContext.popupForRto}
                  </VerticallyCenteredModal>
                  <ErrorMessage formik={formik} fieldValue="registeredRto" />
                </Col>
                <Col md={3} sm={12}>
                  <InputFieldText
                    formikFieldName="make"
                    placeholder="Make*"
                    formik={formik}
                    capitalize={true}
                    autoComplete="off"
                  />
                  <AutoSuggestFile
                    filteredData={formContext.makeFilterData}
                    formik={formik}
                    formikFieldName="make"
                    formContext={formContext}
                  />
                  <ErrorMessage formik={formik} fieldValue="make" />
                </Col>
                <Col md={3} sm={12}>
                  <InputFieldText
                    formikFieldName="model"
                    placeholder="Model*"
                    formik={formik}
                    capitalize={true}
                    autoComplete="off"
                  />
                  <AutoSuggestFile
                    filteredData={formContext.modelFilterData}
                    formik={formik}
                    formikFieldName="model"
                    formContext={formContext}
                  />
                  <ErrorMessage formik={formik} fieldValue="model" />
                </Col>

                <Col md={3} sm={12}>
                  <InputFieldText
                    formikFieldName="varient"
                    placeholder="Variant*"
                    formik={formik}
                    capitalize={true}
                    autoComplete="off"
                  />
                  <AutoSuggestFile
                    filteredData={formContext.varientFilterData}
                    formik={formik}
                    formikFieldName="varient"
                    formContext={formContext}
                  />
                  <ErrorMessage formik={formik} fieldValue="varient" />
                </Col>
              </Row>
              <Row>
                <Col md={3} sm={12}>
                  {formContext.carbikeformikValues?.insuranceFor === "renew" ? (
                    <InputFieldText
                      formikFieldName="registrationYear"
                      placeholder={`Registration Year*`}
                      formik={formik}
                      type="date"
                    />
                  ) : (
                    <InputFieldDropdown
                      formikFieldName="registrationYear"
                      label="Manufacturing Year*"
                      optionsArray={ManufacturingDateArray}
                      formik={formik}
                    />
                  )}
                  <ErrorMessage formik={formik} fieldValue="registrationYear" />
                </Col>
                {formContext.carbikeformikValues?.formtype === "car" && (
                  <Col md={3} sm={12}>
                    <InputFieldDropdown
                      formikFieldName="fuel"
                      label="Fuel"
                      optionsArray={[
                        { value: "petrol", label: "Petrol" },
                        { value: "diesel", label: "Diesel" },
                        { value: "lpg/cng", label: "LPG/CNG" },
                        { value: "electric", label: "Electric" },
                        { value: "cng", label: "CNG" },
                        { value: "hybrid", label: "Hybrid" },
                        { value: "lpg", label: "LPG" },
                        { value: "petrol+cng", label: "Petrol+CNG" },
                        { value: "petrol+lpg", label: "Petrol+LPG" },
                      ]}
                      formik={formik}
                    />
                    <ErrorMessage formik={formik} fieldValue="fuel" />
                  </Col>
                )}
                {formContext.carbikeformikValues?.insuranceFor === "renew" && (
                  <Col md={3} sm={12}>
                    <InputFieldText
                      type="date"
                      formikFieldName="expiryDate"
                      placeholder="Previous policy expiry date"
                      formik={formik}
                    />
                    <ErrorMessage formik={formik} fieldValue="expiryDate" />
                  </Col>
                )}
                {formContext.carbikeformikValues?.insuranceFor === "renew" && (
                  <Col md={3} sm={12}>
                    <InputFieldDropdown
                      formikFieldName="insuranceClaim"
                      label="Claim in last 12 months"
                      optionsArray={[
                        { value: "true", label: "Yes" },
                        { value: "false", label: "No" },
                      ]}
                      formik={formik}
                    />
                    <ErrorMessage formik={formik} fieldValue="insuranceClaim" />
                  </Col>
                )}
                {formik.values?.insuranceClaim === "false" &&
                  formContext.carbikeformikValues?.insuranceFor === "renew" && (
                    <Col md={3} sm={12}>
                      <InputFieldDropdown
                        formikFieldName="noClaimBonus"
                        label="No claim bonus in existing policy"
                        optionsArray={[
                          { value: "0", label: "0%" },
                          { value: "20", label: "20%" },
                          { value: "25", label: "25%" },
                          { value: "35", label: "35%" },
                          { value: "45", label: "45%" },
                          { value: "50", label: "50%" },
                        ]}
                        formik={formik}
                      />
                      <ErrorMessage formik={formik} fieldValue="noClaimBonus" />
                    </Col>
                  )}
                <Col sm={12} className="policyWrap quotes-radio-group mb-3">
                  <div className="radio-container">
                    <div className="me-5">
                      <p className="heading">Policy type</p>
                      <InputFieldRadio
                        formikFieldName="policyType"
                        optionsArray={arrayForPolicyType}
                        formik={formik}
                      />
                      <ErrorMessage formik={formik} fieldValue="policyType" />
                    </div>
                    {formContext.carbikeformikValues?.insuranceFor ===
                      "renew" &&
                      formContext.carbikeformikValues?.formtype !== "car" && (
                        <div>
                          <p className="heading">Policy Terms</p>
                          <InputFieldRadio
                            formikFieldName="policyTerms"
                            optionsArray={[
                              {
                                value: "1",
                                label: "1 Year",
                              },
                              {
                                value: "2",
                                label: "2 Year",
                              },
                              {
                                value: "3",
                                label: "3 Year",
                              },
                            ]}
                            formik={formik}
                          />
                          <ErrorMessage
                            formik={formik}
                            fieldValue="policyTerms"
                          />
                        </div>
                      )}
                  </div>
                  {isFormDirty && (
                    <div className="text-center mt-4 footer-btn-wrap">
                      <Button className="back-btn" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button
                        className="primary-btn"
                        onClick={handleSave}
                        // disabled={loaderForQuotes}
                      >
                        {loaderForQuotes ? "Please wait" : "Save"}
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row className="">
          <div className="second-row d-flex align-items-center flex-wrap justify-content-between bg-color">
            <div className="d-flex gap-md-4 gap-sm-3 gap-2 align-items-center flex-wrap">
              <div>
                <Button
                  onClick={() => setidvPopupStatus(true)}
                  className="select-btn"
                >
                  IDV-Min IDV <SVG src={Arrow} alt="" className="down-arrow" />
                </Button>
              </div>
              {formContext.carbikeformikValues?.formtype === "car" && (
                <div>
                  <Button
                    onClick={() => setriderStatus(true)}
                    className="select-btn"
                  >
                    Additional Covers
                    <SVG src={Arrow} alt="" className="down-arrow" />
                  </Button>
                </div>
              )}
              {formContext.carbikeformikValues?.formtype === "bike" && (
                <div>
                  <Button
                    onClick={() => setriderStatus(true)}
                    className="select-btn"
                  >
                    Additional Covers
                    <SVG src={Arrow} alt="" className="down-arrow" />
                  </Button>
                </div>
              )}
              {formContext.carbikeformikValues?.formtype === "car" && (
                <div>
                  <Button
                    onClick={() => {
                      setownedByPopup(true);
                      setownedByPopuppreviousValue(
                        formik.values.vehicalRegType
                      );
                    }}
                    className="select-btn"
                  >
                    {formik?.values?.ownedBy === "Individual"
                      ? "Individual"
                      : "Organization"}
                    <SVG src={Arrow} alt="" className="down-arrow" />
                  </Button>
                </div>
              )}

              {true && (
                <div onClick={() => setpersonalAccCover(true)}>
                  {formik.values.policyType !== "OD" && !dealId && (
                    <InputFieldCheckBox
                      formik={formik}
                      formikFieldName="personalAccidentCover"
                      label="Personal Accident Cover"
                    />
                  )}
                </div>
              )}
              <div className="d-flex">
                <Button className="share-quotes-btn">
                  {/* <Button className="share-quotes-btn" onClick={() => setshareQuotesPopup(true)}> */}
                  <SVG src={Share} alt="" className="share-icon" /> Share Quotes
                </Button>
                <div className="Demo__container share-quote-content">
                  <div className="Demo__some-network social-icon" title="Email">
                    <EmailShareButton
                      url={shareUrl}
                      subject={title}
                      body="body"
                      className="Demo__some-network__share-button"
                    >
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                  </div>

                  <div
                    className="Demo__some-network "
                    onClick={closeShareQuptes}
                    title="Whatsapp"
                  >
                    <WhatsappShareButton
                      url={shareUrl}
                      title={title}
                      separator=":: "
                      className="Demo__some-network__share-button"
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
            </div>
            <p className="mb-0 gst-text">All prices are exclusive of GST</p>
          </div>
        </Row>
        <Row className=" card-wrap">
          {quotesResultsArray
            ?.filter(
              (filterData) =>
                Number(filterData?.netPremium) !== 0 &&
                !(
                  filterData?.insuranceCompany ===
                    "ICICI Lombard General Insurance Co. Ltd" &&
                  Number(filterData?.odpremium) === 0 &&
                  formik?.values?.policyType === "OD-TP"
                ) &&
                !(
                  filterData?.insuranceCompany ===
                    "ICICI Lombard General Insurance Co. Ltd" &&
                  Number(filterData?.odpremium) === 0 &&
                  formik?.values?.policyType === "OD"
                )
            )
            ?.map((data, index) => {
              // Calculate the average of the three ratings
              const rating = (
                (data?.insurerIndex + data?.claimIndex + 4.0) /
                3
              )?.toFixed(1);
              var premium = 0;
              // for new india we get array for net premium and for idv so we need this logic
              if (typeof data?.netPremium === "object") {
                data?.netPremium?.forEach((foreachdata, index) => {
                  premium = premium + foreachdata;
                });
              } else {
                premium = data?.netPremium;
              }

              return (
                <Card key={index}>
                  <Card.Body>
                    <div className="card-top card-header">
                      <div className="logo-wrap">
                        <img
                          src={imgArrayForCard?.[data?.insuranceCompany]}
                          alt=""
                          className="logo"
                        />
                      </div>
                      <Button
                        variant="primary"
                        className="buy-btn"
                        onClick={() => {
                          // if (
                          //   data?.insuranceCompany ===
                          //     "Future Generali India Insurance Co. Ltd" &&
                          //   !formik.values.personalAccidentCover
                          // ) {
                          //   formik.setFieldValue("futurePA",true)
                          //   setPaPopup(true);
                          // } else {
                            // formik.setFieldValue("futurePA",false)
                            buyNow(data);
                          // }
                        }}
                      >
                        Buy Now
                      </Button>
                    </div>
                    <div className="offer-wrap">
                      {Number(data?.insuredDeclareValue) ===
                        Number(idvValue?.betstValue) && (
                        <SVG
                          src={Best}
                          alt=""
                          className="best-icon"
                          fill="#d4d4d4"
                        />
                      )}
                      {Number(data?.netPremium) ===
                        Number(idvValue?.netPremium) && (
                        <SVG
                          src={Lowest}
                          alt=""
                          className="lowest-icon"
                          fill="#d4d4d4"
                        />
                      )}
                    </div>
                    <div className="card-miidle  card-details">
                      <div className="card-rating-wrap">
                        <div className="card_rating mb-0">
                          <span className="label-text">Rating</span>
                          <span className="label-text badge">{rating}</span>
                        </div>
                        <div className="star-icon-wrap">
                          {giveRating(rating)}
                        </div>
                      </div>
                      <div className="idv-wrap">
                        <p className="label-text">IDV</p>
                        <p className="idv-text">
                          ₹ {Math.floor(data?.insuredDeclareValue)}
                        </p>
                      </div>
                      <div className="idv-wrap">
                        <p className="label-text">Premium</p>
                        <p className="premium-text">
                          ₹ {Number(premium)?.toFixed(0)}
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-tex">
                                Click to view breakup
                              </Tooltip>
                            }
                          >
                            <span
                              onClick={() => {
                                openQuotePopup(data, premium);
                              }}
                            >
                              <FaEye />
                            </span>
                          </OverlayTrigger>
                        </p>
                      </div>
                    </div>
                    <div className="card-bottom rating d-flex gap-4">
                      <div className="premium">
                        <div className="star rating-star">
                          <SVG
                            src={Star}
                            alt=""
                            className="rating-star-icon"
                            fill="#d4d4d4"
                          />
                          <span className="rating-point">4.0</span>
                        </div>
                        <p className="premium-text">Premium</p>
                      </div>
                      <div className="premium">
                        <div className="star rating-star">
                          <SVG
                            src={Star}
                            alt=""
                            className="rating-star-icon"
                            fill="#d4d4d4"
                          />
                          <span className="rating-point">
                            {data?.insurerIndex?.toFixed(1)}
                          </span>
                        </div>
                        <p className="premium-text">Servicing</p>
                      </div>
                      <div className="premium">
                        <div className="star rating-star">
                          <SVG
                            src={Star}
                            alt=""
                            className="rating-star-icon"
                            fill="#d4d4d4"
                          />
                          <span className="rating-point">
                            {data?.claimIndex?.toFixed(1)}
                          </span>
                        </div>
                        <p className="premium-text">Claims</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              );
            })}
          {/* {loaderForQuotes && <SkeletonCard />} */}
          {loaderForQuotes &&
            formContext.createquotesresult?.map((data, index) => {
              return <>{index >= count ? <SkeletonCard /> : null} </>;
            })}
        </Row>
        {!formContext.loaderStatus && quotesResultsArray?.length === 0 && (
          <PageNotFound message={"No product matches given criteria"} />
        )}
        <button
          title="Back"
          onClick={() => backButtonPressed()}
          className="back-button"
        >
          {" "}
          <SVG src={Arrow} alt="" className="back-arrow" />
        </button>
      </Container>
      <VerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        heading={headingForPopup}
      >
        {childForPopup}
      </VerticallyCenteredModal>
      {/* View breakup */}
      <VerticallyCenteredModal
        show={quotePremiumPopup}
        onHide={() => {
          QpPopupClose();
        }}
      >
        {quotePremium}
      </VerticallyCenteredModal>
      {/* popupfor tp policy */}
      <VerticallyCenteredModal
        show={tpPolicyStatus}
        onHide={() => {
          odPopupClose(1);
        }}
        heading="Enter Relevant Dates"
      >
        {popForOd}
      </VerticallyCenteredModal>
      {/* popup for rider */}
      <VerticallyCenteredModal
        show={riderStatus}
        onHide={() => {
          setriderStatus(!riderStatus);
        }}
        heading="Select Additional Covers"
      >
        {dealId
          ? formContext.carbikeformikValues?.formtype === "car"
            ? iciciPopForAdditionalCoverCar
            : iciciPopForAdditionalCoverBike
          : formContext.carbikeformikValues?.formtype === "car"
          ? popForAdditionalCover
          : bikePopForAdditionalCover}
      </VerticallyCenteredModal>
      {/* personal accident cover */}
      <VerticallyCenteredModal
        show={personalAccCover}
        onHide={() => {
          setpersonalAccCover(!personalAccCover);
        }}
        heading="Personal Accident(PA)Cover ?"
      >
        {popPersonalAccident}
      </VerticallyCenteredModal>

      <VehicalNotFoundModal
        show={vehicalNotFound}
        onHide={() => setVehicalNotFound(false)}
      />

      {/* IDV value popup */}
      <VerticallyCenteredModal
        show={idvPopupStatus}
        onHide={() => {
          setidvPopupStatus(!idvPopupStatus);
          seterrorForOdPopup(false);
        }}
        heading="Select IDV"
      >
        {popForIdvMinIdv}
      </VerticallyCenteredModal>

      {/* popup for owned type */}
      <VerticallyCenteredModal
        show={ownedByPopup}
        onHide={() => {
          setownedByPopup(!ownedByPopup);
        }}
        heading="Vehicle registered in the name of"
      >
        {popIndividual}
      </VerticallyCenteredModal>
      {/* popup for login screen */}
      <VerticallyCenteredModal
        show={formContext?.loginPopupStatus}
        onHide={() => {
          setownedByPopup(formContext.setloginPopupStatus(false));
        }}
        heading="Login with mobile number"
      >
        <SignIn />
      </VerticallyCenteredModal>

      {/* popup for Non PA future tags */}
      <VerticallyCenteredModal
        show={paPopup}
        onHide={() => setPaPopup(!paPopup)}
      >
        <>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="g-3">
              {/* Alternate Policy Expiry Date */}
              <Col md={6} sm={12}>
                <InputFieldText
                  formikFieldName="alternatePolicyExpDateFuture"
                  placeholder="Alternate Policy Expiry Date"
                  formik={formik}
                  type="date"
                />
                <ErrorMessage
                  formik={formik}
                  fieldValue="alternatePolicyExpDateFuture"
                />
              </Col>

              {/* Existing PA Policy Number */}
              <Col md={6} sm={12}>
                <InputFieldText
                  formikFieldName="existingPAPolicyNo"
                  placeholder="Existing PA Policy Number"
                  formik={formik}
                  type="text"
                />
                <ErrorMessage formik={formik} fieldValue="existingPAPolicyNo" />
              </Col>

              {/* Existing PA Sum Insured */}
              <Col md={6} sm={12}>
                <InputFieldText
                  formikFieldName="existingPASumInsured"
                  placeholder="Existing PA Sum Insured"
                  formik={formik}
                  type="number"
                />
                <ErrorMessage
                  formik={formik}
                  fieldValue="existingPASumInsured"
                />
              </Col>

              {/* Insurer Name */}
              <Col md={6} sm={12}>
                <InputFieldText
                  formikFieldName="insurerName"
                  placeholder="Insurer Name"
                  formik={formik}
                  type="text"
                />
                <ErrorMessage formik={formik} fieldValue="insurerName" />
              </Col>

              {/* Valid License */}
              <Col md={6} sm={12}>
                <Form.Check
                  type="checkbox"
                  label="Do you have a valid license"
                  name="validLicense"
                  checked={formik.values.validLicense} // Controlled by Formik
                  onChange={(e) =>
                    formik.setFieldValue("validLicense", e.target.checked)
                  } // Update Formik state
                  onBlur={formik.handleBlur} // Handle blur for validation
                />
                {formik.touched.validLicense && formik.errors.validLicense && (
                  <div className="text-danger">
                    {formik.errors.validLicense}
                  </div>
                )}
              </Col>
            </Row>

            {/* Submit Button */}
            <div className="text-center mt-4 footer-btn-wrap">
              <Button type="cancel" className="back-btn">
                Cancel
              </Button>
              <Button type="submit" className="primary-btn">
                Save
              </Button>
            </div>
          </Form>
        </>
      </VerticallyCenteredModal>

      {/* popup for share quotes type */}
      <VerticallyCenteredModal
        show={shareQuotesPopup}
        onHide={() => {
          closeShareQuptes();
        }}
        heading="Share quotes"
      >
        {popShareQuotes}
      </VerticallyCenteredModal>
    </React.Fragment>
  );
}
