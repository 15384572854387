/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import UseMultistepForm from "../../MultipleForms/UseMultiStepForms";
import HealthFormPage1 from "../Health/HealthFormPage1";
import HealthFormPage2 from "../Health/HealthFormPage2";
import { useHistory, useParams } from "react-router-dom"
import * as api from "../../../API/authCurd";
import { Button } from "react-bootstrap";
import * as Yup from "yup"
import { useFormik } from "formik"
import UseFormContext from "../../../context/UseFormContext";

function ContainerForHealth(props) {

  const { id } = useParams();
  let history = useHistory();

  const formContext = UseFormContext();

  Yup.addMethod(Yup.object, 'atleastOneOf', function (list) {
    return this.test({
      name: 'atleastOneOf',
      message: 'At least one existing disease should be selected',
      exclusive: true,
      params: { keys: list.join(', ') },
      test: value => value == false || list.some(f => !!value[f])
    })
  })


  const initialData = {
    gender: "",
    existanceIllness: "",
    hospitalLimit: "",
    pincode: "",
    healthInsurefor: "",
    currentStepIndex: "0",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    illnesses: {
      diabetes: false,
      bloodPressure: false,
      thyroid: false,
      asthma: false,
      anySurgery: false,
      otherDiseases: false
    },
    policyTerm:""
    
  };
  const validationSchema = Yup.object().shape({
    gender: Yup.string()
      .trim()
      .required("Gender Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== ""
      }),

    existanceIllness: Yup.string()
      .trim()
      .required("Existance Illness Field Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== ""
      }),

    hospitalLimit: Yup.string()
      .trim()
      .required("Hospital Limit Field Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== ""
      }),

    pincode: Yup.string()
      .trim()
      .required("Pincode Is Required"),

    healthInsurefor: Yup.string()
      .trim()
      .required("Health Insurance Is Required"),

    currentStepIndex: Yup.string().required("Field is required"),
    firstName: Yup.string().when("currentStepIndex", {
      is: '1',
      then: () => Yup.string()
        .required(" FirstName Is Required")
        .min(2, 'First name must be at least 2 characters long')
        .matches(/^[A-Za-z].*/, 'First name must start with a letter')
    }),


    lastName: Yup.string().when("currentStepIndex", {
      is: '1',
      then: () => Yup.string()
        .required(" LastName Is Required")
        .min(2, 'Last name must be at least 2 characters long')
        .matches(/^[A-Za-z].*/, 'Last name must start with a letter')
    }),

    email: Yup.string().when("currentStepIndex", {
      is: '1',
      then: () => Yup.string()
        .required('Email is required')
        .email('Invalid email format')
        .matches(/@.*\./, 'Email must contain "@" and "."'),
    }),


    phoneNumber: Yup.string().when("currentStepIndex", {
      is: '1',
      then: () => Yup.string()
        .required('Phone number is required')
        .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
    }),

    illnesses: Yup.object().when("existanceIllness", {
      is: 'Yes',
      then: () => Yup.object().shape({
        diabetes: Yup.boolean(),
        bloodPressure: Yup.boolean(),
        thyroid: Yup.boolean(),
        asthma: Yup.boolean(),
        anySurgery: Yup.boolean(),
        otherDiseases: Yup.boolean()
      }).atleastOneOf(['diabetes', 'bloodPressure', 'thyroid', 'asthma', 'anySurgery', 'otherDiseases'])
    }),
  })


  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      if (!isLastStep) return next();
      formContext.setHealthMainFormData(values);
      // formContext.setroutingPath("/health/1")
      // history.push({
      //   pathname: "/healthQuotes",
      //   state: { values: values, prePage: "health" },
      // })
      api
        .createHealthQuoteApi(values)
        .then((data) => {
          redirectMethod(values, data); 
        })
        .catch((err) => {
          console.log("error", err);
          formContext.notifyError("An error occurred while fetching data");
        })
        .finally(() => {
          // Set loader back to false
          formContext.setloaderStatus(false);
        });
    }, 
  })

// Method when first time quote get created 
  const redirectMethod = (values, data) => {
    const JsonDataForCreateQuotes = JSON.parse(data);
    // formContext.setPolicyDates(JsonDataForCreateQuotes);
    console.log("before sassion storage----->", values, JsonDataForCreateQuotes)
    sessionStorage.setItem("quoteId", JsonDataForCreateQuotes?.data[0]?.QUOTE_ID);
    console.log("quotes create", JsonDataForCreateQuotes.data);
    formContext.setcreatequotesresult(JsonDataForCreateQuotes.data); 
    formContext.setroutingPath("/health/1");
    formContext.setHealthQuoteFormikData(values);
    history.push({
      pathname: "/healthQuotes", 
      state: {
        values: values,
        apiResopnse: JsonDataForCreateQuotes.data,
        prePage: "health",
      },
    });
  };

  useEffect(() => {
    if (id && formContext.healthMainFormData) {
      formik.setValues({
        ...formContext.healthMainFormData
      });
    }
  }, []);

  // Destructuring variables from the custom hook UseMultistepForm
  const { currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    UseMultistepForm([
      <HealthFormPage1  {...props} formik={formik} />,
      <HealthFormPage2 {...props} formik={formik} />,
    ]);

  // Update the "currentStepIndex" field in Formik whenever the "currentStepIndex" state changes
  useEffect(() => {
    formik.setFieldValue("currentStepIndex", currentStepIndex)
  }, [currentStepIndex]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}
      >
        {step}
        <div>
          {!isFirstStep && (
            <Button onClick={back} className="back-btn">
              Back
            </Button>
          )}
          <Button type="submit" className="mt-4 mb-4 primary-btn">
            {isLastStep ? "Get Quotes" : "Next"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ContainerForHealth;
