import React, { useEffect, } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContainerForPassword from "./CommonComponents/ContainerForPassword"
export default function ResetPassword() {


    return (
        <React.Fragment>
            <Container>
                <Row>
                    <h4 className="mb-5 mt-5">Forgot Password</h4>
                    <Col className="mb-3">
                        <ContainerForPassword />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}
