import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CountainerForHealth from "../CommonComponents/CountainerForHealth";
import health from "../../../../src/images/health.png";
import UnderConstruction from "../../../../src/images/UnderConstruction.jpg"
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button"
import "../../style/CSS/HealthPage.css"

export default function HealthPage() {
  // ---- --useEffect---------
  useEffect(() => {
    // Add the "car-page" class to the body element when the component mounts
    document.body.classList.add("car-page");
    // Clean up function: Remove the "car-page" class when the component unmounts
    return () => {
      document.body.classList.remove("car-page");
    };
  }, []);
  // ---methods and jsx------

  return (
<Container fluid>
      <Row>
        <Col
          md={5} 
          style={{
            // backgroundColor: '#EBE8FA',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '121vh', 
          }}
        >
          <img
            src={health}
            alt="Sample"
            style={{ maxWidth: '100%', maxHeight: '80vh', borderRadius: '10px' }}
          />
        </Col>

  
        <Col md={7}>
          <div style={{ padding: '20px' }}>
          <h4 className="mb-5 mt-5">Find the best Health plan</h4>
            <CountainerForHealth />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
